import { React, useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from 'react-router-dom';
import {
    GetUrlCompany,
    NavigateToOperationLogin,
    NavigateToOperation,
    MeasureText,
    ToLocalDayAndTime
} from "../../util/commonFun";
import { errorCode } from "../../../ErrorCode";
import InputNumberBox from "../../../components/InputNumberBox";
import ComboBox from "../../../components/ComboBox";
import InputBox from "../../../components/InputBox";
import { OperationManagementType, ShikakariStatus, TextType, variables } from "../../../Variables";
import ErrorText from "../../../components/ErrorText";
import ErrorBox from "../../../components/ErrorBox";
import ConfirmBox, { FocusButton } from "../../../components/ConfirmBox";
import { setIsLoading as setPageLoading, getIsLoading as getIsPageLoading } from "../../Loading/pageLoadingSlice";
import { appInsights } from "../../util/ApplicationInsight";
import {
    resetInitState,
    setIsWorkImageExpandMode,
} from "../lotOperationSlice";

import {
    fetchItemData,
    fetchUserData,
    fetchProcessList,
    setSelectedUserErrorMsg,
    setSelectedItemErrorMsg,
    setResetProcessList,
} from "../operationStartWorkSlice";

const axios = require('axios');
const urlCompanyName = GetUrlCompany();
const buttonDisable = "bg-[#F3F2F1] w-[229px] h-[87px] text-[26px] cursor-pointer text-center textmiddle rounded-[2px]";
const kaishiBtnEnable = "bg-[#0073CD] w-[229px] h-[87px] text-[26px] text-white hover:bg-[#0068B8] cursor-pointer text-center textmiddle rounded-[2px]";
const saikaiBtnEnable = "bg-[#D3EDFB] w-[229px] h-[87px] text-[26px] text-black hover:bg-[#C1D9E6] cursor-pointer text-center textmiddle rounded-[2px]";
let excludeKey = ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'Tab', 'Alt', 'Control', 'Meta', 'Shift', 'Escape'];
let userComboRefs = null;
let itemComboRefs = null;
let matomesuuBoxRefs = null;
let kouteiComboRefs = null;
let lotNumberInputRefs = null;
let startWorkBtnRefs = null;
let resumeWorkBtnRefs = null;
let isChooseOption = false;
const tdWidth = 320;
const tblFontSize = 22;

const emptyShikakariObj = {
    dispProcessId: "",
    dispWorkId: "",
    workName: "",
    dispOrder: -1,
    lotOperationLogLockId: -1,
    userId: -1,
    userName: "",
    matomesuu: 0,
    lastDate: new Date(),
    status: ShikakariStatus.NONE,
}

function LotNumberStartWork(props) {
    const dispatch = useDispatch();
    const isPageLoading = useSelector(getIsPageLoading);
    const items = useSelector((state) => state.operationStartWork);
    const itemList = [...items.dataList.itemList];
    const itemListNoEnd = itemList.filter((item) => item.isEnd === false)
    const userList = [...items.dataList.userList];
    const selectedUserErrorMsg = items.selectedUserErrorMsg;
    const selectedItemErrorMsg = items.selectedItemErrorMsg;
    const processList = [...items.dataList.processList];
    let navigate = useNavigate();
    const refInitial = useRef(true);

    const [itemName, setItemName] = useState("");
    const [userName, setUserName] = useState("");
    const [selectedDispProcessId, setSelectedDispProcessId] = useState("");
    const [inputLotNumber, setInputLotNumber] = useState("");
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [matomesuu, setMatomesuu] = useState(0);
    const [selectedShikakariWork, setSelectedShikakariWork] = useState({ ...emptyShikakariObj });
    const [isStopKeyUp, setIsStopKeyUp] = useState(false);
    const [isSagyouSaikai, setSagyouSaikai] = useState(false);
    const [displayTooltip, setTooltip] = useState(null);
    const [matomesuuCntOverErrorMsg, setMatomesuuCntOverErrorMsg] = useState("");
    const [shikakariList, setShikakariList] = useState([]);
    const [matomesuuDisable, setMatomesuuDisable] = useState(false);
    const [inputLotNumberErrorMsg, setInputLotNumberErrorMsg] = useState("");
    const location = useLocation();
    const currLotOperationLogLockId = location.state ? location.state : -1;
    const selectedRowRef = useRef(null);

    useEffect(() => {
        const currUserId = sessionStorage.getItem('userId') ? sessionStorage.getItem('userId') : "";
        if (currUserId.length > 0 && userList.length > 0 && userName.length === 0) {
            const currUser = userList.find((user) => user.userId === Number(currUserId));
            if (currUser) {
                setUserName(currUser.userName);
                setSelectedUser(currUser);
            }
            else {
                // ユーザーマスタに登録されていないユーザーです。
                dispatch(setSelectedUserErrorMsg(errorCode[errorCode.E1003]));
                setUserName(currUserId);
            }
        }
        let currItem = null;
        let currItemErrMsg = "";
        const currItemCode = sessionStorage.getItem('itemCode') ? sessionStorage.getItem('itemCode') : "";
        if (currItemCode.length > 0 && itemList.length > 0 && itemName.length === 0) {
            currItem = itemList.find((item) => item.itemCode === Number(currItemCode));
            if (currItem) {
                if (currItem.isEnd === true) {
                    // 製品マスタで生産終了設定をしている製品です。
                    currItemErrMsg = errorCode[errorCode.E1004];
                    dispatch(setSelectedItemErrorMsg(currItemErrMsg));
                    setItemName(currItem.displayItemCode);
                }
                else {
                    setItemName(currItem.itemName);
                    if (currItem.processCnt === 0) {
                        // 工程情報が登録されていない製品です。
                        currItemErrMsg = errorCode[errorCode.E1005];
                        dispatch(setSelectedItemErrorMsg(currItemErrMsg));
                    }
                    else {
                        setSelectedItem(currItem);
                        const fetchData = async () => {
                            await fetchProcessDataList(currItem.itemCode);
                        }
                        fetchData();
                    }
                }
            }
            else {
                setItemName(currItemCode);
                // 製品マスタに登録されていない製品です。
                currItemErrMsg = errorCode[errorCode.E1006];
                dispatch(setSelectedItemErrorMsg(currItemErrMsg));
            }
        }
        const currLotNumber = sessionStorage.getItem('lotNo') ? sessionStorage.getItem('lotNo') : "";
        if (currLotNumber.length > 0 && itemList.length > 0 && inputLotNumber.length === 0) {
            setInputLotNumber(currLotNumber);
            if (currItem && currItemErrMsg.length === 0) {
                const fetchData = async () => {
                    // 生産状況リストを取得する
                    await getSeisanInfoListByLotNo(currLotNumber, currItem.itemCode);
                }
                fetchData();
            }
        }
        let currDispProcessId = sessionStorage.getItem('dispProcessId') ? sessionStorage.getItem('dispProcessId') : "";
        if (currDispProcessId.length > 0 && processList.length > 0 && selectedDispProcessId.length === 0) {
            let idx = processList.findIndex((item) => item.dispProcessId === currDispProcessId);
            if (idx > -1) {
                setSelectedDispProcessId(currDispProcessId);
                if (currLotNumber.length > 0) {
                    const fetchData = async () => {
                        // 仕掛かりリストを取得する処理
                        await getShikakariInfoList(currLotNumber, currDispProcessId);
                    }
                    fetchData();
                }
            }
            else {
                currDispProcessId = "";
            }
        }
        const currMatomesuu = sessionStorage.getItem('matomesuu') ? sessionStorage.getItem('matomesuu') : 0;
        if (Number(currMatomesuu) > 0 && props.seisanInfoList.length > 0 && matomesuu === 0) {
            setMatomesuu(Number(currMatomesuu));
        }
        if (currUserId.length === 0 && userName.length === 0) {
            userComboRefs.focus();
        }
        else if (currItemCode.length === 0 && itemName.length === 0) {
            itemComboRefs.focus();
        }
        else if (currLotNumber.length === 0 && inputLotNumber.length === 0) {
            lotNumberInputRefs.focus();
        }
        else if (currDispProcessId.length === 0 && selectedDispProcessId.length === 0) {
            kouteiComboRefs.focus();
        }
        else if (currMatomesuu === 0 && matomesuu === 0) {
            matomesuuBoxRefs.focus();
        }
        else if (startWorkBtnRefs) {
            startWorkBtnRefs.focus();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items, props.seisanInfoList, selectedShikakariWork.lotOperationLogLockId]);

    useEffect(() => {
        if (shikakariList?.length > 0 && selectedUser !== null) {
            let selectedObj = shikakariList.find((obj) => obj.userId === selectedUser.userId && obj.lotOperationLogLockId === currLotOperationLogLockId && obj.status === ShikakariStatus.CHUUDAN);
            if (selectedObj) {
                doSelectedShikakariWork(selectedObj);
            }
            else {
                selectedObj = shikakariList.filter(obj => obj.userId === selectedUser.userId && obj.status === ShikakariStatus.CHUUDAN).sort((a, b) => new Date(b.lastDate) - new Date(a.lastDate))[0];
                if (selectedObj) {
                    doSelectedShikakariWork(selectedObj);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shikakariList, selectedUser]);

    useEffect(() => {
        if (selectedRowRef.current) {
            selectedRowRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
        if (resumeWorkBtnRefs && selectedShikakariWork.lotOperationLogLockId > -1) {
            resumeWorkBtnRefs.focus();
        }
    }, [selectedShikakariWork]);

    useEffect(() => {
        checkMatomesuuError(Number(matomesuu));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matomesuu]);

    useEffect(() => {
        if (refInitial.current === true) {
            refInitial.current = false;
            dispatch(resetInitState());
            dispatch(setIsWorkImageExpandMode(false));
            const fetchData = async () => {
                try {
                    await dispatch(fetchUserData()).unwrap();
                    await dispatch(fetchItemData()).unwrap();
                } catch (error) {
                    let errResponseStatus = error.message;
                    // eslint-disable-next-line eqeqeq
                    if (errResponseStatus == 401) {
                        NavigateToOperationLogin(navigate);
                    }
                    else {
                        appInsights.trackException({ ...error, errorFunction: "LotNumberStartWork.useEffect()" });
                        console.error(error);
                    }
                }
            }
            fetchData();
        }
    }, [dispatch, navigate])

    /**
     * ユーザーコンボボックスの選択イベント
     * @param {*} e 
     * @returns 
     */
    function handleUserSelectedChange(e) {
        setTooltip(null);
        isChooseOption = true;
        if (e.targetValue.length === 0) {
            dispatch(setSelectedUserErrorMsg(""));
            return;
        }
        const selUserObj = userList.find((user) => user.displayUserCode.toLowerCase() === e.targetValue.toLowerCase());
        if (selUserObj) {
            dispatch(setSelectedUserErrorMsg(""));
            handleUserInputChange(e.targetValue);
            itemComboRefs.focus();
        }
    }

    /**
     * ユーザーコンボボックスのキーダウンイベント
     * @param {*} e 
     */
    function handleUserEnterKeyDown(e) {
        setTooltip(null);
        if (e.key === 'Enter') {
            setIsStopKeyUp(true);
            let value = e.target.value;
            if (value.length > 0) {
                let isMatchCode = userList.findIndex((user) => user.displayUserCode === value);
                let isMatchName = userList.findIndex((user) => user.userName === value);
                if (isMatchCode === -1 && isMatchName === -1) {
                    handleUserInputChange(value);
                    itemComboRefs.focus();
                }
            }
            else if (userName.length > 0) {
                itemComboRefs.focus();
            }
        }
        else if (excludeKey.some((k) => k.toLowerCase() === e.key.toLowerCase()) === false && userName.length > 0) {
            setUserName("");
            setSelectedUser(null);
            sessionStorage.removeItem('userId');
        }
    }

    /**
     * ユーザーコンボボックスのフォーカスアウトイベント
     * @param {*} e 
     * @returns 
     */
    function handleUserFocusOut(e) {
        if (isChooseOption) {
            isChooseOption = false;
            return;
        }
        if (e.target.value.length > 0) {
            handleUserInputChange(e.target.value);
        }
        else if (userName.length === 0 && selectedUserErrorMsg.length > 0) {
            dispatch(setSelectedUserErrorMsg(""));
        }
    }

    /**
     * ユーザーを変更する処理
     * @param {*} value 
     */
    function handleUserInputChange(value) {
        const iscorrectUserCode = userList.some((user) => user.displayUserCode.toLowerCase() === value.toLowerCase());
        const iscorrecUserName = userList.some((user) => user.userName.toLowerCase() === value.toLowerCase());
        if (iscorrectUserCode === false && iscorrecUserName === false) {
            // ユーザーマスタに登録されていないユーザーです。
            dispatch(setSelectedUserErrorMsg(errorCode[errorCode.E1003]));
            setUserName(value);
            setSelectedUser(null);
            sessionStorage.setItem('userId', value);
        }
        else {
            handleUserInputChangeNoError(value);
        }
    }

    /**
     * エラー無しユーザーを記入する場合、データ変更処理
     * @param {*} input 
     */
    function handleUserInputChangeNoError(input) {
        const iscorrectUserCode = userList.some((user) => user.displayUserCode.toLowerCase() === input.toLowerCase());
        const selectUserObj = iscorrectUserCode ? userList.find((user) => user.displayUserCode.toLowerCase() === input.toLowerCase()) :
            userList.find((user) => user.userName.toLowerCase() === input.toLowerCase());
        if (selectUserObj) {
            setSelectedUser(selectUserObj);
            setUserName(selectUserObj.userName);
            dispatch(setSelectedUserErrorMsg(""));
            sessionStorage.setItem('userId', selectUserObj.userId);
        }
    }

    /**
     * 製品コンボボックスの選択イベント
     * @param {*} e 
     * @returns 
     */
    function handleItemSelectedChange(e) {
        setTooltip(null);
        isChooseOption = true;
        if (e.targetValue.length === 0) {
            dispatch(setSelectedItemErrorMsg(""));
            return;
        }
        const selItemObj = itemList.find((item) => item.displayItemCode.toLowerCase() === e.targetValue.toLowerCase())
        if (selItemObj) {
            handleItemInputChange(e.targetValue);
            lotNumberInputRefs.focus();
        }
    }

    /**
     * 製品コンボボックスのキーダウンイベント
     * @param {*} e 
     */
    function handleItemEnterKeyDown(e) {
        setTooltip(null);
        if (e.key === 'Enter') {
            setIsStopKeyUp(true);
            let value = e.target.value;
            if (value.length > 0) {
                let isMatchCode = itemListNoEnd.findIndex((user) => user.displayItemCode === value);
                let isMatchName = itemListNoEnd.findIndex((user) => user.itemName === value);
                if (isMatchCode === -1 && isMatchName === -1) {
                    handleItemInputChange(value);
                    lotNumberInputRefs.focus();
                }
            }
            else if (itemName.length > 0) {
                lotNumberInputRefs.focus();
            }
        }
        else if (excludeKey.some((k) => k.toLowerCase() === e.key.toLowerCase()) === false && itemName.length > 0) {
            setItemName("");
            setSelectedItem(null);
            sessionStorage.removeItem('itemCode');
        }
    }

    /**
     * 製品コンボボックスのフォーカスアウトイベント
     * @param {*} e 
     * @returns 
     */
    function handleItemFocusOut(e) {
        if (isChooseOption) {
            isChooseOption = false;
            return;
        }
        if (e.target.value.length > 0) {
            handleItemInputChange(e.target.value);
        }
        else if (itemName.length === 0 && selectedItemErrorMsg.length > 0) {
            dispatch(setSelectedItemErrorMsg(""));
        }
    }

    /**
     * 製品変更する処理
     * @param {*} value 
     * @returns 
     */
    async function handleItemInputChange(value) {
        if (itemName.length > 0 && selectedItem && (selectedItem.displayItemCode === value || selectedItem.itemName === value)) {
            return;
        }
        resetByItemChange();
        const iscorrectItemName = itemList.some((item) => item.itemName === value);
        const iscorrectItemCode = itemList.some((item) => item.displayItemCode === value);
        if (iscorrectItemName === false && iscorrectItemCode === false) {
            // 製品マスタに登録されていない製品です。
            dispatch(setSelectedItemErrorMsg(errorCode[errorCode.E1006]));
            setItemName(value);
            sessionStorage.setItem('itemCode', value);
        }
        else {
            const selectItemObj = itemList.find((item) => item.displayItemCode.toLowerCase() === value.toLowerCase()
                || item.itemName.toLowerCase() === value.toLowerCase());
            if (selectItemObj) {
                sessionStorage.setItem('itemCode', selectItemObj.itemCode);
                if (selectItemObj.isEnd === true) {
                    // 製品マスタで生産終了設定をしている製品です。
                    dispatch(setSelectedItemErrorMsg(errorCode[errorCode.E1004]));
                    setItemName(value);
                }
                else {
                    setItemName(selectItemObj.itemName);
                    if (selectItemObj.processCnt === 0) {
                        // 工程情報が登録されていない製品です。
                        dispatch(setSelectedItemErrorMsg(errorCode[errorCode.E1005]));
                    }
                    else {
                        dispatch(setSelectedItemErrorMsg(""));
                        setSelectedItem(selectItemObj);
                        await fetchProcessDataList(selectItemObj.itemCode);
                    }
                }
            }
        }
    }

    /**
     * itemCodeで工程リストを取得する処理
     * @param {*} itemCode 
     */
    async function fetchProcessDataList(itemCode) {
        try {
            await dispatch(fetchProcessList(itemCode)).unwrap();
        } catch (error) {
            let errResponseStatus = error.message;
            // eslint-disable-next-line eqeqeq
            if (errResponseStatus == 401) {
                NavigateToOperationLogin(navigate);
            }
            else {
                appInsights.trackException({ ...error, errorFunction: "LotNumberStartWork.fetchProcessDataList()" });
                console.error(error);
            }
        }
    }

    /**
     * 製品を変更する場合、関係する各項目をリセットする処理
     */
    function resetByItemChange() {
        sessionStorage.removeItem('lotNo');
        sessionStorage.removeItem('dispProcessId');
        sessionStorage.removeItem('matomesuu');
        props.setLotNumber("");
        props.setKeiKaKuSuu(0);
        props.setSeisanInfoList([]);
        setInputLotNumber("");
        setSelectedDispProcessId("");
        setMatomesuu(0);
        setSagyouSaikai(false);
        setSelectedShikakariWork({ ...emptyShikakariObj });
        setMatomesuuCntOverErrorMsg("");
        dispatch(setResetProcessList());
        setShikakariList([]);
        setMatomesuuDisable(false);
        setSelectedItem(null);
        setInputLotNumberErrorMsg("");
    }

    /**
     * ロット番号を変更するイベント
     * @param {*} e 
     */
    function handleLotNumberChange(e) {
        setInputLotNumber(e.target.value);
        setIsStopKeyUp(false);
        setTooltip(null);
        setInputLotNumberErrorMsg("");
    }

    /**
     * ロット番号変更のキーアップイベント
     * @param {*} e 
     */
    function handleLotNumberKeyUp(e, str) {
        if (e.key === 'Enter' && isStopKeyUp === false) {
            let inputTxt = str;
            if (inputTxt.length > 0) {
                doLotNumberChange(inputTxt);
                kouteiComboRefs.focus();
            }
            else {
                setInputLotNumber(inputTxt);
                props.setLotNumber(inputTxt);
                props.setSeisanInfoList([]);
                props.setKeiKaKuSuu(0);
                sessionStorage.removeItem('lotNo');
                resetByLotNumberChange();
            }
        }
        setIsStopKeyUp(false);
    }

    /**
     * ロット番号変更のフォーカスアウトイベント
     * @param {*} e 
     */
    function handleLotNumberFocusOut(inputTxt) {
        setInputLotNumber(inputTxt);
        doLotNumberChange(inputTxt);
    }

    /**
     * ロット番号変更する処理
     * @param {*} inputTxt 
     * @returns 
     */
    async function doLotNumberChange(inputTxt) {
        if (props.lotNumber === inputTxt) {
            return;
        }
        resetByLotNumberChange();
        props.setLotNumber(inputTxt);
        sessionStorage.setItem('lotNo', inputTxt);
        if (selectedItemErrorMsg.length === 0 && inputTxt.length > 0 && selectedItem !== null) {
            await getSeisanInfoListByLotNo(inputTxt, selectedItem.itemCode);
        }
    }

    /**
     * lotNo,itemCodeで生産状況リストを取得する
     * @param {*} lotNo 
     * @param {*} itemCode 
     */
    async function getSeisanInfoListByLotNo(lotNo, itemCode) {
        await axios({
            method: 'GET',
            url: variables.OPERATION_URL + "/get-seisan-list-by-lotno",
            params: { lotNo: lotNo, itemCode: itemCode },
            headers: { 'company': urlCompanyName },
            withCredentials: true
        }).then(function (response) {
            let responseDataList = response.data.lotSeisanInfoPosts;
            let errResult = response.data.errorResult;
            props.setKeiKaKuSuu(response.data.keiKaKuSuu);
            if (errResult === errorCode.E1015) {
                let msg = errorCode[errResult];
                setInputLotNumberErrorMsg(msg);
            }
            else {
                setInputLotNumberErrorMsg("");
            }
            responseDataList.sort((a, b) => a.dispOrder - b.dispOrder);
            props.setSeisanInfoList(responseDataList);
        }).catch(function (error) {
            let errResponseStatus = error.response.status;
            if (errResponseStatus === 401) {
                NavigateToOperationLogin(navigate);
            }
            else {
                appInsights.trackTrace({ params: JSON.stringify({ lotNo: lotNo, itemCode: itemCode }) });
                appInsights.trackException({ ...error, errorFunction: "LotNumberStartWork.getSeisanInfoListByLotNo()" });
                console.error(error);
            }
        });
    }

    /**
     * 「完了数+仕掛かり数」が生産計画を超える場合、メッセージを表示する
     * @param {*} lotNo 
     * @param {*} dispProcessId 
     * @param {*} itemCode 
     * @param {*} currMatomesuu 
     */
    async function checkMatomesuuErrorByPlanQuantity(lotNo, dispProcessId, itemCode, currMatomesuu) {
        if (!inputLotNumber.toLowerCase().startsWith('test') && currMatomesuu > 0) {
            await axios({
                method: 'GET',
                url: variables.OPERATION_URL + "/check-matomesuuerror-by-planquantity",
                params: { lotNo: lotNo, dispProcessId: dispProcessId, itemCode: itemCode, matomesuu: currMatomesuu },
                headers: { 'company': urlCompanyName },
                withCredentials: true
            }).then(function (response) {
                let responseData = response.data;
                let errResult = responseData.errorCode;
                props.setKeiKaKuSuu(responseData.quantity);
                if (errResult === errorCode.E1016) {
                    let msg = errorCode[errResult];
                    setMatomesuuCntOverErrorMsg(msg);
                }
                else {
                    setMatomesuuCntOverErrorMsg("");
                }
            }).catch(function (error) {
                let errResponseStatus = error.response.status;
                if (errResponseStatus === 401) {
                    NavigateToOperationLogin(navigate);
                }
                else {
                    appInsights.trackTrace({ params: JSON.stringify({ lotNo: lotNo, dispProcessId: dispProcessId, itemCode: itemCode, matomesuu: currMatomesuu }) });
                    appInsights.trackException({ ...error, errorFunction: "LotNumberStartWork.checkMatomesuuErrorByPlanQuantity()" });
                    console.error(error);
                }
            });
        }
        else {
            setMatomesuuCntOverErrorMsg("");
        }
    }

    /**
     * ロット番号を変更する場合、関係する各項目をリセットする処理
     */
    function resetByLotNumberChange() {
        sessionStorage.removeItem('dispProcessId');
        sessionStorage.removeItem('matomesuu');
        setSelectedDispProcessId("");
        setMatomesuu(0);
        setSagyouSaikai(false);
        setSelectedShikakariWork({ ...emptyShikakariObj });
        setMatomesuuCntOverErrorMsg("");
        setShikakariList([]);
        setMatomesuuDisable(false);
        setInputLotNumberErrorMsg("");
    }

    /**
     * 工程コンボボックスの選択イベント
     * @param {*} e 
     */
    async function handleKouteiSelectedChange(e) {
        setTooltip(null);
        let dispProcessId = e.targetValue;
        if (matomesuuBoxRefs) {
            matomesuuBoxRefs.focus();
        }
        if (selectedDispProcessId !== dispProcessId) {
            resetByProcessChange();
            setSelectedDispProcessId(dispProcessId);
            sessionStorage.setItem('dispProcessId', dispProcessId);
            if (inputLotNumber.length > 0) {
                // 仕掛かりリストを取得する処理
                await getShikakariInfoList(inputLotNumber, dispProcessId);
            }
        }
    }

    /**
     * 工程コンボボックスのキーダウンイベント
     * @param {*} e 
     */
    function handleKouteiEnterKeyDown(e) {
        setTooltip(null);
        if (e.key === 'Enter' && selectedDispProcessId.length > 0) {
            setIsStopKeyUp(true);
            matomesuuBoxRefs.focus();
        }
    }

    /**
     * 仕掛かりリストを取得する処理
     * @param {*} lotNo 
     * @param {*} dispProcessId 
     */
    async function getShikakariInfoList(lotNo, dispProcessId) {
        await axios({
            method: 'GET',
            url: variables.OPERATION_URL + "/get-shikakari-list-by-process",
            params: { lotNo: lotNo, dispProcessId: dispProcessId },
            headers: { 'company': urlCompanyName },
            withCredentials: true,
        }).then(function (response) {
            let responseDataList = response.data;
            responseDataList.sort((a, b) => new Date(a.lastDate) - new Date(b.lastDate));
            if (responseDataList && responseDataList.length > 0) {
                setShikakariList(responseDataList.map(item => {
                    return {
                        ...emptyShikakariObj,
                        dispProcessId: item.dispProcessId,
                        dispWorkId: item.dispWorkId,
                        workName: item.workName,
                        dispOrder: item.dispOrder,
                        lotOperationLogLockId: item.lotOperationLogLockId,
                        userId: item.userId,
                        userName: item.userName,
                        matomesuu: item.matomesuu,
                        lastDate: item.lastDate,
                        status: item.locked ? ShikakariStatus.SAGYOUCHUU : ShikakariStatus.CHUUDAN
                    };
                }));
            }
        }).catch(function (error) {
            let errResponseStatus = error.response.status;
            if (errResponseStatus === 401) {
                NavigateToOperationLogin(navigate);
            }
            else {
                appInsights.trackTrace({ params: JSON.stringify({ lotNo: lotNo, dispProcessId: dispProcessId }) });
                appInsights.trackException({ ...error, errorFunction: "LotNumberStartWork.getShikakariInfoList()" });
                console.error(error);
            }
        });
    }

    /**
     * 工程を変更する場合、関係する各項目をリセットする処理
     */
    function resetByProcessChange() {
        sessionStorage.removeItem('matomesuu');
        setMatomesuu(0);
        setSagyouSaikai(false);
        setSelectedShikakariWork({ ...emptyShikakariObj });
        setMatomesuuCntOverErrorMsg("");
        setShikakariList([]);
        setMatomesuuDisable(false);
    }

    /**
     * まとめ数変更イベント
     * @param {*} value 
     */
    function handleMatomesuuChange(value) {
        setMatomesuu(value);
        matomesuuBoxRefs.value = value;
        setIsStopKeyUp(false);
        sessionStorage.setItem('matomesuu', value);
    }

    /**
     * まとめ数変更のキーアップイベント
     * @param {*} e 
     */
    function handleMatomesuuKeyUp(e) {
        if (e.key === 'Enter' && isActiveWork() && isStopKeyUp === false) {
            startWorkBtnRefs.focus();
        }
        setIsStopKeyUp(false);
    }

    /**
     * まとめ数変更のエラー確認する処理
     * @param {*} currMatomesuu 
     * @param {*} currDispProcessId 
     */
    function checkMatomesuuError(currMatomesuu) {
        //　工程が選択されて、中断が選択されていない場合、まとめ数確認を行う。
        if (selectedDispProcessId && selectedDispProcessId !== "" && selectedShikakariWork.lotOperationLogLockId === -1) {
            let currIdx = props.seisanInfoList.findIndex((x) => x.dispProcessId === selectedDispProcessId);
            if (currIdx > 0) {
                // 前の工程の生産状況を取る
                let prevObj = props.seisanInfoList[currIdx - 1];
                // 現在選択した工程の生産状況を取る
                let currObj = props.seisanInfoList[currIdx];
                let currTotalCnt = currObj.completedCount + currObj.inProgressCount + currMatomesuu;
                if (currTotalCnt > prevObj.completedCount) {
                    setMatomesuuCntOverErrorMsg(errorCode[errorCode.E1007]);
                }
                else {
                    setMatomesuuCntOverErrorMsg("");
                }
            }
            else if (currIdx === 0 && selectedItem !== null) {
                checkMatomesuuErrorByPlanQuantity(inputLotNumber, selectedDispProcessId, selectedItem.itemCode, currMatomesuu);
            }
        }

    }

    /**
     * 中断作業がある場合、確認ダイアログを出し
     * 「はい」：作業を開始する。
     * 「いいえ」：確認ダイアログを閉じる。
     * 中断作業がない場合、確認なしで作業を開始する。
     */
    async function handleStartWork() {
        var checkChuuDan = shikakariList.some(obj => obj.userId === selectedUser.userId && obj.status === ShikakariStatus.CHUUDAN);
        if (checkChuuDan) {
            props.setDialogBox(
                <ConfirmBox
                    className="absolute left-[565px] top-[394px] w-[521px]"
                    title="本当に作業開始してよろしいですか？"
                    message={<>
                        中断している作業があります。<br></br>
                        新規作業開始しても問題がないか確認してください。
                    </>}
                    foucsBtn={FocusButton.Btn2}
                    onYesClick={() => { StartWorkByLotNo(); props.setDialogBox(null); }}
                    onNoClick={() => { props.setDialogBox(null); }}
                />
            );
        }
        else {
            await StartWorkByLotNo();
        }
    }

    /**
     * 作業開始ボタンのクリックイベント
     * @param {*} e 
     */
    async function handleStartBtnClick(e) {
        await handleStartWork();
    }

    /**
     * 作業開始ボタンのキーアップイベント
     * @param {*} e 
     */
    async function handleStartBtnKeyUp(e) {
        if (e.key === 'Enter' && isStopKeyUp === false) {
            e.stopPropagation();
            e.preventDefault();
            await handleStartWork();
        }
        setIsStopKeyUp(false);
    }

    /**
     * 作業再開ボタンのクリックイベント
     * @param {*} e 
     */
    async function handleResumeWorkBtnClick(e) {
        await ResumeWorkByLotNo();
    }

    /**
     * 作業再開ボタンのキーアップイベント
     * @param {*} e 
     */
    async function handleResumeWorkBtnKeyUp(e) {
        if (e.key === 'Enter' && isStopKeyUp === false) {
            e.stopPropagation();
            e.preventDefault();
            await ResumeWorkByLotNo();
        }
        setIsStopKeyUp(false);
    }

    /**
     * 作業開始する処理
     */
    async function StartWorkByLotNo() {
        dispatch(setPageLoading(true));
        const userId = selectedUser.userId;
        const itemCode = selectedItem.itemCode;
        let currDispOrder = props.seisanInfoList.find((x) => x.dispProcessId === selectedDispProcessId)?.dispOrder ?? -1;
        const version = processList.find((x) => x.dispProcessId === selectedDispProcessId)?.version;
        let paramobj = {
            userId: userId,
            itemCode: itemCode,
            lotNo: inputLotNumber,
            dispProcessId: selectedDispProcessId,
            version: version,
            ischeckPlanQty: currDispOrder === 1,
            matomesuu: matomesuu
        };
        await axios({
            method: 'GET',
            url: variables.OPERATION_URL + "/start-work-by-lotno",
            params: paramobj,
            headers: { 'company': urlCompanyName },
            withCredentials: true
        }).then(function (response) {
            let responseData = response.data;
            let errLst = responseData.errorCodeList;
            errLst.forEach(err => {
                if (err === errorCode.E1003) {
                    dispatch(setSelectedUserErrorMsg(errorCode[err]));
                }
                if (err === errorCode.E1004 || err === errorCode.E1005) {
                    dispatch(setSelectedItemErrorMsg(errorCode[err]));
                }
                if (err === errorCode.E1015) {
                    setInputLotNumberErrorMsg(errorCode[err]);
                }
                if (err === errorCode.E1016) {
                    setMatomesuuCntOverErrorMsg(errorCode[err]);
                }
                if (err === errorCode.E1009 || err === errorCode.E1010) {
                    sessionStorage.removeItem('matomesuu');
                    let str = errorCode[err].split("\n");
                    let width = (err === errorCode.E1009) ? "w-[600px]" : "w-[830px]";
                    let title = (err === errorCode.E1009) ? "工程設定の変更" : "作業開始不可";
                    props.setDialogBox(
                        <ErrorBox
                            className={"absolute left-[550px] top-[750px] " + width}
                            Title={title}
                            Message={<>
                                {str.map((msg, idx) => (
                                    <div key={idx}>{msg}</div>))
                                }
                            </>}
                            onYesClick={() => { props.setDialogBox(null); window.location.reload(); }}
                        />
                    );
                }
            });
            if (errLst.length === 0) {
                // オペレーション画面へ移動する。
                navigateToOperation(responseData.lotOperationLogLockId, responseData.newLockKey, version);
            }
        }).catch(function (error) {
            let errResponseStatus = error.response.status;
            if (errResponseStatus === 401) {
                NavigateToOperationLogin(navigate);
            }
            else {
                appInsights.trackTrace({ params: JSON.stringify(paramobj) });
                appInsights.trackException({ ...error, errorFunction: "LotNumberStartWork.StartWorkByLotNo()" });
                console.error(error);
            }
        });
        dispatch(setPageLoading(false));
    }

    /**
     * 作業再開する処理
     */
    async function ResumeWorkByLotNo() {
        dispatch(setPageLoading(true));
        const userId = selectedUser.userId;
        const itemCode = selectedItem.itemCode;
        const version = processList.find((x) => x.dispProcessId === selectedDispProcessId)?.version;
        const lotOperationLogLockId = selectedShikakariWork.lotOperationLogLockId;
        let paramobj = {
            userId: userId,
            itemCode: itemCode,
            lotNo: inputLotNumber,
            dispProcessId: selectedDispProcessId,
            lotOperationLogLockId: lotOperationLogLockId,
            version: version
        };
        await axios({
            method: 'GET',
            url: variables.OPERATION_URL + "/resume-work-by-lotno",
            params: paramobj,
            headers: { 'company': urlCompanyName },
            withCredentials: true
        }).then(function (response) {
            let responseData = response.data;
            let errLst = responseData.errorCodeList;
            errLst.forEach(err => {
                if (err === errorCode.E1003) {
                    dispatch(setSelectedUserErrorMsg(errorCode[err]));
                }
                if (err === errorCode.E1004 || err === errorCode.E1005) {
                    dispatch(setSelectedItemErrorMsg(errorCode[err]));
                }
                if (err === errorCode.E1008 || err === errorCode.E1009) {
                    sessionStorage.removeItem('matomesuu');
                    let str = errorCode[err].split("\n");
                    let width = err === errorCode.E1008 ? "w-[830px]" : "w-[600px]";
                    let title = err === errorCode.E1008 ? "作業再開不可" : "工程設定の変更";
                    props.setDialogBox(
                        <ErrorBox
                            className={"absolute left-[550px] top-[750px] " + width}
                            Title={title}
                            Message={<>
                                {str.map((msg, idx) => (
                                    <div key={idx}>{msg}</div>))
                                }
                            </>}
                            onYesClick={() => { props.setDialogBox(null); window.location.reload(); }}
                        />
                    );
                }
            });
            if (errLst.length === 0) {
                // オペレーション画面へ移動する。
                navigateToOperation(lotOperationLogLockId, responseData.newLockKey, version);
            }
        }).catch(function (error) {
            let errResponseStatus = error.response.status;
            if (errResponseStatus === 401) {
                NavigateToOperationLogin(navigate);
            }
            else {
                console.error(error);
                appInsights.trackTrace({ params: JSON.stringify(paramobj) });
                appInsights.trackException({ ...error, errorFunction: "LotNumberStartWork.ResumeWorkByLotNo()" });
            }
        });
        dispatch(setPageLoading(false));
    }

    /**
     * オペレーション画面へ移動する処理
     * @param {*} lotOperationLogLockId 
     * @param {*} lockKey 
     * @param {*} version 
     */
    function navigateToOperation(lotOperationLogLockId, lockKey, version) {
        let dataObj = {
            selectedItem: selectedItem,
            selectedUser: selectedUser,
            lotNumber: inputLotNumber,
            managementType: OperationManagementType.LOTNUMBER,
            lockKey: lockKey,
            lotOperationLogLockId: lotOperationLogLockId,
            version: version
        };
        NavigateToOperation(navigate, dataObj);
    }

    /**
     * ツールチップ表示する処理
     * @param {*} workName 
     * @param {*} idx 
     * @param {*} e 
     */
    function handleMouseOverToDisplayTooltip(workName, idx, e) {
        if (displayTooltip === null && MeasureText(workName, tblFontSize, tdWidth)) {
            e.stopPropagation();
            e.preventDefault();
            let positionY = e.pageY - (e.currentTarget.clientHeight * 7);
            setTooltip(
                <div id={idx} style={{ position: 'absolute', left: '380px', top: positionY + 'px' }} onMouseLeave={() => { setTooltip(null); }}>
                    <div className={"max-w-lg break-all px-2 py-2 rounded-sm border-2 border-[#777777] bg-white text-[" + tblFontSize + "px]"}>
                        {workName}
                    </div>
                </div>
            );
        }
    }

    /**
     * 仕掛かりリストに作業を選択する処理
     * @param {*} singleObj 
     */
    function handleSelectedWorkChange(singleObj) {
        if (singleObj.status === ShikakariStatus.CHUUDAN) {
            if (singleObj.lotOperationLogLockId === selectedShikakariWork.lotOperationLogLockId && singleObj.dispWorkId === selectedShikakariWork.dispWorkId) {
                sessionStorage.removeItem('matomesuu');
                setMatomesuuCntOverErrorMsg("");
                setSelectedShikakariWork({ ...emptyShikakariObj });
                setMatomesuu(0);
                setMatomesuuDisable(false);
                setSagyouSaikai(false);
            }
            else {
                doSelectedShikakariWork(singleObj)
            }
        }
    }

    /**
     * 仕掛かりリストに作業を選択する処理
     * @param {*} singleObj 
     */
    function doSelectedShikakariWork(singleObj) {
        sessionStorage.removeItem('matomesuu');
        setMatomesuuCntOverErrorMsg("");
        setSelectedShikakariWork(singleObj);
        setMatomesuu(singleObj.matomesuu);
        setMatomesuuDisable(true);
        setSagyouSaikai(true);
    }

    /**
     * 各項目がエラー無しか確認する処理
     * @returns 
     */
    const isActiveWork = () => {
        if (itemName.length > 0 && userName.length > 0 && inputLotNumber.length > 0
            && selectedDispProcessId.length > 0 && matomesuu > 0
            && selectedUserErrorMsg.length === 0
            && selectedItemErrorMsg.length === 0
            && (matomesuuCntOverErrorMsg.length === 0 || selectedShikakariWork.lotOperationLogLockId > -1)
            && inputLotNumberErrorMsg.length === 0) {
            return true;
        }
        return false;
    }

    return (
        <div className="w-[755px] h-[880px] bg-white">
            <div className="absolute top-[5px] left-[164px]">
                <span className="font-bold text-[26px]">ユーザー</span>
                <div className="w-[600px] h-[65px] mt-1">
                    <ComboBox
                        height={65}
                        refComboBox={(e) => { userComboRefs = e; }}
                        isSearchable={true}
                        selectedOption={userName}
                        isError={(selectedUserErrorMsg.length > 0)}
                        itemsSource={userList}
                        displayMemberPath={"userName"}
                        selectedValuePath={"displayUserCode"}
                        onSelectedChange={handleUserSelectedChange}
                        onKeyDown={handleUserEnterKeyDown}
                        onFocusOut={handleUserFocusOut}
                        placeholder={"ユーザーIDを入力、またはリストからユーザー名を選択"}>
                    </ComboBox>
                </div>
                {(selectedUserErrorMsg.length > 0) && <ErrorText errMsg={selectedUserErrorMsg} />}
            </div>
            <div className="absolute top-[170px] left-[164px]">
                <span className="font-bold text-[26px]">製品</span>
                <div className="w-[600px] h-[65px] mt-1 focus:outline-none focus:border-[#0073CD]">
                    <ComboBox
                        height={65}
                        refComboBox={(e) => { itemComboRefs = e; }}
                        isSearchable={true}
                        selectedOption={itemName}
                        isError={(selectedItemErrorMsg.length > 0)}
                        itemsSource={itemListNoEnd}
                        displayMemberPath={"itemName"}
                        selectedValuePath={"displayItemCode"}
                        onSelectedChange={handleItemSelectedChange}
                        onKeyDown={handleItemEnterKeyDown}
                        onFocusOut={handleItemFocusOut}
                        placeholder={"製品コードを入力、またはリストから製品名を選択"}>
                    </ComboBox>
                </div>
                {(selectedItemErrorMsg.length > 0) && <ErrorText errMsg={selectedItemErrorMsg} />}
            </div>
            <div className="absolute top-[310px] left-[164px]">
                <span className="font-bold text-[26px]">ロット番号</span>
                <div className="w-[600px] h-[65px] mt-1 focus:outline-none focus:border-[#0073CD]">
                    <InputBox
                        refInputBox={(e) => { lotNumberInputRefs = e; }}
                        maxLength={50}
                        value={inputLotNumber}
                        textType={TextType.HALFWIDTH_SYMBOL_AUTOCHANGE}
                        isError={(inputLotNumberErrorMsg.length > 0)}
                        onChange={(e) => handleLotNumberChange(e)}
                        onKeyUp={(e, str) => handleLotNumberKeyUp(e, str)}
                        onFocusOut={(txt) => handleLotNumberFocusOut(txt)}
                        placeholder="ロット番号を入力">
                    </InputBox>
                </div>
                {(inputLotNumberErrorMsg.length > 0) && <ErrorText errMsg={inputLotNumberErrorMsg} />}
            </div>
            <div className="absolute top-[450px] left-[164px]">
                <div className="flex">
                    <div>
                        <span className="font-bold text-[26px]">工程</span>
                        <div className="w-[515px] h-[65px] mt-1">
                            <ComboBox
                                width={515}
                                height={65}
                                refComboBox={(e) => { kouteiComboRefs = e; }}
                                isSearchable={false}
                                selectedOption={selectedDispProcessId}
                                itemsSource={processList}
                                displayMemberPath={"displayText"}
                                selectedValuePath={"dispProcessId"}
                                onSelectedChange={handleKouteiSelectedChange}
                                onKeyDown={handleKouteiEnterKeyDown}
                                placeholder={"工程を選択"}>
                            </ComboBox>
                        </div>
                    </div>
                    <div className="ml-3 w-[250px] h-[65px]">
                        <span className="font-bold text-[26px]">まとめ数</span>
                        <div className="w-[102px] h-[65px] mt-1">
                            <InputNumberBox
                                refNumericInput={(e) => { matomesuuBoxRefs = e; }}
                                max={99999}
                                min={0}
                                width={102}
                                isError={(matomesuuCntOverErrorMsg.length > 0 && Number(matomesuu) > 0)}
                                disabled={matomesuuDisable}
                                value={matomesuu}
                                onTextChange={handleMatomesuuChange}
                                onKeyUp={handleMatomesuuKeyUp}
                                onFocusOut={() => { setIsStopKeyUp(false); }}
                                onClick={() => { matomesuuBoxRefs.focus(); }}
                            />
                        </div>
                        {(matomesuuCntOverErrorMsg.length > 0 && Number(matomesuu) > 0 && selectedShikakariWork.lotOperationLogLockId === -1) && <ErrorText errMsg={matomesuuCntOverErrorMsg} />}
                    </div>
                </div>
            </div>
            <div className={"absolute top-[574px] text-[24px] w-[928px] h-[210px] border-2 border-[#C8C6C4] shadow-md rounded-[2px]"}>
                <div className=" flex w-[924px] h-[50px] font-bold border-b-2 border-[#C8C6C4]">
                    <div className="w-[150px] py-3 border-r-2 border-[#C8C6C4]"><span className="ml-4">最後日時</span></div>
                    <div className="w-[170px] py-3 border-r-2 border-[#C8C6C4]"><span className="ml-4">ユーザー名</span></div>
                    <div className="w-[370px] py-3 border-r-2 border-[#C8C6C4]"><span className="ml-10">作業</span></div>
                    <div className="w-[110px] py-3 border-r-2 border-[#C8C6C4] text-center">まとめ数</div>
                    <div className="w-[100px] py-3"><span className="ml-4">状況</span></div>
                </div>
                <div className={"w-[924px] h-[156px] overflow-y-scroll overflow-x-hidden text-[" + tblFontSize + "px]"}>
                    <table width={910}>
                        {shikakariList.map((singleObj, idx) => (
                            <tbody key={idx}>
                                <tr ref={singleObj.lotOperationLogLockId === selectedShikakariWork.lotOperationLogLockId ? selectedRowRef : null}
                                    className={"h-[52px] border-b-2 font-bold cursor-pointer " + (singleObj.status === ShikakariStatus.SAGYOUCHUU ? " opacity-30 font-normal" :
                                        selectedShikakariWork.lotOperationLogLockId === singleObj.lotOperationLogLockId ? " bg-[#D8D7D5]" : " hover:bg-[#F3F2F1]")}
                                    onClick={() => handleSelectedWorkChange(singleObj)}>
                                    <td className="w-[140px]">
                                        <div className="w-[126px] text-left ml-4">{ToLocalDayAndTime(singleObj.lastDate)}</div>
                                    </td>
                                    <td className="w-[170px]">
                                        <div className="w-[145px] text-left ml-5 ellipse one-line">{singleObj.userName}</div>
                                    </td>
                                    <td className="w-[360px] ">
                                        <div className="flex">
                                            <div className="w-[25px] text-right ml-2 mr-2">{singleObj.dispOrder}</div>
                                            <div className={"w-[" + tdWidth + "px] ellipse one-line"}
                                                onMouseOver={(e) => handleMouseOverToDisplayTooltip(singleObj.workName, idx, e)}
                                                onMouseLeave={() => { setTooltip(null); }}
                                            >{singleObj.workName}</div>
                                        </div>
                                    </td>
                                    <td className="w-[110px]">
                                        <div className="w-[80px] text-right ml-2">{singleObj.matomesuu}</div>
                                    </td>
                                    <td className="w-[100px]">
                                        <div className="w-[80px] text-left ml-2">
                                            {(singleObj.status === ShikakariStatus.CHUUDAN) ? "中断" : (singleObj.status === ShikakariStatus.SAGYOUCHUU) ? "作業中" : ""}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        ))}
                    </table>
                </div>
            </div>
            <div className="absolute top-[800px] left-[350px]">
                {isSagyouSaikai ?
                    <div ref={(e) => { resumeWorkBtnRefs = e; }} tabIndex={0} className={isActiveWork() === true ? saikaiBtnEnable : buttonDisable}
                        onClick={isActiveWork() === false || isPageLoading ? void (0) : (e) => handleResumeWorkBtnClick(e)}
                        onKeyUp={isActiveWork() === false || isPageLoading ? void (0) : (e) => handleResumeWorkBtnKeyUp(e)}
                    >作業再開</div>
                    :
                    <div ref={(e) => { startWorkBtnRefs = e; }} tabIndex={0} className={isActiveWork() === true ? kaishiBtnEnable : buttonDisable}
                        onClick={isActiveWork() === false || isPageLoading ? void (0) : (e) => handleStartBtnClick(e)}
                        onKeyUp={isActiveWork() === false || isPageLoading ? void (0) : (e) => handleStartBtnKeyUp(e)}
                    >作業開始</div>
                }
            </div>
            {displayTooltip}
        </div>
    );
}

export default LotNumberStartWork;