import Select from "react-select";

function ComboBox(props) {
    let itemsSource = props.itemsSource;
    const labelCss = "py-" + props.verPadding + " overflow-hidden whitespace-nowrap overflow-ellipsis text-[" + props.fontSize + "px]";
    const colourStyles = {
        option: (base, state) => {
            return {
                ...base,
                backgroundColor: state.isFocused ? "#D8D7D5" : null,
                "&:active": {
                    backgroundColor: "#D8D7D5",
                },
                color: "#050505",
            };
        },
        placeholder: (base, state) => {
            return {
                ...base,
                color: "#71717a"
            };
        },
        control: (base, state) => ({
            ...base,
            boxShadow: 0,
            borderRadius: "2px",
            height: props.height + 'px',
            border: "2px solid " + (props.isError ? "#CD0000" : state.isFocused ? "#0073CD" : "#C8C6C4"),
            "&:hover": {
                borderColor: props.isError ? "#CD0000" : state.isFocused ? "#0073CD" : "#C8C6C4",
            }
        }),
    };

    function getOptions() {
        let options = [];
        if (itemsSource && itemsSource.length > 0) {
            if (props.selectedValuePath.length > 0 && props.displayMemberPath.length > 0) {
                itemsSource.forEach(item => {
                    let obj = {};
                    obj['value'] = item[props.selectedValuePath] + "," + item[props.displayMemberPath];
                    obj['label'] = <div className={labelCss}>{item[props.displayMemberPath]}</div>;
                    obj['targetValue'] = item[props.selectedValuePath];
                    obj['targetLabel'] = item[props.displayMemberPath];
                    options.push(obj);
                });
            }
        }
        return options;
    }

    const slectedValue = () => {
        if (props.selectedOption.length === 0) {
            return null;
        }
        let optionMatchLabel = itemsSource.find((item) => item[props.displayMemberPath] === props.selectedOption);
        if (optionMatchLabel) {
            return { label: <div className={labelCss + (props.fontBold ? " font-bold text-black" : "")}>{optionMatchLabel[props.displayMemberPath]}</div>, value: optionMatchLabel[props.selectedValuePath] };
        }
        else {
            let optionMatchValue = itemsSource.find((item) => item[props.selectedValuePath] === props.selectedOption);
            if (optionMatchValue) {
                return { label: <div className={labelCss + (props.fontBold ? " font-bold text-black" : "")}>{optionMatchValue[props.displayMemberPath]}</div>, value: optionMatchValue[props.selectedValuePath] };
            }
        }
        return { label: <div className={labelCss + (props.fontBold ? " font-bold text-black" : "")}>{props.selectedOption}</div>, value: "" };
    }

    const filterOptions = (candidate, input) => {
        if (input) {
            if (input.length > 0) {
                if (candidate.data.targetValue === input || candidate.data.targetLabel.toLowerCase().includes(input.toLowerCase())) {
                    return true;
                }
                else {
                    return false;
                }
            }
        }
        return true;
    };

    function onSelectedChange(e) {
        props.onSelectedChange(e);
    }

    function onKeyDown(e) {
        props.onKeyDown(e);
    }

    function onFocusOut(e) {
        props.onFocusOut(e);
    }

    return (
        <>
            <Select
                className={"react-select w-[" + props.width + "px]"}
                styles={colourStyles}
                ref={props.refComboBox}
                classNamePrefix="select"
                menuPlacement={"auto"}
                maxMenuHeight={props.maxMenuHeight}
                isSearchable={props.isSearchable}
                options={getOptions()}
                value={slectedValue()}
                onChange={onSelectedChange}
                onKeyDown={onKeyDown}
                filterOption={filterOptions}
                onBlur={props.isSearchable ? onFocusOut : void (0)}
                placeholder={<div className={"text-[" + props.fontSize + "px]"}>{props.placeholder}</div>}
                components={{ IndicatorSeparator: () => null }}>
            </Select>
        </>
    );
}

ComboBox.defaultProps = {
    refComboBox: null,
    isSearchable: false,
    verPadding: 2,
    placeholder: "",
    selectedOption: "",
    isError: false,
    maxMenuHeight: 540,
    height: 65,
    fontSize: 22,
    fontBold: true,
    width: 600,
    displayMemberPath: "",
    selectedValuePath: "",
    onSelectedChange: () => void (0),
    onKeyDown: () => void (0),
    onFocusOut: () => void (0),
}

export default ComboBox;