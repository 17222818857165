import React from "react";
import Save from "./Save";
import Discard from "./Discard";
import SectionTitle from "./SectionTitle";
import TextInput from "./TextInput";
import { TextType, variables, userType, Salt, CompanyLoginPath, popupcss } from "../Variables";
import bcrypt from "bcryptjs";
import ErrorBox from "./ErrorBox";
import ConfirmBox from "./ConfirmBox";
import { Navigate } from 'react-router-dom';
import { generatePath } from 'react-router';
import { SetIsEditing, GetUrlCompany } from "../features/util/commonFun";
import UserPassword from "./UserPassword";
import { appInsights } from "../features/util/ApplicationInsight";

const axios = require("axios");
var hash = require('object-hash');
const urlCompanyName = GetUrlCompany();

class UserInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: this.props.User,
      currentUser: this.props.User,
      dialogBox: null,
      oldPassword: "",
      isPasswordChange: false,
      isRedirectToCompanyLogin: false,
    };

    this.handleChangeDisplayUserId = this.handleChangeDisplayUserId.bind(this);
    this.handleChangeUserName = this.handleChangeUserName.bind(this);
    this.handleChangeUserType = this.handleChangeUserType.bind(this);
    this.handleCheckchange = this.handleCheckchange.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.registerOrUpdateUser = this.registerOrUpdateUser.bind(this);
    this.deleteDialog = this.deleteDialog.bind(this);
    this.complete = this.complete.bind(this);
    this.handleDiscardClick = this.handleDiscardClick.bind(this);

    this.onPasswordChangeClick = this.onPasswordChangeClick.bind(this);
    this.handleUserPasswordChange = this.handleUserPasswordChange.bind(this);
    this.handleUserPasswordUnMatch = this.handleUserPasswordUnMatch.bind(this);
    this.onOldPsMatch = this.onOldPsMatch.bind(this);
    this.onOldPsUnMatch = this.onOldPsUnMatch.bind(this);
  }

  componentDidUpdate() {
    SetIsEditing(this.isDataChange() || this.state.isPasswordChange);
  }

  componentWillUnmount() {
    SetIsEditing(false);
  }

  isDataChange() {
    if (hash(this.state.user) !== hash(this.state.currentUser)) {
      return true;
    } else {
      return false;
    }
  }

  //ユーザーコードValueをセットする。
  handleChangeDisplayUserId(e) {
    this.setState((state) => {
      return {
        user: {
          ...state.user,
          displayUserId: e.target.value,
        },
      };
    });
  }

  //ユーザー名Valueをセットする。
  handleChangeUserName(e) {
    this.setState((state) => {
      return {
        user: {
          ...state.user,
          userName: e.target.value,
        },
      };
    });
  }

  //退社をチェックをセットする。
  handleCheckchange(e) {
    this.setState((state) => {
      return {
        user: {
          ...state.user,
          isRetired: e.target.checked,
        },
      };
    });
  }

  //ユーザータイプをセットする。
  handleChangeUserType(event) {
    this.setState((state) => {
      return {
        user: {
          ...state.user,
          userTypeId: Number(event.target.value),
          password: Number(event.target.value) === userType.Sagyousha ? "" : state.currentUser.password
        },
        isPasswordChange: false
      };
    });
  }

  handleSaveClick(e) {
    if (
      this.state.user.displayUserId.length <= 0 &&
      this.state.user.userName.length <= 0
    ) {
      return;
    }

    //重複確認
    let paramObj = { userId: this.state.user.id, displayUserId: this.state.user.displayUserId }
    axios
      .get(variables.USER_URL + "/checkduplicate", {
        params: paramObj,
        headers: { 'company': urlCompanyName },
        withCredentials: true
      })
      .then((result) => {
        // ユーザーIDがDBに未存在の場合、登録確認ボックスを表示する。
        if (result.data === false) {
          this.setState({
            dialogBox: (
              <ConfirmBox
                className="absolute right-[162px] top-[265px] w-[471px]"
                title="入力内容の登録"
                message="登録しますか？"
                onYesClick={this.registerOrUpdateUser}
                onNoClick={this.deleteDialog}
              />
            ),
          });
        } else {
          // エラーボックスを表示する。
          this.setState({
            dialogBox: (
              <ErrorBox
                className="absolute right-[162px] top-[265px] w-[471px]"
                Title="入力内容の登録"
                Message="既に登録済みです。"
                onYesClick={this.deleteDialog}
              />
            ),
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          // ログイン画面へ移動する。
          this.redirect();
        }
        else {
          appInsights.trackTrace({ params: JSON.stringify(paramObj) });
          appInsights.trackException({ ...error, errorFunction: "UserInput.handleSaveClick()" });
        }
      });
  }

  registerOrUpdateUser() {
    let method = "POST";
    let body = {
      displayUserId: this.state.user.displayUserId,
      userName: this.state.user.userName,
      userTypeId: this.state.user.userTypeId,
      password: this.state.user.password,
    };
    if (this.state.user.id !== -1) {
      method = "PUT";
      body = {
        userId: this.state.user.id,
        displayUserId: this.state.user.displayUserId,
        userName: this.state.user.userName,
        userTypeId: this.state.user.userTypeId,
        password: this.state.user.userTypeId === userType.Sagyousha ? "" : this.state.user.password,
        isRetired: this.state.user.isRetired,
      };
    }

    axios({
      method: method,
      url: variables.USER_URL,
      headers: { 'company': urlCompanyName },
      withCredentials: true,
      data: body,
    })
      .then((result) => {
        this.complete();
      })
      .catch((error) => {
        if (error.response.status === 401) {
          // ログイン画面へ移動する。
          this.redirect();
        }
        else {
          appInsights.trackTrace({ data: JSON.stringify(body) });
          appInsights.trackException({ ...error, errorFunction: "UserInput.registerOrUpdateUser()" });
        }
      });
  }

  // 確認ダイアログ、エラーダイアログなどを削除する。
  deleteDialog() {
    this.setState({
      dialogBox: null,
    });
  }

  complete() {
    this.props.onComplete();
  }

  handleDiscardClick() {
    const msg = <>作業が破棄されます。よろしいですか？</>;
    if (
      this.state.user.id === -1 &&
      this.state.user.displayUserId.length === 0 &&
      this.state.user.userName.length === 0
    ) {
      this.complete();
    } else {
      this.setState({
        dialogBox: (
          <ConfirmBox
            className="absolute right-[162px] top-[265px] w-[471px]"
            title="変更の破棄"
            message={msg}
            onYesClick={this.complete}
            onNoClick={this.deleteDialog}
          />
        ),
      });
    }
  }

  //編集の時、【在籍状況】の状況を追加する。
  renderElement() {
    if (this.state.user.id !== -1)
      return (
        <div>
          <div className="my-[15px]">
            <SectionTitle Text="在籍状況" />
          </div>
          <div className="flex flex-row items-center mb-8">
            <div className="w-[135px]">
              <label htmlFor="retiredCheckBox">退社</label>
            </div>
            <div>
              <input
                type="checkbox"
                defaultChecked={this.state.user.isRetired}
                onChange={this.handleCheckchange}
              />
            </div>
          </div>
        </div>
      );
  }

  /**
   * パスワード変更イベントハンドラ
   * 備考：新しいパスワードと新しいパスワード（確認）が一致する時、このイベントが発生します。
   * @param {*} val 
   */
  handleUserPasswordChange(val) {
    if (val && val.length > 0) {
      this.setState((state) => {
        return {
          user: {
            ...state.user,
            password: bcrypt.hashSync(val, Salt),
          },
        };
      });
    }
  }

  /**
   * 新しいパスワードと新しいパスワード（確認）が一致しない場合、
   * 更新用にuser objectのpasswordを元に戻す。
   */
  handleUserPasswordUnMatch() {
    this.setState((state) => {
      return {
        user: {
          ...state.user,
          password: ""
        },
      };
    });
  }

  onPasswordChangeClick() {
    this.setState((state) => {
      return {
        user: {
          ...state.user,
          password: ""
        },
        isPasswordChange: true,
      };
    });
  }

  redirect() {
    this.setState({
      isRedirectToCompanyLogin: true
    });
  }

  onOldPsMatch(val) {
    this.setState({ oldPassword: val });
  }

  onOldPsUnMatch() {
    this.setState({ oldPassword: "" });
  }

  isEnableSave() {
    if (this.isDataChange() &&
      this.state.user.displayUserId.length > 0 &&
      this.state.user.userName.length > 0) {
      if (this.state.user.userTypeId === userType.Kanrisha) {
        if (this.state.isPasswordChange) {
          return (this.state.user.password.length > 0 &&
            this.state.oldPassword.length > 0 &&
            this.state.currentUser.password !== this.state.user.password);
        } else {
          return this.state.user.password.length > 0;
        }
      } else {
        return true;
      }
    }
    return false;
  }

  render() {
    if (this.state.isRedirectToCompanyLogin) {
      return (
        <Navigate to={generatePath(CompanyLoginPath, {
          company: urlCompanyName
        })} />
      );
    }

    return (
      <div className={popupcss}>
        <div className="h-[1033px] ml-[1270px] mt-[47px] w-[650px] bg-white border-l-2 shadow-md px-[15px] pt-[43px]">
          <SectionTitle Text={this.state.user.id !== -1 ? "ユーザーの編集" : "ユーザーの追加"} />
          <div className="flex flex-row">
            <Save
              onClick={this.handleSaveClick}
              isEnable={this.isEnableSave()}
            />
            <Discard onClick={this.handleDiscardClick} />
          </div>
          <div className="h-[2px] bg-[#C8C6C4] opacity-[.10] mx-[-15px]" />
          <div className="my-[15px]">
            <SectionTitle Text="基本情報" />
          </div>

          <div className="flex flex-row items-center mb-8">
            <div className="w-[135px]">
              <label htmlFor="displayUserId">ユーザーID</label>
            </div>
            <div className="text-[#CD0000] mx-3">
              <strong>*</strong>
            </div>
            <TextInput
              id="id"
              value={this.state.user.displayUserId}
              maxLength={50}
              onChange={this.handleChangeDisplayUserId}
              textType={TextType.HALFWIDTH_INPUT}
            />
          </div>
          <div className="flex flex-row items-center mb-8">
            <div className="w-[135px]">
              <label htmlFor="userName">ユーザー名</label>
            </div>
            <div className="text-[#CD0000] mx-3">
              <strong>*</strong>
            </div>
            <TextInput
              value={this.state.user.userName}
              maxLength={50}
              onChange={this.handleChangeUserName}
              textType={TextType.ALL}
            />
          </div>
          <div className="flex flex-row items-center mb-8">
            <div className="w-[135px]">
              <label htmlFor="userType">ユーザータイプ</label>
            </div>
            <div className="text-[#CD0000] mx-3">
              <strong>*</strong>
            </div>
            <div className="flex flex-row">
              <div className="w-[135px]">
                <input
                  type="radio"
                  id="sagyousha"
                  onChange={this.handleChangeUserType}
                  checked={this.state.user.userTypeId === userType.Sagyousha}
                  value={userType.Sagyousha}
                  name="user_type"
                />
                <label htmlFor="sagyousha" className="pl-1">
                  作業者
                </label>
              </div>
              <div className="w-[135px]">
                <input
                  type="radio"
                  id="kanrisha"
                  onChange={this.handleChangeUserType}
                  checked={this.state.user.userTypeId === userType.Kanrisha}
                  value={userType.Kanrisha}
                  name="user_type"
                />
                <label htmlFor="kanrisha" className="pl-1">
                  管理者
                </label>
              </div>
            </div>
          </div>
          <div>{this.renderElement()}</div>
          <div>
            {
              this.state.user.userTypeId === userType.Kanrisha &&
              <>
                {
                  (this.state.currentUser.password.length > 0 &&
                    this.state.isPasswordChange === false) ?
                    <>
                      <div className="flex flex-row items-center">
                        <div className="w-[135px]">
                          <label htmlFor="oldpassword">パスワード</label>
                        </div>
                        <div className="text-[#CD0000] mx-3">
                          <strong>*</strong>
                        </div>
                        <div>
                          <input
                            type="password"
                            id="oldpassword"
                            className="w-[373px] h-[29px] border-[1px] rounded-sm border-black outline-none outline-offset-0 focus:outline-[#0073CD] focus:border-0 "
                            value="************"
                            disabled={true}
                          />
                        </div>
                      </div>
                      <div className="my-[30px]">
                        <button
                          onClick={this.onPasswordChangeClick}
                          className="flex flex-row w-[122px] h-[21px] rounded mx-[166px] bg-[#0073CD] text-white  text-[16px] px-[15px] pb-[10px] hover:bg-[#0068B8]"
                        >
                          パスワード変更
                        </button>
                      </div>
                    </>
                    :
                    <UserPassword
                      OldPassword={this.state.currentUser.password}
                      onPsChange={this.handleUserPasswordChange}
                      onPsUnMatch={this.handleUserPasswordUnMatch}
                      onOldPsMatch={this.onOldPsMatch}
                      onOldPsUnMatch={this.onOldPsUnMatch} />
                }
              </>
            }
          </div>
          <div>{this.state.dialogBox}</div>
        </div>
      </div>
    );
  }
}
export default UserInput;
