import React from "react";
import { BiEdit } from "react-icons/bi";
import { IconContext } from "react-icons";
import { variables } from "../Variables";

function EditButton(props) {
    let iconColor = '#0073CD';
    let hoverbgColor = variables.HOVER_CSS;
    if (props.isLock) {
        iconColor = '#A19F9D';
        hoverbgColor = '';
    };

    return (
        <div onClick={props.isLock ? () => void (0) : props.onClick}
            className={props.className || "w-[84px] h-[53px]"} >
            <div
                className={props.editBtnCss + hoverbgColor}>
                <IconContext.Provider
                    value={{ color: iconColor, size: "16px", className: "self-center" }}>
                    <BiEdit />
                </IconContext.Provider>
                <div className={"ml-[6px]"}>
                    編集
                </div>
            </div>
        </div>
    );
}

EditButton.defaultProps = {
    editBtnCss: "flex items-center justify-center w-full h-full cursor-pointer border-[1px] border-[#C8C6C4] drop-shadow-[0_6px_6px_rgba(96,94,92,1)] bg-white ",
    isLock: false,
}

export default EditButton;