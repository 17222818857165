import React from "react";
import Save from "./Save";
import Discard from "./Discard";
import SectionTitle from "./SectionTitle";
import TextInput from "./TextInput";
import DropDown from "./DropDown";
import { variables, CompanyLoginPath, popupcss } from "../Variables";
import ErrorBox from "./ErrorBox";
import ConfirmBox from "./ConfirmBox";
import { TextType } from "../Variables";
import { Navigate } from 'react-router-dom';
import { generatePath } from 'react-router';
import { SetIsEditing, GetUrlCompany } from "../features/util/commonFun";
import { appInsights } from "../features/util/ApplicationInsight";

const axios = require("axios");
var hash = require('object-hash');
const urlCompanyName = GetUrlCompany();

class ItemInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: this.props.Item || {
        id: -1,
        displayItemCode: "",
        itemName: "",
        isEnd: "",
        categoryId: -1,
        categoryName: "",
      },
      currentItem: this.props.Item,
      dialogBox: null,
      categories: [],
      isRedirectToCompanyLogin: false
    };
    this.handleItemNamechange = this.handleItemNamechange.bind(this);
    this.handleDisplayItemCodechange = this.handleDisplayItemCodechange.bind(this);
    this.handleCheckchange = this.handleCheckchange.bind(this);
    this.handleCategoryNameDropDownChange = this.handleCategoryNameDropDownChange.bind(this);
    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.deleteDialog = this.deleteDialog.bind(this);
    this.registerOrUpdateItem = this.registerOrUpdateItem.bind(this);
    this.complete = this.complete.bind(this);
    this.handleDiscardClick = this.handleDiscardClick.bind(this);
    this.redirect = this.redirect.bind(this);
  }

  componentDidUpdate() {
    SetIsEditing(this.isDataChange());
  }

  componentWillUnmount() {
    SetIsEditing(false);
  }

  isDataChange() {
    if (hash(this.state.item) !== hash(this.state.currentItem)) {
      return true;
    } else {
      return false;
    }
  }

  //カテゴリ名のValueをセットする。
  handleCategoryNameDropDownChange(e) {
    this.setState((state) => {
      return {
        item: {
          ...state.item,
          categoryId: Number(e.target.value),
        },
      };
    });
  }

  //製品コードのValueをセットする。
  handleDisplayItemCodechange(e) {
    this.setState((state) => {
      return {
        item: {
          ...state.item,
          displayItemCode: e.target.value,
        },
      };
    });
  }

  //製品名のValueをセットする。
  handleItemNamechange(e) {
    this.setState((state) => {
      return {
        item: {
          ...state.item,
          itemName: e.target.value,
        },
      };
    });
  }

  //生産終了のチェックをセットする。
  handleCheckchange(e) {
    this.setState((state) => {
      return {
        item: {
          ...state.item,
          isEnd: e.target.checked,
        },
      };
    });
  }

  //   保存ボタン処理
  //    カテゴリ名、製品コードと製品名が登録済みか確認して、未存在の場合、登録確認メッセージを出す。
  //    存在の場合、エラーメッセージを出す。
  handleSaveClick(e) {
    if (
      this.state.item.categoryId !== -1 &&
      this.state.item.displayItemCode.length <= 0 &&
      this.state.item.itemName.length <= 0
    ) {
      return;
    }

    //重複確認
    let paramObj = {
      ItemCode: this.state.item.id,
      DisplayItemCode: this.state.item.displayItemCode,
      ItemName: this.state.item.itemName,
    };
    axios
      .get(variables.ITEM_URL + "/checkduplicate", {
        params: paramObj,
        headers: { 'company': urlCompanyName },
        withCredentials: true,
      })
      .then((result) => {
        // 製品コード又は製品名がDBに未存在の場合、登録確認ボックスを表示する。
        if (result.data === false) {
          this.setState({
            dialogBox: (
              <ConfirmBox
                className="absolute right-[162px] top-[265px] w-[471px]"
                title="入力内容の登録"
                message="登録しますか？"
                onYesClick={this.registerOrUpdateItem}
                onNoClick={this.deleteDialog}
              />
            ),
          });
        } else {
          // エラーボックスを表示する。
          this.setState({
            dialogBox: (
              <ErrorBox
                className="absolute right-[162px] top-[265px] w-[471px]"
                Title="入力内容の登録"
                Message="既に登録済みです。"
                onYesClick={this.deleteDialog}
              />
            ),
          });
        }
      })
      .catch((error) => {
        // ユーザー権限エラーの場合、ログイン画面へ移動する。
        if (error.response.status === 401) {
          // ログイン画面へ移動する。
          this.redirect();
        }
        else {
          appInsights.trackTrace({ params: JSON.stringify(paramObj) });
          appInsights.trackException({ ...error, errorFunction: "iteminput.handleSaveClick()" });
        }
      });
  }

  // 確認ダイアログ、エラーダイアログなどを削除する。
  deleteDialog() {
    this.setState({
      dialogBox: null,
    });
  }

  //　カテゴリ名、製品コードと製品名を登録もしくは更新する//
  registerOrUpdateItem() {
    // 新規登録の場合、PUT
    //　編集の場合、POST
    let method = "PUT";
    let body = {
      itemId: this.state.item.id,
      displayItemCode: this.state.item.displayItemCode,
      categoryId: this.state.item.categoryId,
      itemName: this.state.item.itemName,
      isEnd: this.state.item.isEnd,
    };
    if (this.state.item.id === -1) {
      method = "POST";
      body = {
        categoryId: this.state.item.categoryId,
        itemName: this.state.item.itemName,
        displayItemCode: this.state.item.displayItemCode,
      };
    }

    axios({
      method: method,
      url: variables.ITEM_URL,
      headers: { 'company': urlCompanyName },
      withCredentials: true,
      data: body,
    })
      .then((result) => {
        this.complete();
      })
      .catch((error) => {
        // ユーザー権限エラーの場合、ログイン画面へ移動する。
        if (error.response.status === 401) {
          // ログイン画面へ移動する。
          this.redirect();
        }
        else {
          appInsights.trackTrace({ data: JSON.stringify(body) });
          appInsights.trackException({ ...error, errorFunction: "iteminput.registerOrUpdateItem()" });
        }
      });
  }

  complete() {
    this.props.onComplete();
  }

  // 破棄ボタン処理
  handleDiscardClick() {
    const msg = <>作業が破棄されます。よろしいですか？</>;
    if (
      this.state.item.categoryId === -1 &&
      this.state.item.displayItemCode.length === 0 &&
      this.state.item.itemName.length === 0
    ) {
      this.complete();
    } else {
      this.setState({
        dialogBox: (
          <ConfirmBox
            className="absolute right-[162px] top-[265px] w-[471px]"
            title="変更の破棄"
            message={msg}
            onYesClick={this.complete}
            onNoClick={this.deleteDialog}
          />
        ),
      });
    }
  }

  componentDidMount() {
    axios({
      method: 'GET',
      url: variables.CATEGORY_URL,
      headers: { 'company': urlCompanyName },
      withCredentials: true
    }).then(
      response => {
        let data = response.data;
        data.sort((a, b) => a.name.localeCompare(b.name));
        this.setState({
          categories: data,
        });
      }).catch((error) => {
        if (error.response.status === 401) {
          // ログイン画面へ移動する。
          this.redirect();
        }
        else {
          appInsights.trackException({ ...error, errorFunction: "iteminput.componentDidMount()" });
        }
      });
  }

  renderElement() {
    if (this.state.item.id !== -1)
      return (
        <div>
          <div className="my-[15px]">
            <SectionTitle Text="生産状況" />
          </div>
          <div className="flex flex-row items-center  mb-8">
            <div className="w-[135px]">
              <label htmlFor="itemCode">生産終了</label>
            </div>
            <div>
              <input
                type="checkbox"
                defaultChecked={this.state.item.isEnd}
                onChange={this.handleCheckchange}
              />
            </div>
          </div>
        </div>
      );
  }

  redirect() {
    this.setState({
      isRedirectToCompanyLogin: true
    });
  }

  render() {
    if (this.state.isRedirectToCompanyLogin) {
      return (
        <Navigate to={generatePath(CompanyLoginPath, {
          company: urlCompanyName
        })} />
      );
    }

    return (
      <div className={popupcss}>
        <div className="h-[1033px] ml-[1270px] mt-[47px] w-[650px] bg-white border-l-2 shadow-md px-[15px] pt-[43px]">
          {this.state.item.id !== -1 ? (
            <SectionTitle Text="製品の編集" />
          ) : (
            <SectionTitle Text="製品の追加" />
          )}
          <div className="flex flex-row">
            {this.state.item.id !== -1 ? (
              <Save
                onClick={this.handleSaveClick}
                isEnable={
                  this.isDataChange() === true &&
                  this.state.item.categoryId !== -1 &&
                  this.state.item.displayItemCode.length > 0 &&
                  this.state.item.itemName.length > 0
                }
              />
            ) : (
              <Save
                onClick={this.handleSaveClick}
                isEnable={
                  this.state.item.categoryId !== -1 &&
                  this.state.item.displayItemCode.length > 0 &&
                  this.state.item.itemName.length > 0
                }
              />
            )}
            <Discard onClick={this.handleDiscardClick} />
          </div>
          <div className="h-[2px] bg-[#C8C6C4] opacity-[.10] mx-[-15px]" />
          <div className="my-[15px]">
            <SectionTitle Text="基本情報" />
          </div>
          <div className="flex flex-row items-center mb-8">
            <div className="w-[135px]">
              <label htmlFor="cateName">カテゴリ名</label>
            </div>
            <div className="text-[#CD0000] mx-3">
              <strong>*</strong>
            </div>
            <DropDown
              itemsSource={this.state.categories}
              selectedValuePath={"id"}
              displayMemberPath={"name"}
              onChange={this.handleCategoryNameDropDownChange}
              selectedValue={this.state.item.categoryId}
              firstOption="カテゴリを選択して下さい。"
            />
          </div>
          <div className="flex flex-row items-center mb-8">
            <div className="w-[135px]">
              <label htmlFor="itemCode">製品コード</label>
            </div>
            <div className="text-[#CD0000] mx-3">
              <strong>*</strong>
            </div>
            <TextInput
              id="itemCode"
              value={this.state.item.displayItemCode}
              maxLength={50}
              onChange={this.handleDisplayItemCodechange}
              textType={TextType.HALFWIDTH_SYMBOL_INPUT}
            />
          </div>
          <div className="flex flex-row items-center mb-8">
            <div className="w-[135px]">
              <label htmlFor="itemName">製品名</label>
            </div>
            <div className="text-[#CD0000] mx-3">
              <strong>*</strong>
            </div>
            <TextInput
              id="itemName"
              value={this.state.item.itemName}
              maxLength={50}
              onChange={this.handleItemNamechange}
            />
          </div>
          <div>{this.renderElement()}</div>
          <div>{this.state.dialogBox}</div>
        </div>
      </div>
    );
  }
}

export default ItemInput;
