import { React } from "react";
import { useDispatch, useSelector } from "react-redux";
import WorkDropDown from "./WorkDropDown";
import {
    setTxtCompSource,
    setTxtCompText,
    setTxtCompWorkID,
    setTxtCompMethod,
    setTxtCompKurikaeshi,
    getMngItmsOfTxtInputKuOFF,
} from "./manageItemSlice";
import { CompareSource, CompareMethod, LockAttributes } from "../../../Variables";
import { nanoid } from "nanoid";
import { selectProcessList } from "../process/processListSlice";
import { selectWorkListByProcessIdList } from "./workListSlice";
import KurikaeshiToggle from "../../../components/KurikaeshiToggle";

/**
 * 文字列比較の管理項目入力コンポネント
 * @param {*} props 
 * @returns 
 */
function ManageItemInputForTextCompare(props) {
    const dispatch = useDispatch();
    const processList = useSelector(selectProcessList);
    const workList = useSelector(state => selectWorkListByProcessIdList(state, processList.map(p => p.processID)));
    const mngItmsOfTxtInputKuriOFF = useSelector(getMngItmsOfTxtInputKuOFF);

    /**
     * 比較先作業リスト
     */
    let hikakusakiWrkList = [];
    if (workList && workList.length > 0) {
        if (mngItmsOfTxtInputKuriOFF && mngItmsOfTxtInputKuriOFF.length > 0) {
            hikakusakiWrkList = workList.filter(w => mngItmsOfTxtInputKuriOFF.findIndex(m => m.workID === w.workID) >= 0);
        }
    }

    /**
     * 比較先作業リストの工程リスト
     */
    let hikakusakiProcList = [];
    if (processList && processList.length > 0 && hikakusakiWrkList.length > 0) {
        let hikakusakiProcIDList = hikakusakiWrkList.map(w => w.processID);
        hikakusakiProcList = processList.filter(p => hikakusakiProcIDList.includes(p.processID));
    }

    //　管理項目変数
    const mngItm = props.mngItm;
    function onCompareSourceChange(e) {
        dispatch(setTxtCompSource({ workID: props.workID, txtCompSource: Number(e.target.value) }));
    }

    function onCompareTextChange(e) {
        dispatch(setTxtCompText({ workID: props.workID, txtCompText: e.target.value }));
    }

    /**
     * 登録作業ドロップダウンエベントハンドラ
     * @param {*} e 
     */
    function onDropDownChange(value) {
        dispatch(setTxtCompWorkID({ workID: props.workID, txtCompWorkID: value }));
    }

    function onCompareMethodChange(e) {
        dispatch(setTxtCompMethod({ workID: props.workID, txtCompMethod: Number(e.target.value) }));
    }

    function onTxtCompKurikaeshiChg(e) {
        dispatch(setTxtCompKurikaeshi({ workID: props.workID, txtCompKurikaeshi: !mngItm.data.txtCompKurikaeshi }));
    }

    return (
        <div>
            <div className="flex items-center">
                <span className={"font-bold"}>比較先</span>
                <span className="w-[40px] text-2xl font-bold text-red-500">*</span>
                <input type={"radio"} id="shitei" name="compare_source"
                    value={CompareSource.Shitei}
                    onChange={onCompareSourceChange}
                    checked={mngItm.data.txtCompSource === CompareSource.Shitei}
                    disabled={props.isLock}
                />
                <label htmlFor="shitei" className={"w-[55px] px-1"} >指定</label>
                <input type={"radio"} id="tourokuSagyou" name="compare_source"
                    value={CompareSource.Work}
                    onChange={onCompareSourceChange}
                    checked={mngItm.data.txtCompSource === CompareSource.Work}
                    disabled={props.isLock}
                />
                <label htmlFor="tourokuSagyou" className={"w-[90px] px-1"}>登録作業</label>
                <input type={"radio"} id="lotSerialNoCompare" name="compare_source"
                    value={CompareSource.LotSerialNo}
                    onChange={onCompareSourceChange}
                    checked={mngItm.data.txtCompSource === CompareSource.LotSerialNo}
                    disabled={props.isLock}
                />
                <label htmlFor="lotSerialNoCompare" className={"px-1"}>製造/シリアル/ロット番号</label>
            </div>
            <div>
                {
                    mngItm.data.txtCompSource === CompareSource.Shitei ?
                        <div className="flex items-center">
                            <textarea
                                className={"w-[444px] h-[38px] pl-[5px] after:content-['*'] after:text-red-500 resize-none border-2 border-[#C8C6C4] outline-offset-0 " +
                                    (props.isLock ? LockAttributes.ATTRIBUTES + " outline-none" : " focus:outline-[#0073CD]")}
                                placeholder="比較する文字列を入力してください（50文字以内）"
                                maxLength={50}
                                required={true}
                                value={mngItm.data.txtCompText}
                                onChange={onCompareTextChange}
                                readOnly={props.isLock}
                            />
                            <div className="text-[26px] font-bold pt-2 pl-[2px] text-red-500">*</div>
                        </div>
                        : mngItm.data.txtCompSource === CompareSource.Work ?
                            <div className="flex items-center">
                                <WorkDropDown
                                    key={nanoid()}
                                    firstOption="文字列登録を設定した作業を選択して下さい"
                                    selectedOption={props.mngItm.data.txtCompWorkID}
                                    onSelectedIndexChange={onDropDownChange}
                                    isLock={props.isLock}
                                    processList={hikakusakiProcList}
                                    workList={hikakusakiWrkList}
                                />
                                <div className="text-[26px] font-bold pt-1.5 pl-[2px] text-red-500">*</div>
                            </div>
                            : <div className="mb-[38px]"></div>
                }

            </div>
            <div className="flex items-center">
                <span className={"font-bold"}>比較方法</span>
                <span className="w-[65px] text-2xl font-bold text-red-500">*</span>
                <input type={"radio"} id="allmatch" name="compare_method"
                    value={CompareMethod.AllMatch}
                    onChange={onCompareMethodChange}
                    checked={mngItm.data.txtCompMethod === CompareMethod.AllMatch}
                    disabled={props.isLock}
                />
                <label htmlFor="allmatch" className={"w-[100px] mx-1"} >完全一致</label>
                <input type={"radio"} id="partialmatch" name="compare_method"
                    value={CompareMethod.PartialMatch}
                    onChange={onCompareMethodChange}
                    checked={mngItm.data.txtCompMethod === CompareMethod.PartialMatch}
                    disabled={props.isLock}
                />
                <label htmlFor="partialmatch" className={"mx-1"}>部分一致</label>
            </div>
            <div className="h-[1px] w-[350px] bg-[#C8C6C4] opacity-[.56] " />
            <KurikaeshiToggle
                isChecked={mngItm.data.txtCompKurikaeshi}
                onChange={onTxtCompKurikaeshiChg}
                isLock={props.isLock} />
        </div >
    );
}

ManageItemInputForTextCompare.defaultProps = {
    compareSource: CompareSource.Shitei,
    compareMethod: CompareMethod.AllMatch,
}
export default ManageItemInputForTextCompare;