import React from "react";
import Select from "react-select";

/**
 * 作業一覧ドロップダウン
 * @param {*} props 
 * @returns 
 */
function WorkDropDown(props) {
    // チャートの工程一覧
    let processList = props.processList;
    // チャートの作業一覧
    let workList = props.workList;

    const colourStyles = {
        option: (base, state) => {
            return {
                ...base,
                backgroundColor: state.isFocused ? "#D8D7D5" : null,
                "&:active": {
                    backgroundColor: "#D8D7D5",
                },
                color: "#050505",
            };
        },
        control: (base, state) => ({
            ...base,
            minHeight: props.height + 'px',
            height: props.height + 'px',
            boxShadow: 0,
            borderColor: state.isFocused ? "#0073CD" : base.borderColor,
            "&:hover": {
                borderColor: state.isFocused ? "#0073CD" : base.borderColor
            }
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            height: props.height + 'px',
        })
    };

    const getOptions = () => {
        let options = [];
        options.push({ label: <><div>{props.firstOption}</div></>, value: "" });
        if (processList && processList.length > 0) {
            processList.forEach(p => {
                let obj = {};
                let subOptions = [];
                workList.filter(w => w.processID === p.processID).forEach(w => {
                    let subObj = {};
                    subObj['value'] = w.workID;
                    subObj['label'] = <div className="flex">
                        <div className="text-right w-[30px]">{w.dispOrder}</div>
                        <div className={"ml-[6px] overflow-hidden whitespace-nowrap overflow-ellipsis w-[" + props.width + "px]"}>{w.workName}</div>
                    </div>;
                    subOptions.push(subObj);
                })
                obj['options'] = subOptions;
                obj['label'] = <><div className="font-bold text-[18px] border-t-2 pt-2 text-[#0073CD] overflow-hidden whitespace-nowrap overflow-ellipsis">{p.dispOrder}<span className="ml-[6px]">{p.processName}</span></div></>;
                options.push(obj);
            });
        }
        return options;
    }

    const selectedValue = () => {
        if (props.selectedOption !== "") {
            let work = workList.find((w) => w.workID === props.selectedOption);
            if (work) {
                let process = processList.find((p) => p.processID === work.processID);
                if (process) {
                    return { label: <>{process.dispOrder}-{work.dispOrder}<span className="ml-[6px]">{work.workName}</span></>, value: props.selectedOption };
                }
            }
        }
        return { label: <>{props.firstOption}</>, value: "" };
    }

    function onSelectedChange(e) {
        props.onSelectedIndexChange(e.value);
    }

    return (
        <div>
            <Select
                className={"react-select w-[" + props.width + "px]"}
                options={getOptions()}
                classNamePrefix="select"
                menuPlacement={"top"}
                maxMenuHeight={540}
                isSearchable={false}
                isDisabled={props.isLock}
                value={selectedValue()}
                onChange={onSelectedChange}
                styles={colourStyles}
            >
            </Select>
        </div >
    );
}

WorkDropDown.defaultProps = {
    height: 38,
    width: 397,
    firstOption: "",
    processList: [],
    workList: [],
};
export default WorkDropDown;