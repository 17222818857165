import { React, useEffect } from "react";
import SerialWorkCheckList from "./workCheck/SerialWorkCheckList";
import SerialChuudanWork from "./workCheck/SerialChuudanWork";
import SerialWorkInfo from "./workCheck/SerialWorkInfo";
import LotWorkCheckList from "./workCheck/LotWorkCheckList";
import LotChuudanWork from "./workCheck/LotChuudanWork";
import LotWorkInfo from "./workCheck/LotWorkInfo";
import { useLocation } from "react-router";
import { useNavigate } from 'react-router-dom';
import { NavigateToOperationStartWork } from "../util/commonFun";
import { OperationManagementType } from "../../Variables";
import { v4 as uuidv4 } from "uuid";
import PageLoading from "../../components/PageLoading";

function Operation(props) {
  let navigate = useNavigate();
  const location = useLocation();
  const managementType = location.state ? location.state.managementType : null;

  useEffect(() => {
    if (managementType === null) {
      NavigateToOperationStartWork(navigate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="relative w-[1920px] h-[1080px] bg-[#FFFFFF] overflow-hidden">
      <PageLoading />
      {(managementType === OperationManagementType.SERIALNUMBER) ?
        <div className="flex" key={uuidv4()}>
          <div>
            <div className="flex-row">
              <SerialWorkCheckList />
              <div className="mt-[3px]">
                <SerialChuudanWork />
              </div>
            </div>
          </div>
          <div>
            <div className="w-[1870px] h-[1080px]">
              <SerialWorkInfo />
            </div>
          </div>
        </div>
        : (managementType === OperationManagementType.LOTNUMBER) ?
          <div className="flex" key={uuidv4()}>
            <div>
              <div className="flex-row">
                <LotWorkCheckList />
                <div className="mt-[3px]">
                  <LotChuudanWork />
                </div>
              </div>
            </div>
            <div>
              <div className="w-[1870px] h-[1080px]">
                <LotWorkInfo />
              </div>
            </div>
          </div>
          : ""
      }
    </div>
  );
}
export default Operation;