import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { DetailDocType, variables } from '../../../Variables';
import { GetUrlCompany } from '../../util/commonFun';
import { appInsights } from "../../util/ApplicationInsight";

const axios = require('axios');
const urlCompanyName = GetUrlCompany();
export const fetchDetailDocByWorkID = createAsyncThunk("detailDoc/fetchDetailDocByWorkID",
    async wrkIDs => {
        const response = await axios({
            method: 'POST',
            url: variables.DETAIL_DOC_URL + "/get-by-workids",
            data: wrkIDs,
            headers: { 'company': urlCompanyName },
            withCredentials: true
        });
        return response.data;
    });

// state の書式: [{ detailDocID:-1, workID: '', detailDocType: DetailDocType.NONE, docFileName: '', objUrl: '' }]
const emptyDtlDocObj = {
    detailDocID: -1,
    workID: '',
    detailDocType: DetailDocType.NONE,
    docFileName: '',
    objUrl: ''
}

const initState = {
    dataList: [],
    backupDataList: []
};

export const detailDocSlice = createSlice({
    name: 'detailDoc',
    initialState: initState,
    reducers: {
        /**
         * 詳細資料動画ファイルを設定する。
         * param: { workID: string, fileName: string, objUrl: string }
         * @param {*} detailDoc 
         * @param {*} action 
         */
        setMovieFile: (detailDoc, action) => {
            const { workID, fileName, objUrl } = action.payload;
            if (workID && workID.length > 0) {
                const dDoc = detailDoc.dataList.find(d => d.workID === workID && d.detailDocType === DetailDocType.MOVIE);
                if (dDoc) {
                    dDoc.docFileName = fileName;
                    dDoc.objUrl = objUrl;
                } else {
                    detailDoc.dataList.push(
                        {
                            ...emptyDtlDocObj,
                            workID: workID,
                            detailDocType: DetailDocType.MOVIE,
                            docFileName: fileName,
                            objUrl: objUrl
                        });
                }
            }
        },
        /**
         * 詳細資料動画ファイルを削除する。
         * param: workID: <string>
         * @param {*} detailDoc 
         * @param {*} action 
         */
        deleteMovieFile: (detailDoc, action) => {
            const { workID } = action.payload;
            if (workID && workID.length > 0) {
                const idxToDel = detailDoc.dataList.findIndex(d => d.workID === workID && d.detailDocType === DetailDocType.MOVIE);
                if (idxToDel >= 0) {
                    detailDoc.dataList.splice(idxToDel, 1);
                }
            }
        },
        /**
         * 詳細資料PDFファイルを設定する。
         * param: workID: <string>, fileName: <string>, objUrl: <string>
         * @param {*} detailDoc 
         * @param {*} action 
         */
        setPdfFile: (detailDoc, action) => {
            const { workID, fileName, objUrl } = action.payload;
            if (workID && workID.length > 0) {
                const dDoc = detailDoc.dataList.find(d => d.workID === workID && d.detailDocType === DetailDocType.PDF);
                if (dDoc) {
                    dDoc.docFileName = fileName;
                    dDoc.objUrl = objUrl;
                } else {
                    detailDoc.dataList.push(
                        {
                            ...emptyDtlDocObj,
                            workID: workID,
                            detailDocType: DetailDocType.PDF,
                            docFileName: fileName,
                            objUrl: objUrl
                        });
                }
            }
        },
        /**
         * 詳細資料PDFファイルを削除する。
         * param: workID: <string>
         * @param {*} detailDoc 
         * @param {*} action 
         */
        deletePdfFile: (detailDoc, action) => {
            const { workID } = action.payload;
            if (workID && workID.length > 0) {
                const idxToDel = detailDoc.dataList.findIndex(d => d.workID === workID && d.detailDocType === DetailDocType.PDF);
                if (idxToDel >= 0) {
                    detailDoc.dataList.splice(idxToDel, 1);
                }
            }
        },
        /**
         * 詳細資料テキストファイルを設定する。
         * param: workID: <string>, fileName: <string>, objUrl: <string>
         * @param {*} detailDoc 
         * @param {*} action 
         */
        setTextFile: (detailDoc, action) => {
            const { workID, fileName, objUrl } = action.payload;
            if (workID && workID.length > 0) {
                const dDoc = detailDoc.dataList.find(d => d.workID === workID && d.detailDocType === DetailDocType.TEXT);
                if (dDoc) {
                    dDoc.docFileName = fileName;
                    dDoc.objUrl = objUrl;
                } else {
                    detailDoc.dataList.push(
                        {
                            ...emptyDtlDocObj,
                            workID: workID,
                            detailDocType: DetailDocType.TEXT,
                            docFileName: fileName,
                            objUrl: objUrl
                        });
                }
            }
        },
        /**
         * 詳細資料テキストファイルのデータを削除する。
         * param: workID: <string>
         * @param {*} detailDoc 
         * @param {*} action 
         */
        deleteTextFile: (detailDoc, action) => {
            const { workID } = action.payload;
            if (workID && workID.length > 0) {
                const idxToDel = detailDoc.dataList.findIndex(d => d.workID === workID && d.detailDocType === DetailDocType.TEXT);
                if (idxToDel >= 0) {
                    detailDoc.dataList.splice(idxToDel, 1);
                }
            }
        },
        /**
         * 作業IDリストで全部の詳細資料を削除する。
         * param: array of workID
         * @param {*} detailDoc 
         * @param {*} action 
         */
        deleteDtlDocByWorkIDList: (detailDoc, action) => {
            const lstWorkID = action.payload;
            if (lstWorkID && lstWorkID.length > 0) {
                const filteredList = detailDoc.dataList.filter(d => lstWorkID.includes(d.workID) === false);
                if (filteredList) {
                    detailDoc.dataList = filteredList;
                } else {
                    detailDoc.dataList = [];
                }
            }
        },
        resetDetailDoc: (detailDoc, action) => {
            return { ...initState };
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchDetailDocByWorkID.fulfilled, (detailDoc, action) => {
            const responseDetailDocList = action.payload;
            if (responseDetailDocList && responseDetailDocList.length > 0) {
                detailDoc.dataList = responseDetailDocList;
            }
            detailDoc.backupDataList = detailDoc.dataList.slice();
        });
        builder.addCase(fetchDetailDocByWorkID.rejected, (state, action) => {
            appInsights.trackException({ ...action.error, errorFunction: "detailDocSlice.builder.addCase(fetchCompletedRecords.rejected)" });
        });
    },
});

// stateから作業idで動画ファイルを取得する。
export const selectMovieFileByWorkId = (state, workID) => {
    const docMovie = state.detailDoc.dataList.find(d => d.workID === workID && d.detailDocType === DetailDocType.MOVIE);
    if (docMovie) {
        return docMovie;
    }
    return null;
};

// stateから作業idでPDFファイルを取得する。
export const selectPdfFileByWorkId = (state, workID) => {
    const docPdf = state.detailDoc.dataList.find(d => d.workID === workID && d.detailDocType === DetailDocType.PDF);
    if (docPdf) {
        return docPdf;
    }
    return null;
};

// stateから作業idでテキストファイルを取得する。
export const selectTextFileByWorkId = (state, workID) => {
    const docText = state.detailDoc.dataList.find(d => d.workID === workID && d.detailDocType === DetailDocType.TEXT);
    if (docText) {
        return docText;
    }
    return null;
};

export const selectAllDocs = (state) => {
    return state.detailDoc.dataList;
}

export const selectBackupDocs = (state) => {
    return state.detailDoc.backupDataList;
}

export const {
    setMovieFile,
    deleteMovieFile,
    setPdfFile,
    deletePdfFile,
    setTextFile,
    deleteTextFile,
    resetDetailDoc,
    deleteDtlDocByWorkIDList } = detailDocSlice.actions;
export default detailDocSlice.reducer;