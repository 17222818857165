import { React, useState } from "react";
import { useDispatch } from "react-redux";
import RadioButton from "../../../components/RadioButton";
import { SagyouBunkiType } from "../../../Variables";
import { clearBunkiData, setSagyouBunkiType } from "./manageItemSlice";
import SagyouBunkiList from "./SagyouBunkiList";
import ConfirmBox from "../../../components/ConfirmBox";
import SagyouBunkiBtn from "./SagyouBunkiBtn";

function MangeItemInputForSagyouBunki(props) {
    const dispatch = useDispatch();
    const workID = props.workID;
    const mngItm = props.mngItm;
    const bunkiData = mngItm.data.bunkiData;
    const [dialogBox, setDialogBox] = useState(null);

    function handleRadioBtnChange(sagyouBunkitype) {
        if (mngItm.data.sagyouBunkiType === sagyouBunkitype) {
            return;
        }
        if ((bunkiData.length >= 2 && isBunkiDataExist()) ||
            (bunkiData[0].btnText.length > 0 || bunkiData[1].btnText.length > 0)) {
            let msg = "";
            msg = (
                <>
                    <p className="break-all">
                        設定した内容が削除されます。
                        <br />変更しますか？
                    </p>
                </>
            );
            setDialogBox(
                <ConfirmBox
                    className="absolute left-[1400px] top-[630px] w-[471px]"
                    title="管理項目の変更"
                    message={msg}
                    onYesClick={() => handleSagyouBunkiTypeChgYes(sagyouBunkitype)}
                    onNoClick={handleSagyouBunkiTypeChgNo}
                />
            );
        }
        else {
            dispatch(setSagyouBunkiType({ workID: workID, sagyouBunkiType: sagyouBunkitype }));
        }
    }

    /**
     * BunkiDataがあるかどうかチェック
     * @returns bool
     */
    const isBunkiDataExist = () => {
        let isBunkiDataExist = bunkiData.some(data => data.workID !== "");
        return isBunkiDataExist;
    }

    function handleSagyouBunkiTypeChgYes(sagyouBunkitype) {
        dispatch(setSagyouBunkiType({ workID: workID, sagyouBunkiType: sagyouBunkitype }));
        dispatch(clearBunkiData({ workID: workID }));
        setDialogBox(null);
    }

    function handleSagyouBunkiTypeChgNo() {
        setDialogBox(null);
    }

    return (
        <div className="flex">
            <div>
                <RadioButton
                    className={"w-[50px] h-[40px] cursor-pointer text-center mr-4 mt-[-2px] rounded-[2px]"}
                    text={"ボタン"}
                    onChange={() => handleRadioBtnChange(SagyouBunkiType.BtnType)}
                    name="sagyouBunkiType"
                    checked={mngItm.data.sagyouBunkiType === SagyouBunkiType.BtnType}
                    isLock={props.isLock}
                />
                <RadioButton
                    className={"w-[50px] h-[40px] cursor-pointer text-center mr-4 rounded-[2px] mt-[10px]"}
                    text={"リスト"}
                    onChange={() => handleRadioBtnChange(SagyouBunkiType.ListType)}
                    name="sagyouBunkiType"
                    checked={mngItm.data.sagyouBunkiType === SagyouBunkiType.ListType}
                    isLock={props.isLock}
                />
            </div>
            <div>
                {mngItm.data.sagyouBunkiType === SagyouBunkiType.BtnType ?
                    <SagyouBunkiBtn mngItm={mngItm} />
                    :
                    <SagyouBunkiList isLock={props.isLock} />
                }
            </div>
            <div>
                {/* {ConfirmDialogBox} */}
                {dialogBox}
            </div>
        </div>
    );
};
export default MangeItemInputForSagyouBunki;