import React, { useEffect, useState } from "react";
import { DatePicker } from "../../components/DatePicker";
import SearchBox from "../../components/SearchBox";
import { ProductionPlanOrderColumn, ProductionPlanType, Sort, variables } from "../../Variables";
import { RestoreButton } from "../../components/RestoreButton";
import { setIsLoading as setPageLoading } from "../Loading/pageLoadingSlice";
import { useDispatch } from "react-redux";
import { ConvertDateFormat, GetLastorNextDays, ConvertDisplayDateFormat, GetUrlCompany, NavigateToCompanyLogin } from "../util/commonFun";
import TableTitle from "../../components/TableTitle";
import { useNavigate } from "react-router";
import { appInsights } from "../../features/util/ApplicationInsight";
import { PagingBar } from "../../components/PagingBar";
import ConfirmBox from "../../components/ConfirmBox";

const axios = require('axios');
const urlCompanyName = GetUrlCompany();

const emptyProductionPlanObj = {
    productionPlanId: -1,
    itemCode: -1,
    dispItemCode: "",
    itemName: "",
    serialNo: "",
    lotNo: "",
    dispProcessId: "",
    processName: "",
    quantity: 0,
    planDate: "",
    optionalText1: "",
    optionalText2: "",
    optionalText3: "",
    optionalText4: "",
    optionalText5: "",
    optionalNo: 0,
    isChecked: false,
}

const defaultSortColumnAndSortType = {
    sortColumn: ProductionPlanOrderColumn.None,
    sortType: Sort.NONE
}

let defaultDisplayRows = 50;
let initialTimeout = 800;
export function ProductionPlanRestore(props) {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const lastThreeDays = ConvertDateFormat(GetLastorNextDays(new Date(), -3));
    const nextThreeDays = ConvertDateFormat(GetLastorNextDays(new Date(), 5));
    const [startDate, setStartDate] = useState(lastThreeDays);
    const [endDate, setEndDate] = useState(nextThreeDays);
    const [dispItemCodeInput, setDispItemCodeInput] = useState("");
    const [itemNameInput, setItemNameInput] = useState("");
    const [serailLotNumberInput, setSerailLotNumberInput] = useState("");
    const [productionPlanList, setProductionPlanList] = useState([]);
    const [currPageNumber, setCurrPageNumber] = useState(1);
    const [totalRowCount, setTotalRowCount] = useState(0);
    const [sortColumnAndSortType, setSortColumnAndSortType] = useState(defaultSortColumnAndSortType);
    const [showRestoreConfirmBox, setShowRestoreConfirmBox] = useState(false);

    useEffect(() => {
        const timeOutID = setTimeout(function () {
            setCurrPageNumber(1);
            fetchProductionPlanData(startDate, endDate, dispItemCodeInput, itemNameInput, serailLotNumberInput, 1, sortColumnAndSortType.sortColumn, sortColumnAndSortType.sortType);
        }, initialTimeout);

        return function cleanUp() {
            clearTimeout(timeOutID);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDate, endDate, dispItemCodeInput, itemNameInput, serailLotNumberInput]);

    /**
    * 期限指定の組み合わせは適当であるか確認する
    * @returns 
    */
    function isDateCombinationOK() {
        if (startDate.length > 0 && endDate.length > 0) {
            let firstDate = new Date(startDate);
            let secondDate = new Date(endDate);
            return firstDate <= secondDate;
        }
        return true;
    }

    /**
     * 生産計画登録ボタンをクリックする操作
     */
    function handleRegisterTypeClick() {
        props.setSelectedWorkType(ProductionPlanType.REGISTER);
    }

    /**
     * 復元ボタンをクリック操作
     * @param {*} e 
     */
    function handleRestoreBtnClick(e) {
        let checkedCnt = productionPlanList.filter(x => x.isChecked === true).length;
        if (checkedCnt > 0) {
            setShowRestoreConfirmBox(true);
        }
    }

    /**
     * 1つ以上の削除した生産計画レコードを復元する処理
     */
    async function restoreProductionPlan() {
        dispatch(setPageLoading(true));
        let ids = productionPlanList.filter(x => x.isChecked === true).map(function (item) { return item.productionPlanId; });
        await axios({
            method: 'PUT',
            url: variables.PRODUCTION_PLAN_URL + "/restore-productionplan-by-id",
            data: ids,
            headers: { 'company': urlCompanyName },
            withCredentials: true
        }).then(response => {
            fetchProductionPlanData(startDate, endDate, dispItemCodeInput, itemNameInput, serailLotNumberInput, currPageNumber, sortColumnAndSortType.sortColumn, sortColumnAndSortType.sortType);
        }).catch(function (error) {
            let errResponseStatus = error.response.status;
            if (errResponseStatus === 401) {
                NavigateToCompanyLogin(navigate);
            }
            else {
                appInsights.trackTrace({ params: JSON.stringify({ productionPlanIds: ids.toString() }) });
                appInsights.trackException({ ...error, errorFunction: "ProductionPlanRestore.restoreProductionPlan()" });
            }
        });
        dispatch(setPageLoading(false));
    }

    /**
     * 完成予定日の検索するFromDate変更処理
     * @param {*} value 
     */
    function handleFromDateChange(value) {
        setStartDate(value);
    }

    /**
     * 完成予定日の検索するToDate変更処理
     * @param {*} value 
     */
    function handleToDateChange(value) {
        setEndDate(value);
    }

    /**
     * 製品コードの検索処理
     * @param {*} e 
     */
    function handleSearchItemCodeInputChange(e) {
        let inputItemCode = e.target.value;
        setDispItemCodeInput(inputItemCode);
    }

    /**
     * 製品名の検索処理
     * @param {*} e 
     */
    function handleSearchItemNameInputChange(e) {
        let inputItemName = e.target.value;
        setItemNameInput(inputItemName);
    }

    /**
     * 製造 / シリアル / ロット 番号の検索処理
     * @param {*} e 
     */
    function handleSearchNumberInputChange(e) {
        let inputSerialLotNumber = e.target.value;
        setSerailLotNumberInput(inputSerialLotNumber);
    }

    /**
     * 生産計画リストを取得する処理
     * @param {*} startDate 
     * @param {*} endDate 
     * @param {*} dispItemCode 
     * @param {*} itemName 
     * @param {*} serialLotNumber 
     * @param {*} currPageNumber 
     * @param {*} orderColumn 
     * @param {*} orderType 
     */
    async function fetchProductionPlanData(startDate, endDate, dispItemCode, itemName, serialLotNumber, currPageNumber, orderColumn, orderType) {
        dispatch(setPageLoading(true));
        if (isDateCombinationOK()) {
            let paramobj = {
                startDate: startDate,
                endDate: endDate,
                dispItemCode: dispItemCode,
                itemName: itemName,
                lotSerial: serialLotNumber,
                offsetRecord: (currPageNumber - 1) * defaultDisplayRows,
                deleteFlag: true,
                orderCol: orderColumn,
                orderType: orderType
            };
            await axios({
                method: 'GET',
                url: variables.PRODUCTION_PLAN_URL + "/get-production-plan",
                params: paramobj,
                headers: { 'company': urlCompanyName },
                withCredentials: true
            }).then(response => {
                setTotalRowCount(response.data.totalRowCount);
                let responseData = response.data.productionPlanList;
                setProductionPlanList(responseData.map(plan => {
                    return {
                        ...emptyProductionPlanObj,
                        productionPlanId: plan.productionPlanId,
                        itemCode: plan.itemCode,
                        dispItemCode: plan.dispItemCode,
                        itemName: plan.itemName,
                        serialNo: plan.serialNo,
                        lotNo: plan.lotNo,
                        dispProcessId: plan.dispProcessId,
                        processName: plan.processName,
                        quantity: plan.quantity,
                        planDate: ConvertDisplayDateFormat(plan.planDate),
                        optionalText1: plan.optionalText1,
                        optionalText2: plan.optionalText2,
                        optionalText3: plan.optionalText3,
                        optionalText4: plan.optionalText4,
                        optionalText5: plan.optionalText5,
                        optionalNo: plan.optionalNo,
                    };
                }));
            }).catch(function (error) {
                let errResponseStatus = error.response.status;
                if (errResponseStatus === 401) {
                    NavigateToCompanyLogin(navigate);
                }
                else {
                    appInsights.trackTrace({ params: JSON.stringify(paramobj) });
                    appInsights.trackException({ ...error, errorFunction: "ProductionPlanRestore.fetchProductionPlanData()" });
                }
            });
        } else {
            setTotalRowCount(0);
            setCurrPageNumber(1);
            setProductionPlanList([]);
        }
        dispatch(setPageLoading(false));
    }

    /**
     * チェックボックスの操作
     * @param {*} e 
     * @param {*} productionPlanId 
     */
    function handleCheckedChange(e, productionPlanId) {
        setProductionPlanList((prevState) => {
            let tmp = prevState.slice();
            let idx = tmp.findIndex((item) => item.productionPlanId === productionPlanId);
            if (idx > -1) {
                let updateObj = { ...tmp[idx] };
                updateObj.isChecked = e.target.checked;
                tmp[idx] = updateObj;
            }
            return tmp;
        });
    }

    /**
     * 生産計画データをソートする。
     * param: ProductionPlanOrderColumn
     * @param {*} sortCol 
     */
    function handleSort(sortCol) {
        let sortType = Sort.DESC;
        if (sortCol) {
            if (sortColumnAndSortType.sortColumn === sortCol
                && sortColumnAndSortType.sortType === Sort.DESC) {
                sortType = Sort.ASC;
            }
            let updateObj = {
                ...defaultSortColumnAndSortType,
                sortColumn: sortCol,
                sortType: sortType
            };
            setSortColumnAndSortType(updateObj);
            fetchDataWithoutTimer(currPageNumber, updateObj);
        }
    }

    /**
     * 最初のページに戻る処理
     * @param {*} e 
     */
    function handleGoToFirstPage(e) {
        let firstPage = 1;
        setCurrPageNumber(firstPage);
        fetchDataWithoutTimer(firstPage, sortColumnAndSortType);
    }

    /**
     * 前のページに移動する処理
     * @param {*} e 
     */
    function handleGoToPrevious(e) {
        let prevPage = currPageNumber - 1;
        setCurrPageNumber(prevPage);
        fetchDataWithoutTimer(prevPage, sortColumnAndSortType);
    }

    /**
     * 次のページに移動する処理
     * @param {*} e 
     */
    function handleGoToNext(e) {
        let nextPage = currPageNumber + 1;
        setCurrPageNumber(nextPage);
        fetchDataWithoutTimer(nextPage, sortColumnAndSortType);
    }

    /**
     * タイマー無しで生産計画データを取る
     * @param {*} pageNumber 
     * @param {*} sortColumnAndSortTypeObj 
     */
    function fetchDataWithoutTimer(pageNumber, sortColumnAndSortTypeObj) {
        fetchProductionPlanData(startDate, endDate, dispItemCodeInput, itemNameInput, serailLotNumberInput, pageNumber, sortColumnAndSortTypeObj.sortColumn, sortColumnAndSortTypeObj.sortType);
    }

    return (
        <>
            <div className="flex h-[60px] ml-1">
                <div className={"w-[165px] h-[54px] cursor-pointer text-center mr-4 rounded-[2px] bg-[#D8D7D5] border-2 hover:border-[#707070] hover:opacity-100 opacity-60"}
                    onClick={handleRegisterTypeClick}>
                    <div className="flex relative">
                        <div className="text-left mt-3 ml-2 text-[20px] font-bold">生産計画登録</div>
                        <input
                            className="absolute right-1 w-[18px] h-[18px] mt-2 cursor-pointer"
                            type="radio"
                            id={ProductionPlanType.REGISTER}
                            name={"register"}
                            checked={false}
                            readOnly={true} />
                    </div>
                </div>
                <div className={"w-[196px] h-[54px] cursor-pointer text-center mr-4 rounded-[2px] border-2 border-[#0073CD] bg-[white]"}>
                    <div className="flex relative">
                        <div className="text-left mt-3 ml-2 text-[20px] font-bold">削除した生産計画</div>
                        <input
                            className="absolute right-1 w-[18px] h-[18px] mt-2 cursor-pointer"
                            type="radio"
                            id={ProductionPlanType.RESTORE}
                            name={"restore"}
                            checked={true}
                            readOnly={true} />
                    </div>
                </div>
            </div>
            <div className=" mt-3">期間指定（完成予定日）</div>
            <div className=" flex">
                <DatePicker value={startDate}
                    onDateChange={(value) => handleFromDateChange(value)} />
                <span className=" px-2">～</span>
                <DatePicker value={endDate}
                    onDateChange={(value) => handleToDateChange(value)} />
            </div>
            <div className="flex mt-5 h-[72px]">
                <div className="h-[32px] mr-[15px]">
                    <label>製品コード</label>
                    <SearchBox width={220}
                        onChange={handleSearchItemCodeInputChange}
                        value={dispItemCodeInput} />
                </div>
                <div className="h-[32px] mr-[15px]">
                    <label>製品名</label>
                    <SearchBox width={220}
                        onChange={handleSearchItemNameInputChange}
                        value={itemNameInput} />
                </div>
                <div className="h-[32px] mr-[15px]">
                    <label>製造 / シリアル / ロット 番号</label>
                    <SearchBox width={220}
                        onChange={handleSearchNumberInputChange}
                        value={serailLotNumberInput} />
                </div>
            </div>
            <div>
                <RestoreButton className="w-[200px] h-[50px]"
                    isEnable={productionPlanList.some(x => x.isChecked === true)}
                    Text="生産計画の復元"
                    onClick={handleRestoreBtnClick} />
            </div>
            <div className="h-[2px] w-[1683px] bg-[#C8C6C4] opacity-[.56] absolute" />
            <div className="absolute inset-0 top-[401px] left-[237px] h-[2px] w-[1683px] bg-[#C8C6C4] opacity-[.56] " />
            <div className="w-[1682px] h-[670px] overflow-x-scroll mt-3">
                <table className="border-collapse">
                    <thead>
                        <tr>
                            <th className="header">
                                <div className="h-[30px] w-[30px]"></div>
                            </th>
                            <th className="header">
                                <div className="border-r-2 h-[30px] w-[120px] min-w-[50px] max-w-[1000px] resizable inner-resizer-disabled">
                                    <TableTitle horiPadding={5} Text="製品コード"
                                        Sort={sortColumnAndSortType.sortColumn === ProductionPlanOrderColumn.DispItemCode
                                            ? sortColumnAndSortType.sortType
                                            : Sort.NONE}
                                        onClick={() => handleSort(ProductionPlanOrderColumn.DispItemCode)} />
                                </div>
                            </th>
                            <th className="header">
                                <div className="border-r-2 h-[30px] w-[109px] min-w-[50px] max-w-[1000px] resizable inner-resizer-disabled">
                                    <TableTitle horiPadding={5} Text="製品名"
                                        Sort={sortColumnAndSortType.sortColumn === ProductionPlanOrderColumn.ItemName
                                            ? sortColumnAndSortType.sortType
                                            : Sort.NONE}
                                        onClick={() => handleSort(ProductionPlanOrderColumn.ItemName)} />
                                </div>
                            </th>
                            <th className="header">
                                <div className="border-r-2 h-[30px] w-[185px] min-w-[50px] max-w-[1000px] resizable inner-resizer-disabled">
                                    <TableTitle horiPadding={5} Text="製造/シリアル番号"
                                        Sort={sortColumnAndSortType.sortColumn === ProductionPlanOrderColumn.SerialNumber
                                            ? sortColumnAndSortType.sortType
                                            : Sort.NONE}
                                        onClick={() => handleSort(ProductionPlanOrderColumn.SerialNumber)} />
                                </div>
                            </th>
                            <th className="header">
                                <div className="border-r-2 h-[30px] w-[125px] min-w-[50px] max-w-[1000px] resizable inner-resizer-disabled">
                                    <TableTitle horiPadding={5} Text="ロット番号"
                                        Sort={sortColumnAndSortType.sortColumn === ProductionPlanOrderColumn.LotNumber
                                            ? sortColumnAndSortType.sortType
                                            : Sort.NONE}
                                        onClick={() => handleSort(ProductionPlanOrderColumn.LotNumber)} />
                                </div>
                            </th>
                            <th className="header">
                                <div className="border-r-2 h-[30px] w-[90px] min-w-[50px] max-w-[1000px] resizable inner-resizer-disabled" >
                                    <TableTitle horiPadding={5} Text="工程ID" iconShow={false} />
                                </div>
                            </th>
                            <th className="header">
                                <div className="border-r-2 h-[30px] w-[109px] min-w-[50px] max-w-[1000px] resizable inner-resizer-disabled">
                                    <TableTitle horiPadding={5} Text="工程名" iconShow={false} />
                                </div>
                            </th>
                            <th className="header">
                                <div className="border-r-2 h-[30px] w-[75px] min-w-[50px] max-w-[1000px] resizable inner-resizer-disabled">
                                    <TableTitle horiPadding={5} Text="計画数" iconShow={false} />
                                </div>
                            </th>
                            <th className="header">
                                <div className="border-r-2 h-[30px] w-[130px] min-w-[50px] max-w-[1000px] resizable inner-resizer-disabled">
                                    <TableTitle horiPadding={5} Text="完成予定日"
                                        Sort={sortColumnAndSortType.sortColumn === ProductionPlanOrderColumn.PlanDate
                                            ? sortColumnAndSortType.sortType
                                            : Sort.NONE}
                                        onClick={() => handleSort(ProductionPlanOrderColumn.PlanDate)} />
                                </div>
                            </th>
                            <th className="header">
                                <div className="border-r-2 h-[30px] w-[95px] min-w-[50px] max-w-[1000px] resizable inner-resizer-disabled">
                                    <TableTitle horiPadding={5} Text="任意入力1" iconShow={false} />
                                </div>
                            </th>
                            <th className="header">
                                <div className="border-r-2 h-[30px] w-[95px] min-w-[50px] max-w-[1000px] resizable inner-resizer-disabled">
                                    <TableTitle horiPadding={5} Text="任意入力2" iconShow={false} />
                                </div>
                            </th>
                            <th className="header">
                                <div className="border-r-2 h-[30px] w-[95px] min-w-[50px] max-w-[1000px] resizable inner-resizer-disabled">
                                    <TableTitle horiPadding={5} Text="任意入力3" iconShow={false} />
                                </div>
                            </th>
                            <th className="header">
                                <div className="border-r-2 h-[30px] w-[95px] min-w-[50px] max-w-[1000px] resizable inner-resizer-disabled">
                                    <TableTitle horiPadding={5} Text="任意入力4" iconShow={false} />
                                </div>
                            </th>
                            <th className="header">
                                <div className="border-r-2 h-[30px] w-[95px] min-w-[50px] max-w-[1000px] resizable inner-resizer-disabled">
                                    <TableTitle horiPadding={5} Text="任意入力5" iconShow={false} />
                                </div>
                            </th>
                            <th className="header">
                                <div className="border-r-2 h-[30px] w-[145px] min-w-[50px] max-w-[1000px] resizable inner-resizer-disabled" >
                                    <TableTitle horiPadding={5} Text="任意入力_数値" iconShow={false} />
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {productionPlanList.map((singleObj) => (
                            <tr key={singleObj.productionPlanId} className={"h-[53px] border-y-[1px] border-[#C8C6C4]-[.56] " + variables.HOVER_CSS + (singleObj.isChecked ? " bg-[#D8D7D5]" : "")}>
                                <td className="h-[30px] w-[30px]">
                                    <input key={singleObj.productionPlanId} type="checkbox" className="w-[21px] h-[21px] flex items-center ml-2 accent-[#0073CD] rounded-[2px]"
                                        checked={singleObj.isChecked}
                                        onChange={(e) => handleCheckedChange(e, singleObj.productionPlanId)} />
                                </td>
                                <td className="text-left pl-[8px] w-[50px] min-w-[50px] pr-3">
                                    <span className=" w-fit one-line">{singleObj.dispItemCode}</span>
                                </td>
                                <td className="text-left pl-[5px] w-[50px] min-w-[50px] pr-3"><span className=" w-full one-line">{singleObj.itemName}</span></td>
                                <td className="text-left pl-[5px] w-[50px] min-w-[50px] pr-3"><span className=" w-full one-line">{singleObj.serialNo}</span></td>
                                <td className="text-left pl-[5px] w-[50px] min-w-[50px] pr-3"><span className=" w-full one-line">{singleObj.lotNo}</span></td>
                                <td className="text-left pl-[5px] w-[50px] min-w-[50px] pr-3"><span className=" w-full one-line">{singleObj.dispProcessId}</span></td>
                                <td className="text-left pl-[5px] w-[50px] min-w-[50px] pr-3"><span className=" w-full one-line">{singleObj.processName}</span></td>
                                <td className="text-right pl-[5px] w-[50px] min-w-[50px] pr-3"><span className=" w-full one-line">{singleObj.quantity}</span></td>
                                <td className="text-left pl-[5px] w-[50px] min-w-[50px] pr-3"><span className=" w-full one-line">{singleObj.planDate}</span></td>
                                <td className="text-left pl-[5px] w-[50px] min-w-[50px] pr-3"><span className=" w-full one-line">{singleObj.optionalText1}</span></td>
                                <td className="text-left pl-[5px] w-[50px] min-w-[50px] pr-3"><span className=" w-full one-line">{singleObj.optionalText2}</span></td>
                                <td className="text-left pl-[5px] w-[50px] min-w-[50px] pr-3"><span className=" w-full one-line">{singleObj.optionalText3}</span></td>
                                <td className="text-left pl-[5px] w-[50px] min-w-[50px] pr-3"><span className=" w-full one-line">{singleObj.optionalText4}</span></td>
                                <td className="text-left pl-[5px] w-[50px] min-w-[50px] pr-3"><span className=" w-full one-line">{singleObj.optionalText5}</span></td>
                                <td className="text-right pl-[5px] w-[50px] min-w-[50px] pr-3"><span className=" w-full one-line">{singleObj.optionalNo}</span></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <PagingBar
                defaultDisplayRows={defaultDisplayRows}
                totalCount={totalRowCount}
                currPageNumber={currPageNumber}
                goToFirstPage={handleGoToFirstPage}
                goToPrevious={handleGoToPrevious}
                goToNext={handleGoToNext} />

            {
                showRestoreConfirmBox && <ConfirmBox
                    className="absolute left-[410px] top-[335px] w-[560px]"
                    title="生産計画の復元"
                    message={"選択した生産計画を復元しますか？"}
                    onYesClick={() => {
                        setShowRestoreConfirmBox(false);
                        restoreProductionPlan();
                    }}
                    onNoClick={() => setShowRestoreConfirmBox(false)} />
            }
        </>
    );
}