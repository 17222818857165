import { React } from "react";
import { useDispatch, useSelector } from "react-redux";
import WorkDropDown from "./WorkDropDown";
import {
    getSelectedWorkInfo,
    selectWorkListByProcessId,
} from "./workListSlice";
import { setBunkiWorkId } from "./manageItemSlice";
import { setBunkiBtnText } from "./manageItemSlice";
import { selectProcessList } from "../process/processListSlice";
import { nanoid } from "nanoid";
import InputBox from "../../../components/InputBox";
import { TextType } from "../../../Variables";

function SagyouBunkiBtn(props) {
    const dispatch = useDispatch();
    const mngItm = props.mngItm;
    const selectedWork = useSelector((state) => getSelectedWorkInfo(state));
    const selectedWorkID = selectedWork.workID;
    const selectedProcessId = selectedWork.processID;
    const processList = useSelector(selectProcessList);
    const bunkiDataList = [...mngItm.data.bunkiData].sort((a, b) => a.dispOrder - b.dispOrder);
    let selectedProcess = processList.filter((p) => p.processID === selectedProcessId);
    let workListByProcessId = useSelector((state) => selectWorkListByProcessId(state, selectedProcessId));
    workListByProcessId = workListByProcessId.filter((x) => x.workID !== selectedWork.workID);

    function onSagyouBunkiDropDownChange(value, dispOrder) {
        dispatch(setBunkiWorkId({ dispOrder: dispOrder, bunkiWorkId: value, workID: selectedWorkID }));
    }

    function onSagyouBunkiInputTextChange(inputText, dispOrder) {
        dispatch(setBunkiBtnText({ dispOrder: dispOrder, bunkiBtnText: inputText, workID: selectedWorkID }));
    }

    return (
        <div>
            <div className="flex">
                <span className={"font-bold w-[41px] mr-[9px]"}>分岐1</span>
                <WorkDropDown
                    key={nanoid()}
                    firstOption="分岐する作業を選択してください"
                    width={320}
                    height={29}
                    selectedOption={bunkiDataList[0].workID}
                    onSelectedIndexChange={(val) => onSagyouBunkiDropDownChange(val, 1)}
                    isLock={selectedWork.isLock}
                    processList={selectedProcess}
                    workList={workListByProcessId}
                />
                <div className="text-[26px] font-bold pt-1.5 pl-[2px] text-red-500">
                    *
                </div>
            </div>
            <div className="flex items-center ml-[50px] mt-[2px]">
                <InputBox
                    className="w-[210px] h-[29px] pl-[5px] border-[1px] rounded-[2px] resize-none border-[#C8C6C4] outline-none "
                    placeholder="ボタンの表示（5文字以内）"
                    maxLength={5}
                    required={true}
                    value={bunkiDataList[0].btnText}
                    onChange={(e) => onSagyouBunkiInputTextChange(e.target.value, 1)}
                    disabled={selectedWork.isLock}
                    textType={TextType.ALL}>
                </InputBox>
                <div className="text-[26px] font-bold pt-1 pl-[2px] text-red-500">
                    *
                </div>
            </div>
            <div className="flex mt-[10px]">
                <span className="font-bold w-[41px] mr-[9px] mt-1">分岐2</span>
                <WorkDropDown
                    key={nanoid()}
                    firstOption="分岐する作業を選択してください"
                    width={320}
                    height={29}
                    selectedOption={bunkiDataList[1].workID}
                    onSelectedIndexChange={(val) => onSagyouBunkiDropDownChange(val, 2)}
                    isLock={selectedWork.isLock}
                    processList={selectedProcess}
                    workList={workListByProcessId}
                />
                <div className="text-[26px] font-bold pt-1 pl-[2px] text-red-500">
                    *
                </div>
            </div>
            <div className="flex items-center ml-[50px] mt-[2px]">
                <InputBox
                    className="w-[210px] h-[29px] pl-[5px] border-[1px] rounded-[2px] resize-none border-[#C8C6C4] outline-none "
                    placeholder="ボタンの表示（5文字以内）"
                    maxLength={5}
                    required={true}
                    value={bunkiDataList[1].btnText}
                    onChange={(e) => onSagyouBunkiInputTextChange(e.target.value, 2)}
                    disabled={selectedWork.isLock}
                    textType={TextType.ALL}>
                </InputBox>
                <div className="text-[26px] font-bold pt-1.5 pl-[2px] text-red-500">
                    *
                </div>
            </div>
        </div>
    );
}

export default SagyouBunkiBtn;