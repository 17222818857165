import { React, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import TableTitle from "../../components/TableTitle";
import SectionTitle from "../../components/SectionTitle";
import Update from "../../components/Update";
import { ToUTCDateTime, DisplayDateFormatChange, ConvertDisplayDateFormat, ConvertDisplayDateTimeFormat } from "../util/commonFun";
import {
    fetchCompletedRecords,
    fetchCategoryList,
    getCategory,
    getCompletedRecords
} from "./completedRecordSlice";
import SelectWithCheckBox from "../../components/SelectWithCheckBox";
import SpinnerLoading from "../../components/SpinnerLoading";
import { IconContext } from "react-icons";
import { BiInfoCircle } from "react-icons/bi";
import SearchBox from "../../components/SearchBox";
import { DateTimePicker } from "../../components/DateTimePicker";

function RecordListTable(props) {
    const data = props.record;
    const array = props.array;
    const curIndex = props.curIndex;
    let borderStyle = " border-dashed";
    let categoryBorder = "";
    let categoryFontColor = " text-white ";
    let itemBorder = "";
    let itemFontColor = " text-white ";
    let processBorder = "";
    let processFontColor = " text-white ";
    let topBorder = " border-[#707070] border-t-2 border-opacity-50 ";
    let compleDateCss = " text-white ";

    let txtLeftCss = "h-[40px] align-text-top text-left pl-[16px] pt-[8px] overflow-hidden text-ellipsis whitespace-nowrap ";
    let subTotalCss = "h-[40px] align-text-top text-right font-bold pt-[8px] bg-[#F3F2F1] border-b-2 border-[#707070] border-dashed border-t-2 border-opacity-50 right-border ";
    let totalCss = "h-[40px] align-text-top text-right font-bold pt-[8px] bg-[#8D8C8B] border-b-2 border-[#707070] border-dashed border-t-2 border-opacity-50 text-white ";

    if (curIndex === 0 || array[curIndex - 1].categoryCode !== data.categoryCode) {
        borderStyle = " border-solid";
        categoryBorder = topBorder;
        categoryFontColor = " text-black ";
    }
    if (curIndex === 0 || array[curIndex - 1].dispProcessID !== data.dispProcessID ||
        ConvertDisplayDateFormat(array[curIndex - 1].completedDate) !== ConvertDisplayDateFormat(data.completedDate)) {
        compleDateCss = " text-black border-b-2 border-[#707070] border-dashed border-t-2 bg-[#F3F2F1] border-opacity-50 ";
    }
    if (data.completedDate === "") {
        compleDateCss = totalCss;
    }
    if (curIndex === 0 || array[curIndex - 1].dispItemCode !== data.dispItemCode) {
        itemBorder = topBorder;
        itemFontColor = " text-black ";
        processFontColor = " text-black ";
        processBorder = topBorder;
    } else {
        if (curIndex === 0 || array[curIndex - 1].dispProcessID !== data.dispProcessID) {
            processBorder = topBorder;
            processFontColor = " text-black ";
        }
    }

    return (
        <>
            {
                <tr className={categoryBorder + borderStyle}>
                    <td className={"w-[208px] max-w-[208px] right-border " + txtLeftCss + categoryFontColor + categoryBorder + borderStyle}>
                        {data.categoryName}
                    </td>
                    <td className={"w-[216px] max-w-[216px] right-border " + txtLeftCss + itemFontColor + itemBorder + borderStyle}>
                        {data.dispItemCode}
                    </td>
                    <td className={"w-[305px] max-w-[305px] right-border " + txtLeftCss + itemFontColor + itemBorder + borderStyle}>
                        {data.itemName}
                    </td>
                    <td className={"w-[274px] max-w-[274px] right-border " + txtLeftCss + processFontColor + processBorder + borderStyle}>
                        {data.processName}
                    </td>
                    <td className={"w-[140px] max-w-[140px] " + txtLeftCss + compleDateCss}>
                        {data.completedDate !== "" ? ConvertDisplayDateFormat(data.completedDate) : ""}
                    </td>
                    {(data.subTotal) ?
                        <td className={"w-[248px] max-w-[248px] pr-[20px] " + subTotalCss}>
                            {data.subTotal}
                        </td> :
                        (data.total)
                            ?
                            <td className={"w-[248px] max-w-[248px] pr-[20px] " + totalCss}>
                                {data.total}
                            </td> :
                            <td className={"w-[248px] max-w-[248px] right-border " + txtLeftCss}>
                                {data.lotSerialNo}
                            </td>
                    }
                    {(data.subTotalComplQty) ?
                        <td className={"w-[98px] max-w-[98px] pl-[20px] pr-[16px] " + subTotalCss}>
                            {data.subTotalComplQty}
                        </td>
                        :
                        (data.totalCompletedQty) ?
                            <td className={"w-[98px] max-w-[98px] pl-[20px] pr-[16px] " + totalCss}>
                                {data.totalCompletedQty}
                            </td> :
                            <td className="w-[98px] max-w-[98px] h-[40px] align-text-top text-right pl-[20px] pt-[8px] pr-[16px] right-border">
                                {data.completedQty}
                            </td>
                    }
                </tr>
            }
        </>
    );
}

function CompletedRecord() {
    const dispatch = useDispatch();
    const categoryList = useSelector(getCategory);
    const completedRecordList = useSelector(getCompletedRecords);
    const [checkedCategoryList, setcheckedCategoryList] = useState([]);
    const [spinnerLoading, setSpinnerLoading] = useState(null);
    const [UpdateTime, setUpdateTime] = useState("");
    const [dispItemCodeFilterTxt, setDispItemCodeFilterTxt] = useState("");
    const [itemNameFilterTxt, setItemNameFilterTxt] = useState("");
    const [processNameFilterTxt, setProcessNameFilterTxt] = useState("");
    const [lotSerialNoFilterTxt, setLotSerialNoFilterTxt] = useState("");
    const sevenDaysAgoDate = new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000);
    // Set time to 00:00:00
    sevenDaysAgoDate.setHours(0, 0, 0);
    var startDateTimeUTC = ToUTCDateTime(sevenDaysAgoDate);

    var currentTime = new Date();
    var currentDateTimeUTC = ToUTCDateTime(currentTime);

    const [startDate, setStartDate] = useState(ConvertDisplayDateTimeFormat(sevenDaysAgoDate));
    const [endDate, setEndDate] = useState(ConvertDisplayDateTimeFormat(currentTime));
    let lotCompleRecords = completedRecordList.filter(data => data.isLot === true);
    let serialCompleRecords = completedRecordList.filter(data => data.isLot === false);
    let groupedLotRecords = [];

    if (lotCompleRecords.length > 0) {
        //カテゴリー名、製品、工程、完了日、lotSerialNoでソートする。
        lotCompleRecords.sort((a, b) => a.categoryName.localeCompare(b.categoryName) || a.dispItemCode.localeCompare(b.dispItemCode)
            || a.dispOrder - b.dispOrder || a.completedDate.localeCompare(b.completedDate) || a.lotSerialNo.localeCompare(b.lotSerialNo));

        for (let i = 0; i < lotCompleRecords.length; i++) {
            if (i === 0 || (lotCompleRecords[i - 1].categoryCode !== lotCompleRecords[i].categoryCode ||
                lotCompleRecords[i - 1].dispItemCode !== lotCompleRecords[i].dispItemCode
                || lotCompleRecords[i - 1].dispProcessID !== lotCompleRecords[i].dispProcessID
                || lotCompleRecords[i - 1].completedDate !== lotCompleRecords[i].completedDate
                || lotCompleRecords[i - 1].lotSerialNo !== lotCompleRecords[i].lotSerialNo)) {

                //カテゴリー名、製品、工程、完了日、lotSerialNoが同じリストを取得して、まとめ数と足す。
                const sameCatItmProDateLotNo = lotCompleRecords.filter(d => d.categoryCode === lotCompleRecords[i].categoryCode && d.dispItemCode === lotCompleRecords[i].dispItemCode
                    && d.dispProcessID === lotCompleRecords[i].dispProcessID && d.completedDate === lotCompleRecords[i].completedDate && d.lotSerialNo === lotCompleRecords[i].lotSerialNo);
                const completedQtyList = sameCatItmProDateLotNo.map(tdata => tdata.completedCount);
                var lotCompletedQty = 0;
                lotCompletedQty = completedQtyList.reduce(function (a, b) {
                    return a + b;
                });

                groupedLotRecords.push({
                    categoryCode: lotCompleRecords[i].categoryCode, categoryName: lotCompleRecords[i].categoryName, dispItemCode: lotCompleRecords[i].dispItemCode, itemName: lotCompleRecords[i].itemName,
                    processName: lotCompleRecords[i].processName, lotSerialNo: lotCompleRecords[i].lotSerialNo, completedCount: lotCompletedQty,
                    dispOrder: lotCompleRecords[i].dispOrder, dispProcessID: lotCompleRecords[i].dispProcessID, completedDate: lotCompleRecords[i].completedDate, isLot: lotCompleRecords[i].isLot,
                });
            }
        }
    }

    //シリアルレコードとロットレコードをConcatして、カテゴリー、製品、工程、完了日でソートする。
    let recordsList = serialCompleRecords.concat(groupedLotRecords);
    recordsList.sort((a, b) => a.categoryName.localeCompare(b.categoryName) || a.dispItemCode.localeCompare(b.dispItemCode)
        || a.dispOrder - b.dispOrder || a.completedDate.localeCompare(b.completedDate) || a.isLot - b.isLot);

    //レコード一覧から、チェックされたカテゴリのレコードを取得する。
    let recordsByChkCatList = checkedCategoryList.flatMap((cat) => (
        recordsList.filter(data => data.categoryCode === cat.id)
    ));

    if (dispItemCodeFilterTxt.length > 0) {
        recordsByChkCatList = recordsByChkCatList.filter(data => data.dispItemCode.toLowerCase().includes(dispItemCodeFilterTxt.toLowerCase()));
    }

    if (itemNameFilterTxt.length > 0) {
        recordsByChkCatList = recordsByChkCatList.filter(data => data.itemName.toLowerCase().includes(itemNameFilterTxt.toLocaleLowerCase()));
    }

    if (processNameFilterTxt.length > 0) {
        recordsByChkCatList = recordsByChkCatList.filter(data => data.processName.toLowerCase().includes(processNameFilterTxt.toLowerCase()));
    }

    if (lotSerialNoFilterTxt.length > 0) {
        recordsByChkCatList = recordsByChkCatList.filter(data => data.lotSerialNo.toLowerCase().includes(lotSerialNoFilterTxt.toLowerCase()));
    }

    if (startDate.length > 0) {
        recordsByChkCatList = recordsByChkCatList.filter(data => new Date(data.completedDate) >= new Date(startDate));
    }

    if (endDate.length > 0) {
        recordsByChkCatList = recordsByChkCatList.filter(data => new Date(data.completedDate) <= new Date(endDate));
    }

    let sortedRecordsByChkCatList = recordsByChkCatList.sort((a, b) => a.categoryName.localeCompare(b.categoryName) || a.dispItemCode.localeCompare(b.dispItemCode) || a.dispOrder - b.dispOrder)

    //ユニークなカテゴリリストを取得する。
    const uniqueCatList = sortedRecordsByChkCatList.map(c => c.categoryCode).filter((cat, index, arr) => arr.indexOf(cat) === index);

    function handleUpdateClick() {
        var updateDateTime = new Date();
        var updateDateTimeUTC = ToUTCDateTime(updateDateTime);
        setSpinnerLoading(<SpinnerLoading />);
        const comRecPromise = dispatch(fetchCompletedRecords({ startDate: startDateTimeUTC, endDate: updateDateTimeUTC }));
        Promise.all([comRecPromise])
            .then((responses) => { setSpinnerLoading(null); });
        setUpdateTime(DisplayDateFormatChange(updateDateTime));
        setEndDate(ConvertDisplayDateTimeFormat(updateDateTime));
    }

    useEffect(() => {
        setSpinnerLoading(<SpinnerLoading />);
        const comRecPromise = dispatch(fetchCompletedRecords({ startDate: startDateTimeUTC, endDate: currentDateTimeUTC }));
        const catPromise = dispatch(fetchCategoryList());
        Promise.all([catPromise, comRecPromise])
            .then((responses) => { setSpinnerLoading(null); });
        setUpdateTime(DisplayDateFormatChange(new Date()));
    }, []);

    useEffect(() => {
        setcheckedCategoryList(categoryList);
    }, [categoryList]);

    function handleAllCheckClick() {
        if (checkedCategoryList.length === categoryList.length) {
            setcheckedCategoryList([]);
        }
        else {
            setcheckedCategoryList(categoryList)
        }
    }

    function onCheckBoxChange(e) {
        let id = Number(e.target.value);
        if (e.target.checked) {
            let checkedData = categoryList.find(itm => itm.id === id);
            setcheckedCategoryList([...checkedCategoryList, checkedData]);
        }
        else {
            setcheckedCategoryList(checkedCategoryList.filter(item => item.id !== id));
        }
    }

    function handleSearchDispItemCode(e) {
        setDispItemCodeFilterTxt(e.target.value);
    }

    function handleSearchItemName(e) {
        setItemNameFilterTxt(e.target.value);
    }

    function handleSearchProcessName(e) {
        setProcessNameFilterTxt(e.target.value);
    }

    function handleSearchLotSerialNo(e) {
        setLotSerialNoFilterTxt(e.target.value);
    }

    /**
   * 完了実績の検索するStartDate変更処理
   * @param {*} value 
   */
    function handleStartDateChange(value) {
        setStartDate(value);
    }

    /**
    * 完了実績の検索するEndDate変更処理
    * @param {*} value 
    */
    function handleEndDateChange(value) {
        setEndDate(value);
    }

    //同じカテゴリ、製品と工程のリストを取得する。
    const sameCatItmProList = (records) => {
        var data = [];
        for (var i = 0; i < records.length; i++) {
            const categoryCode = records[i].categoryCode;
            const categoryName = records[i].categoryName;
            const dispItemCode = records[i].dispItemCode;
            const itemName = records[i].itemName;
            const processName = records[i].processName;
            const dispProcessID = records[i].dispProcessID;

            let isDuplicateExist = false;
            if (data.find(d => d.categoryCode === categoryCode && d.dispItemCode === dispItemCode && d.dispProcessID === dispProcessID)) {
                isDuplicateExist = true;
            }

            if (isDuplicateExist === false) {
                data.push({ categoryCode: categoryCode, categoryName: categoryName, dispItemCode: dispItemCode, itemName: itemName, processName: processName, dispProcessID: dispProcessID });
            }
        }
        return data;
    }

    const recordsWithTotal = () => {
        const newlist = [];
        for (let i = 0; i < uniqueCatList.length; i++) {
            const categoryCode = uniqueCatList[i];

            const recordsByCatCode = sortedRecordsByChkCatList.filter(x => x.categoryCode === categoryCode);
            const unqCatItmProList = sameCatItmProList(recordsByCatCode);

            for (let j = 0; j < unqCatItmProList.length; j++) {
                const LotSerialNumDiffList = recordsByCatCode.filter(data => data.dispItemCode === unqCatItmProList[j].dispItemCode && data.dispProcessID === unqCatItmProList[j].dispProcessID);
                //同じカテゴリ、製品と工程の完了数を足す。
                const completedQtyList = LotSerialNumDiffList.map(tdata => tdata.completedCount);
                var totalCompletedQty = 0;
                totalCompletedQty = completedQtyList.reduce(function (a, b) {
                    return a + b;
                });

                for (let k = 0; k < LotSerialNumDiffList.length; k++) {
                    let qty = 0;
                    //同じ完了日の完了数を足す。
                    if (k === 0 || ConvertDisplayDateFormat(LotSerialNumDiffList[k - 1].completedDate) !== ConvertDisplayDateFormat(LotSerialNumDiffList[k].completedDate)) {
                        let sameCompletedDateList = LotSerialNumDiffList.filter(data => ConvertDisplayDateFormat(data.completedDate) === ConvertDisplayDateFormat(LotSerialNumDiffList[k].completedDate));
                        qty = sameCompletedDateList.map(data => data.completedCount);
                        let subTotalComplQty = qty.reduce(function (a, b) {
                            return a + b;
                        });
                        newlist.push({ categoryCode: LotSerialNumDiffList[k].categoryCode, categoryName: LotSerialNumDiffList[k].categoryName, dispItemCode: LotSerialNumDiffList[k].dispItemCode, itemName: LotSerialNumDiffList[k].itemName, processName: LotSerialNumDiffList[k].processName, subTotal: "小計", subTotalComplQty: subTotalComplQty, dispProcessID: LotSerialNumDiffList[k].dispProcessID, completedDate: LotSerialNumDiffList[k].completedDate });

                        for (let l = 0; l < sameCompletedDateList.length; l++) {
                            //同じシリアル／ロット番号の完了数を足す。
                            if (l === 0 || sameCompletedDateList[l - 1].lotSerialNo !== sameCompletedDateList[l].lotSerialNo) {
                                let sameLotSerialNoList = sameCompletedDateList.filter(data => data.lotSerialNo === sameCompletedDateList[l].lotSerialNo);
                                qty = sameLotSerialNoList.map(data => data.completedCount);
                                let subTotalComplQty = qty.reduce(function (a, b) {
                                    return a + b;
                                });
                                newlist.push({ categoryCode: sameCompletedDateList[l].categoryCode, categoryName: sameCompletedDateList[l].categoryName, dispItemCode: sameCompletedDateList[l].dispItemCode, itemName: sameCompletedDateList[l].itemName, processName: sameCompletedDateList[l].processName, completedQty: subTotalComplQty, lotSerialNo: sameCompletedDateList[l].lotSerialNo, dispProcessID: sameCompletedDateList[l].dispProcessID, completedDate: sameCompletedDateList[l].completedDate });
                            }
                        }
                    }
                }

                newlist.push({ categoryCode: unqCatItmProList[j].categoryCode, categoryName: unqCatItmProList[j].categoryName, dispItemCode: unqCatItmProList[j].dispItemCode, itemName: unqCatItmProList[j].itemName, processName: unqCatItmProList[j].processName, total: "合計", totalCompletedQty: totalCompletedQty, dispProcessID: unqCatItmProList[j].dispProcessID, completedDate: "" });
            }
        }
        return newlist;
    }

    return (
        <>
            <div className="flex flex-row">
                <div>
                    <div className="my-[11px] h-[21px]">生産実績</div>
                    <div className="flex flex-row">
                        <SectionTitle className="h-[34px] mt-[5px]" Text="完了実績"></SectionTitle>
                        <div className="flex mb-[5px] ml-[35px]">
                            <Update className="w-[84px]" onClick={handleUpdateClick} Text="更新" ></Update>
                            <div className="w-[242px] h-[36px] border-[1px] rounded border-opacity-1 border-solid border-color[#D6D5D3] text-[#605E5C]">
                                <div className="w-[225px] h-[21px] text-[16px] text-[#605E5C] ml-[8px] mt-[5px] mb-[8px] mr-[9px]">最終更新：{UpdateTime}</div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-[#DFF6DD] w-[1673px] h-[37px]">
                        <div className="inline-flex ml-[19px] pt-[8px]">
                            <IconContext.Provider value={{ color: "#000000", size: "20px", className: "self-center" }}>
                                <BiInfoCircle />
                            </IconContext.Provider>
                            <div className="w-[355px] h-[21px] text-[16px] ml-[8px] text-[#000000]">
                                過去7日間の完了実績を確認できます。
                            </div>
                        </div>
                    </div>
                    <div className="h-[2px] mt-[4px] w-[1683px] bg-[#C8C6C4] opacity-[.56] absolute" />
                    <div className="mt-3 mb-[3px]">期間指定（完了日）</div>
                    <div className="flex">
                        <DateTimePicker
                            value={startDate}
                            minDate={ConvertDisplayDateFormat(sevenDaysAgoDate)}
                            maxDate={ConvertDisplayDateFormat(currentTime)}
                            onDateChange={(value) => handleStartDateChange(value)} />
                        <span className=" px-2">～</span>
                        <DateTimePicker
                            value={endDate}
                            minDate={ConvertDisplayDateFormat(sevenDaysAgoDate)}
                            maxDate={ConvertDisplayDateFormat(currentTime)}
                            onDateChange={(value) => handleEndDateChange(value)} />
                    </div>
                    <div className="mt-[10px]">
                        <div className="flex">
                            <div>
                                <div className="h-[21px] w-[79px] text-[16px]">カテゴリ選択</div>
                                <SelectWithCheckBox
                                    categoryList={categoryList}
                                    checkedCategoryList={checkedCategoryList}
                                    valueMemberPath="id"
                                    displayMemberPath="name"
                                    handleAllCheckClick={handleAllCheckClick}
                                    onCheckBoxChange={(cat) => onCheckBoxChange(cat)} />
                            </div>
                            <div className="h-[32px] ml-[15px]">
                                <label>製品コード</label>
                                <SearchBox width={225}
                                    value={dispItemCodeFilterTxt}
                                    onChange={handleSearchDispItemCode} />
                            </div>
                            <div className="h-[32px] ml-[15px]">
                                <label>製品名</label>
                                <SearchBox width={225}
                                    value={itemNameFilterTxt}
                                    onChange={handleSearchItemName} />
                            </div>
                            <div className="h-[32px] ml-[15px]">
                                <label>工程名</label>
                                <SearchBox width={225}
                                    value={processNameFilterTxt}
                                    onChange={handleSearchProcessName} />
                            </div>
                            <div className="h-[32px] ml-[15px]">
                                <label>製造 / シリアル / ロット 番号</label>
                                <SearchBox width={225}
                                    value={lotSerialNoFilterTxt}
                                    onChange={handleSearchLotSerialNo} />
                            </div>
                        </div>
                        <div className="w-[1669px] h-[40px] mt-[11px]">
                            <div className="w-[208px] h-[40px] pt-[11px] inline-flex border-r border-r-gray-100 items-center">
                                <TableTitle
                                    className="px-[-15px]"
                                    iconShow={false}
                                    Text="カテゴリ" />
                            </div>
                            <div className="w-[216px] h-[40px] pt-[11px] inline-flex border-r border-r-gray-100">
                                <TableTitle
                                    className="px-[-15px]"
                                    iconShow={false}
                                    Text="製品コード" />
                            </div>
                            <div className="w-[305px] h-[40px] pt-[11px] inline-flex border-r border-r-gray-100">
                                <TableTitle
                                    className="px-[-15px]"
                                    iconShow={false}
                                    Text="製品名" />
                            </div>
                            <div className="w-[274px] h-[40px] pt-[11px] inline-flex border-r border-r-gray-100">
                                <TableTitle
                                    className="px-[-15px]]"
                                    iconShow={false}
                                    Text="工程名" />
                            </div>
                            <div className="w-[140px] h-[40px] pt-[11px] inline-flex border-r border-r-gray-100">
                                <TableTitle
                                    className="px-[-15px]]"
                                    iconShow={false}
                                    Text="完了日" />
                            </div>
                            <div className="w-[248px] h-[40px] pt-[11px] inline-flex border-r border-r-gray-100 items-center">
                                <TableTitle
                                    className="px-[-15px] w-[233px]"
                                    iconShow={false}
                                    Text="製造 / シリアル / ロット 番号" />
                            </div>
                            <div className="w-[98px] h-[40px] pt-[11px] inline-flex border-r border-r-gray-100">
                                <TableTitle
                                    className="mx-[35px] w-[98px]"
                                    iconShow={false}
                                    Text="完了" />
                            </div>
                        </div>
                        <div className="h-[3px] bg-[#707070] opacity-[.56]" />
                        <div className="h-[712px] overflow-y-scroll overflow-x-hidden">
                            <table className="border-collapse mt-[-2px] pl-[72px] border-b-2 border-[#707070] border-opacity-50">
                                <tbody>
                                    {
                                        recordsWithTotal().map((record, index, array) => <RecordListTable key={index} record={record} array={array} curIndex={index} />)
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div >
            {/* Loading... */}
            {spinnerLoading}</>
    )
}
export default CompletedRecord;