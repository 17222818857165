import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ToggleButton from "../../../components/ToggleButton";
import WorkDropDown from "./WorkDropDown";
import { toggleOkNgBranch, setOkWorkID, setNgWorkID } from "./manageItemSlice";
import { getSelectedWorkInfo } from "./workListSlice";
import { nanoid } from "nanoid";
import { selectWorkListByProcessId } from "./workListSlice";
import { selectProcessList } from "../process/processListSlice";

/**
 * OK/NG判定の管理項目入力コンポネント
 * @param {*} props 
 * @returns 
 */
function ManageItemInputForOKNG(props) {
    const dispatch = useDispatch();
    const selectedWork = useSelector((state) => getSelectedWorkInfo(state));
    const selectedProcessId = selectedWork.processID;
    const processList = useSelector(selectProcessList);
    let selectedProcess = processList.filter(p => p.processID === selectedProcessId);
    let workListByProcessId = useSelector(state => selectWorkListByProcessId(state, selectedProcessId));
    workListByProcessId = workListByProcessId.filter(x => x.workID !== selectedWork.workID);

    const textColor = selectedWork.isLock ? " text-[#A19F9D]" : "";
    function onBunkiSetteiChange() {
        if (selectedWork.workID !== "" && selectedWork.workID.length > 0 && !selectedWork.isLock) {
            dispatch(toggleOkNgBranch({ workID: props.workID }));
        }
    }

    function onOkDropDownChange(value) {
        dispatch(setOkWorkID({ workID: props.workID, okWorkID: value }));
    }

    function onNgDropDownChange(value) {
        dispatch(setNgWorkID({ workID: props.workID, ngWorkID: value }));
    }

    return (
        <div className="h-[148px] grid grid-rows-3 content-evenly items-center -mt-2">
            <div className="flex">
                <span className={"font-bold mr-[13px] " + (selectedWork.isLock ? "text-[#A19F9D]" : "")}>分岐設定</span>
                <ToggleButton isChecked={props.mngItm.data.okNgBranch} onChange={onBunkiSetteiChange} inputID={"bunkisettei"} isLock={selectedWork.isLock} />
            </div>
            {
                props.mngItm.data.okNgBranch &&
                <div className="flex -mt-5 ml-2">
                    <span className={"font-bold w-[22px] mr-[9px] ml-2 mt-1.5 " + textColor}>OK</span>
                    <WorkDropDown
                        key={nanoid()}
                        firstOption="分岐無し（通常のOK/NG判定処理）"
                        selectedOption={props.mngItm.data.okWorkID}
                        onSelectedIndexChange={onOkDropDownChange}
                        isLock={selectedWork.isLock}
                        processList={selectedProcess}
                        workList={workListByProcessId} />
                    <div className="text-[26px] font-bold pt-2.5 pl-[2px] text-red-500">*</div>
                </div>
            }

            {
                props.mngItm.data.okNgBranch &&
                <div className="flex -mt-5 ml-2">
                    <span className={"font-bold w-[22px] mr-[9px] ml-2 mt-1.5 " + textColor}>NG</span>
                    <WorkDropDown
                        key={nanoid()}
                        firstOption="分岐無し（通常のOK/NG判定処理）"
                        selectedOption={props.mngItm.data.ngWorkID}
                        onSelectedIndexChange={onNgDropDownChange}
                        isLock={selectedWork.isLock}
                        processList={selectedProcess}
                        workList={workListByProcessId}
                    />
                    <div className="text-[26px] font-bold pt-2.5 pl-[2px] text-red-500">*</div>
                </div>
            }

        </div>
    );
}

export default ManageItemInputForOKNG;