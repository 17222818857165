import { React, useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { GetUrlCompany, NavigateToOperation, NavigateToOperationLogin } from "../../util/commonFun";
import ComboBox from "../../../components/ComboBox";
import InputBox from "../../../components/InputBox";
import { errorCode } from "../../../ErrorCode";
import { OperationManagementType, TextType, variables } from "../../../Variables";
import { setIsLoading as setPageLoading, getIsLoading as getIsPageLoading } from "../../Loading/pageLoadingSlice";
import {
    resetInitState,
    setIsWorkImageExpandMode,
} from "../serialOperationSlice";
import {
    fetchItemData,
    fetchUserData,
    setSelectedUserErrorMsg,
    setSelectedItemErrorMsg,
} from "../operationStartWorkSlice";
import ErrorText from "../../../components/ErrorText";
import { appInsights } from "../../util/ApplicationInsight";

const axios = require('axios');
const urlCompanyName = GetUrlCompany();
const buttonDisable = "bg-[#F3F2F1] w-[229px] h-[87px] text-[26px] cursor-pointer text-center textmiddle rounded-[2px]";
const kaishiBtnEnable = "bg-[#0073CD] w-[229px] h-[87px] text-[26px] text-white hover:bg-[#0068B8] cursor-pointer text-center textmiddle rounded-[2px]";
const saikaiBtnEnable = "bg-[#D3EDFB] w-[229px] h-[87px] text-[26px] text-black hover:bg-[#C1D9E6] cursor-pointer text-center textmiddle rounded-[2px]";
let excludeKey = ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'Tab', 'Alt', 'Control', 'Meta', 'Shift', 'Escape'];
let userComboRefs = null;
let itemComboRefs = null;
let serialInputRefs = null;
let startWorkBtnRefs = null;
let isChooseOption = false;

function SerialNumberStartWork(props) {
    const dispatch = useDispatch();
    const isPageLoading = useSelector(getIsPageLoading);
    const items = useSelector((state) => state.operationStartWork);
    const itemList = [...items.dataList.itemList];
    const itemListNoEnd = itemList.filter((item) => item.isEnd === false)
    const userList = [...items.dataList.userList];
    const selectedUserErrorMsg = items.selectedUserErrorMsg;
    const selectedItemErrorMsg = items.selectedItemErrorMsg;
    let navigate = useNavigate();
    const refInitial = useRef(true);

    const [itemName, setItemName] = useState("");
    const [userName, setUserName] = useState("");
    const [serialNo, setSerialNo] = useState("");
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [isStopKeyUp, setIsStopKeyUp] = useState(true);
    const [isSagyouSaikai, setSagyouSaikai] = useState(false);
    const [inputSerialNumberErrorMsg, setInputSerialNumberErrorMsg] = useState("");

    useEffect(() => {
        const currUserId = sessionStorage.getItem('userId') ? sessionStorage.getItem('userId') : "";
        if (currUserId.length > 0 && userList.length > 0 && userName.length === 0) {
            const currUser = userList.find((user) => user.userId === Number(currUserId));
            if (currUser) {
                setUserName(currUser.userName);
                setSelectedUser(currUser);
            }
            else {
                // ユーザーマスタに登録されていないユーザーです。
                dispatch(setSelectedUserErrorMsg(errorCode[errorCode.E1003]));
                setUserName(currUserId);
            }
        }
        const currItemCode = sessionStorage.getItem('itemCode') ? sessionStorage.getItem('itemCode') : "";
        if (currItemCode.length > 0 && itemList.length > 0 && itemName.length === 0) {
            const currItem = itemList.find((item) => item.itemCode === Number(currItemCode));
            if (currItem) {
                if (currItem.isEnd === true) {
                    // 製品マスタで生産終了設定をしている製品です。
                    dispatch(setSelectedItemErrorMsg(errorCode[errorCode.E1004]));
                    setItemName(currItem.displayItemCode);
                }
                else {
                    setItemName(currItem.itemName);
                    if (currItem.processCnt === 0) {
                        // 工程情報が登録されていない製品です。
                        dispatch(setSelectedItemErrorMsg(errorCode[errorCode.E1005]));
                    }
                    else {
                        setSelectedItem(currItem);
                    }
                }
            }
            else {
                setItemName(currItemCode);
                // 製品マスタに登録されていない製品です。
                dispatch(setSelectedItemErrorMsg(errorCode[errorCode.E1006]));
            }
        }
        if (currUserId.length === 0 && userName.length === 0) {
            userComboRefs.focus();
        }
        else if (currItemCode.length === 0 && itemName.length === 0) {
            itemComboRefs.focus();
        }
        else {
            serialInputRefs.focus();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items]);

    useEffect(() => {
        if (refInitial.current === true) {
            refInitial.current = false;
            dispatch(resetInitState());
            dispatch(setIsWorkImageExpandMode(false));
            const fetchData = async () => {
                try {
                    await dispatch(fetchUserData()).unwrap();
                    await dispatch(fetchItemData()).unwrap();
                } catch (error) {
                    let errResponseStatus = error.message;
                    // eslint-disable-next-line eqeqeq
                    if (errResponseStatus == 401) {
                        NavigateToOperationLogin(navigate);
                    }
                    else {
                        appInsights.trackException({ ...error, errorFunction: "SerialNumberStarkWork.useEffect()" });
                        console.error(error);
                    }
                }
            }
            fetchData();
        }
    }, [dispatch, navigate])

    /**
     * ユーザーコンボボックスの選択イベント
     * @param {*} e 
     * @returns 
     */
    function handleUserSelectedChange(e) {
        isChooseOption = true;
        if (e.targetValue.length === 0) {
            dispatch(setSelectedUserErrorMsg(""));
            return;
        }
        const selUserObj = userList.find((user) => user.displayUserCode.toLowerCase() === e.targetValue.toLowerCase());
        if (selUserObj) {
            dispatch(setSelectedUserErrorMsg(""));
            handleUserInputChange(e.targetValue);
            itemComboRefs.focus();
        }
    }

    /**
     * ユーザーコンボボックスのキーダウンイベント
     * @param {*} e 
     */
    function handleUserEnterKeyDown(e) {
        if (e.key === 'Enter') {
            let value = e.target.value;
            if (value.length > 0) {
                let isMatchCode = userList.findIndex((user) => user.displayUserCode === value);
                let isMatchName = userList.findIndex((user) => user.userName === value);
                if (isMatchCode === -1 && isMatchName === -1) {
                    handleUserInputChange(value);
                    itemComboRefs.focus();
                }
            }
            else if (userName.length > 0) {
                itemComboRefs.focus();
            }
        }
        else if (excludeKey.some((k) => k.toLowerCase() === e.key.toLowerCase()) === false && userName.length > 0) {
            setUserName("");
            setSelectedUser(null);
            sessionStorage.removeItem('userId');
        }
    }

    /**
     * ユーザーコンボボックスのフォーカスアウトイベント
     * @param {*} e 
     * @returns 
     */
    function handleUserFocusOut(e) {
        if (isChooseOption) {
            isChooseOption = false;
            return;
        }
        if (e.target.value.length > 0) {
            handleUserInputChange(e.target.value);
        }
        else if (userName.length === 0 && selectedUserErrorMsg.length > 0) {
            dispatch(setSelectedUserErrorMsg(""));
        }
    }

    /**
     * ユーザーを変更する処理
     * @param {*} value 
     */
    function handleUserInputChange(value) {
        const iscorrectUserCode = userList.some((user) => user.displayUserCode.toLowerCase() === value.toLowerCase());
        const iscorrecUserName = userList.some((user) => user.userName.toLowerCase() === value.toLowerCase());
        if (iscorrectUserCode === false && iscorrecUserName === false) {
            // ユーザーマスタに登録されていないユーザーです。
            dispatch(setSelectedUserErrorMsg(errorCode[errorCode.E1003]));
            setUserName(value);
            setSelectedUser(null);
            sessionStorage.setItem('userId', value);
        }
        else {
            handleUserInputChangeNoError(value);
        }
    }

    /**
     * エラー無しユーザーを記入する場合、データ変更処理
     * @param {*} input 
     */
    function handleUserInputChangeNoError(input) {
        const iscorrectUserCode = userList.some((user) => user.displayUserCode.toLowerCase() === input.toLowerCase());
        const selectUserObj = iscorrectUserCode ? userList.find((user) => user.displayUserCode.toLowerCase() === input.toLowerCase()) :
            userList.find((user) => user.userName.toLowerCase() === input.toLowerCase());
        if (selectUserObj) {
            setSelectedUser(selectUserObj);
            setUserName(selectUserObj.userName);
            dispatch(setSelectedUserErrorMsg(""));
            sessionStorage.setItem('userId', selectUserObj.userId);
        }
    }

    /**
     * 製品コンボボックスの選択イベント
     * @param {*} e 
     * @returns 
     */
    function handleItemSelectedChange(e) {
        isChooseOption = true;
        if (e.targetValue.length === 0) {
            dispatch(setSelectedItemErrorMsg(""));
            return;
        }
        const selItemObj = itemList.find((item) => item.displayItemCode.toLowerCase() === e.targetValue.toLowerCase())
        if (selItemObj) {
            handleItemInputChange(e.targetValue);
            serialInputRefs.focus();
        }
    }

    /**
     * 製品コンボボックスのキーダウンイベント
     * @param {*} e 
     */
    function handleItemEnterKeyDown(e) {
        if (e.key === 'Enter') {
            setIsStopKeyUp(true);
            let value = e.target.value;
            if (value.length > 0) {
                let isMatchCode = itemListNoEnd.findIndex((user) => user.displayItemCode === value);
                let isMatchName = itemListNoEnd.findIndex((user) => user.itemName === value);
                if (isMatchCode === -1 && isMatchName === -1) {
                    handleItemInputChange(value);
                    serialInputRefs.focus();
                }
            }
            else if (itemName.length > 0) {
                serialInputRefs.focus();
            }
        }
        else if (excludeKey.some((k) => k.toLowerCase() === e.key.toLowerCase()) === false && itemName.length > 0) {
            setItemName("");
            setSelectedItem(null);
            sessionStorage.removeItem('itemCode');
        }
    }

    /**
     * 製品コンボボックスのフォーカスアウトイベント
     * @param {*} e 
     * @returns 
     */
    function handleItemFocusOut(e) {
        if (isChooseOption) {
            isChooseOption = false;
            return;
        }
        if (e.target.value.length > 0) {
            handleItemInputChange(e.target.value);
        }
        else if (itemName.length === 0 && selectedItemErrorMsg.length > 0) {
            dispatch(setSelectedItemErrorMsg(""));
        }
    }

    /**
     * 製品変更する処理
     * @param {*} value 
     * @returns 
     */
    function handleItemInputChange(value) {
        if (itemName.length > 0 && selectedItem && (selectedItem.displayItemCode === value || selectedItem.itemName === value)) {
            return;
        }
        setSerialNo("");
        setInputSerialNumberErrorMsg("");
        setSelectedItem(null);
        const iscorrectItemName = itemList.some((item) => item.itemName === value);
        const iscorrectItemCode = itemList.some((item) => item.displayItemCode === value);
        if (iscorrectItemName === false && iscorrectItemCode === false) {
            // 製品マスタに登録されていない製品です。
            dispatch(setSelectedItemErrorMsg(errorCode[errorCode.E1006]));
            setItemName(value);
            sessionStorage.setItem('itemCode', value);
        }
        else {
            const selectItemObj = itemList.find((item) => item.displayItemCode.toLowerCase() === value.toLowerCase()
                || item.itemName.toLowerCase() === value.toLowerCase());
            if (selectItemObj) {
                sessionStorage.setItem('itemCode', selectItemObj.itemCode);
                if (selectItemObj.isEnd === true) {
                    // 製品マスタで生産終了設定をしている製品です。
                    dispatch(setSelectedItemErrorMsg(errorCode[errorCode.E1004]));
                    setItemName(value);
                }
                else {
                    setItemName(selectItemObj.itemName);
                    if (selectItemObj.processCnt === 0) {
                        // 工程情報が登録されていない製品です。
                        dispatch(setSelectedItemErrorMsg(errorCode[errorCode.E1005]));
                    }
                    else {
                        dispatch(setSelectedItemErrorMsg(""));
                        setSelectedItem(selectItemObj);
                    }
                }
            }
        }
    }

    /**
     * 製造番号 / シリアル番号を変更する処理
     * @param {*} e 
     */
    function handleSerialNumberChange(e) {
        const str = e.target.value;
        setSerialNo(str);
        setIsStopKeyUp(false);
        setSagyouSaikai(false);
        setInputSerialNumberErrorMsg("");
    }

    /**
     * 製造番号 / シリアル番号変更のキーアップイベント
     * @param {*} e 
     */
    async function handleSerialNumberKeyUp(e, str) {
        if (e.key === 'Enter' && isStopKeyUp === false) {
            let inputTxt = str;
            setSerialNo(inputTxt);
            if (inputTxt.length > 0) {
                let isFailed = await checkIsWorkCompleteOrLocked(inputTxt);
                if (isFailed === false && selectedUserErrorMsg.length === 0 && userName.length > 0) {
                    startWorkBtnRefs.focus();
                }
            }
        }
        setIsStopKeyUp(false);
    }

    /**
     * 製造番号 / シリアル番号変更のフォーカスアウトイベント
     * @param {*} str 
     */
    async function handleSerialNumberFocusOut(str) {
        setSerialNo(str);
        await checkIsWorkCompleteOrLocked(str);
    }

    /**
     * 製造番号 / シリアル番号で全作業が完了ですか確認する処理
     * @returns 
     */
    async function checkIsWorkCompleteOrLocked(inputSerialNo) {
        let isFailed = true;
        if (selectedItemErrorMsg.length > 0 || inputSerialNo.length === 0 || selectedItem === null) {
            return isFailed;
        }
        let paramobj = { serialNo: inputSerialNo, itemCode: selectedItem.itemCode }
        await axios({
            method: 'GET',
            url: variables.OPERATION_URL + "/check-workcomplete-serialno",
            params: paramobj,
            headers: { 'company': urlCompanyName },
            withCredentials: true
        }).then(function (response) {
            let responseData = response.data;
            let errorMsg = errorCode[responseData.errorCodeList[0]];
            if (errorMsg) {
                let msg = errorMsg.replace(/\{(\d+)\}/g, responseData.userName);
                setInputSerialNumberErrorMsg(msg);
            }
            else {
                isFailed = false;
                setInputSerialNumberErrorMsg("");
            }
            setSagyouSaikai(responseData.saiKaiFlg);
        }).catch(function (error) {
            let errResponseStatus = error.response.status;
            if (errResponseStatus === 401) {
                NavigateToOperationLogin(navigate);
            }
            else {
                appInsights.trackTrace({ params: JSON.stringify(paramobj) });
                appInsights.trackException({ ...error, errorFunction: "SerialNumberStarkWork.checkIsWorkCompleteOrLocked()" });
                console.error(error);
            }
        });
        return isFailed;
    }

    /**
     * 作業開始/作業再開ボタンのクリックイベント
     * @param {*} e 
     */
    async function handleStartBtnClick(e) {
        await operationStartWorkOrResumeWork();
    }

    /**
     * 作業開始/作業再開ボタンのキーアップイベント
     * @param {*} e 
     */
    async function handleStartBtnKeyUp(e) {
        if (e.key === 'Enter') {
            e.stopPropagation();
            e.preventDefault();
            await operationStartWorkOrResumeWork();
        }
    }

    /**
     * 作業開始/作業再開する処理
     */
    async function operationStartWorkOrResumeWork() {
        dispatch(setPageLoading(true));
        let paramobj = { serialNo: serialNo, itemCode: selectedItem.itemCode, userId: selectedUser.userId };
        await axios({
            method: 'GET',
            url: variables.OPERATION_URL + "/work-sagyoukaishi-or-saikai-serialno",
            params: paramobj,
            headers: { 'company': urlCompanyName },
            withCredentials: true
        }).then(function (response) {
            let responseData = response.data;
            let errLst = responseData.errorCodeList;
            errLst.forEach(err => {
                if (err === errorCode.E1003) {
                    dispatch(setSelectedUserErrorMsg(errorCode[err]));
                }
                if (err === errorCode.E1004 || err === errorCode.E1005) {
                    dispatch(setSelectedItemErrorMsg(errorCode[err]));
                }
                if (err === errorCode.E1001 || err === errorCode.E1002 || err === errorCode.E1014) {
                    let msg = errorCode[err].replace(/\{(\d+)\}/g, responseData.userName);
                    setInputSerialNumberErrorMsg(msg);
                    setSagyouSaikai(responseData.saiKaiFlg);
                    serialInputRefs.focus();
                }
            });
            if (errLst.length === 0) {
                // オペレーション画面へ移動する。
                navigateToOperation(responseData.serialOperationLogLockId, responseData.newLockKey, responseData.version);
            }
        }).catch(function (error) {
            let errResponseStatus = error.response.status;
            if (errResponseStatus === 401) {
                NavigateToOperationLogin(navigate);
            }
            else {
                appInsights.trackTrace({ params: JSON.stringify(paramobj) });
                appInsights.trackException({ ...error, errorFunction: "SerialNumberStarkWork.operationStartWorkOrResumeWork()" });
                console.error(error);
            }
        });
        dispatch(setPageLoading(false));
    }

    /**
     * オペレーション画面へ移動する処理
     * @param {*} serialOperationLogLockId 
     * @param {*} lockKey 
     * @param {*} version 
     */
    function navigateToOperation(serialOperationLogLockId, lockKey, version) {
        let dataObj = {
            selectedItem: selectedItem,
            selectedUser: selectedUser,
            serialNumber: serialNo,
            managementType: OperationManagementType.SERIALNUMBER,
            lockKey: lockKey,
            serialOperationLogLockId: serialOperationLogLockId,
            version: version
        };
        NavigateToOperation(navigate, dataObj);
    }

    /**
     * 各項目がエラー無しか確認する処理
     * @returns 
     */
    const isActiveWork = () => {
        if (itemName.length > 0 && userName.length > 0 && serialNo.length > 0
            && selectedUserErrorMsg.length === 0
            && selectedItemErrorMsg.length === 0
            && inputSerialNumberErrorMsg.length === 0) {
            return true;
        }
        return false;
    }

    return (
        <div className="w-[755px] h-[880px] bg-white">
            <div className="absolute top-[5px] left-[164px]">
                <span className="font-bold text-[26px]">ユーザー</span>
                <div className="w-[600px] h-[65px] mt-1">
                    <ComboBox
                        height={65}
                        refComboBox={(e) => { userComboRefs = e; }}
                        isSearchable={true}
                        selectedOption={userName}
                        isError={(selectedUserErrorMsg.length > 0)}
                        itemsSource={userList}
                        displayMemberPath={"userName"}
                        selectedValuePath={"displayUserCode"}
                        onSelectedChange={handleUserSelectedChange}
                        onKeyDown={handleUserEnterKeyDown}
                        onFocusOut={handleUserFocusOut}
                        placeholder={"ユーザーIDを入力、またはリストからユーザー名を選択"}>
                    </ComboBox>
                </div>
                {(selectedUserErrorMsg.length > 0) && <ErrorText errMsg={selectedUserErrorMsg} />}
            </div>
            <div className="absolute top-[170px] left-[164px]">
                <span className="font-bold text-[26px]">製品</span>
                <div className="w-[600px] h-[65px] mt-1 focus:outline-none focus:border-[#0073CD]">
                    <ComboBox
                        height={65}
                        refComboBox={(e) => { itemComboRefs = e; }}
                        isSearchable={true}
                        selectedOption={itemName}
                        isError={(selectedItemErrorMsg.length > 0)}
                        itemsSource={itemListNoEnd}
                        displayMemberPath={"itemName"}
                        selectedValuePath={"displayItemCode"}
                        onSelectedChange={handleItemSelectedChange}
                        onKeyDown={handleItemEnterKeyDown}
                        onFocusOut={handleItemFocusOut}
                        placeholder={"製品コードを入力、またはリストから製品名を選択"}>
                    </ComboBox>
                </div>
                {(selectedItemErrorMsg.length > 0) && <ErrorText errMsg={selectedItemErrorMsg} />}
            </div>
            <div className="absolute top-[310px] left-[164px]">
                <span className="font-bold text-[26px]">製造番号 / シリアル番号</span>
                <div className="w-[600px] h-[65px] mt-1 focus:outline-none focus:border-[#0073CD]">
                    <InputBox
                        refInputBox={(e) => { serialInputRefs = e; }}
                        maxLength={50}
                        value={serialNo}
                        textType={TextType.HALFWIDTH_SYMBOL_AUTOCHANGE}
                        isError={(inputSerialNumberErrorMsg.length > 0)}
                        onChange={(e) => handleSerialNumberChange(e)}
                        onKeyUp={(e, str) => handleSerialNumberKeyUp(e, str)}
                        onFocusOut={(txt) => handleSerialNumberFocusOut(txt)}
                        placeholder="製造番号 / シリアル番号を入力">
                    </InputBox>
                </div>
                {(inputSerialNumberErrorMsg.length > 0) && <ErrorText errMsg={inputSerialNumberErrorMsg} />}
            </div>
            <div className="absolute top-[500px] left-[350px]">
                {isSagyouSaikai ?
                    <div ref={(e) => { startWorkBtnRefs = e; }} tabIndex={0} className={isActiveWork() === true ? saikaiBtnEnable : buttonDisable}
                        onClick={isActiveWork() === false || isPageLoading ? void (0) : (e) => handleStartBtnClick(e)}
                        onKeyUp={isActiveWork() === false || isPageLoading ? void (0) : (e) => handleStartBtnKeyUp(e)}
                    >作業再開</div>
                    :
                    <div ref={(e) => { startWorkBtnRefs = e; }} tabIndex={0} className={isActiveWork() === true ? kaishiBtnEnable : buttonDisable}
                        onClick={isActiveWork() === false || isPageLoading ? void (0) : (e) => handleStartBtnClick(e)}
                        onKeyUp={isActiveWork() === false || isPageLoading ? void (0) : (e) => handleStartBtnKeyUp(e)}
                    >作業開始</div>
                }
            </div>
        </div>
    );
}

export default SerialNumberStartWork;