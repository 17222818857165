import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { variables } from "../../../Variables";
import { GetUrlCompany } from "../../util/commonFun";
import { appInsights } from "../../util/ApplicationInsight";

const axios = require('axios');
const urlCompanyName = GetUrlCompany();
/**
 * 作業IDで工具一覧をサーバーから取得する。
 */
export const fetchToolByWorkID = createAsyncThunk("toolList/fetchToolByWorkID",
  async workIDs => {
    const response = await axios({
      method: 'POST',
      url: variables.TOOL_URL + "/getbyworkids",
      data: workIDs,
      headers: { 'company': urlCompanyName },
      withCredentials: true
    });

    return response.data;
  });

const initState = {
  selectedToolId: -1,
  dataList: [],
  backupDataList: []
};

const toolListSlice = createSlice({
  name: "toolList",
  initialState: initState,
  reducers: {
    addTool: (state, action) => {
      const toolObj = action.payload.toolObj;
      const list = state.dataList.filter((tool) => tool.workId === toolObj.workId);
      list.sort((a, b) => a.dispOrder - b.dispOrder);
      if (list.length > 0) {
        const curIdx = list.findIndex((item) => item.toolId === action.payload.currToolId);
        toolObj.dispOrder = list[curIdx].dispOrder + 1;

        for (var i = (curIdx + 1); i < list.length; i++) {
          const temp = list[i].dispOrder;
          list[i].dispOrder = temp + 1;
        }
      }
      state.dataList.push(toolObj);
      state.selectedToolId = toolObj.toolId;
    },

    deleteTool: (state, action) => {
      // 削除する前に、データリストを取得する
      const templistBeforeDelete = [...state.dataList.filter((tool) => tool.workId === action.payload.workId)];
      templistBeforeDelete.sort((a, b) => a.dispOrder - b.dispOrder);
      const currIndex = templistBeforeDelete.findIndex((item) => item.toolId === action.payload.toolId);
      // 元リストから削除する
      const delIndex = state.dataList.findIndex((item) => item.toolId === action.payload.toolId);
      state.dataList.splice(delIndex, 1);
      const list = state.dataList.filter((tool) => tool.workId === action.payload.workId);
      list.sort((a, b) => a.dispOrder - b.dispOrder);
      for (var i = 0; i < list.length; i++) {
        list[i].dispOrder = i + 1;
      }
      if (list.length === 0) {
        state.selectedToolId = -1;
      }
      else {
        let chgSelectToolId =
          currIndex === list.length ? list[currIndex - 1].toolId : list[currIndex].toolId;
        state.selectedToolId = chgSelectToolId;
      }
    },

    setSelectedToolId: (state, action) => {
      state.selectedToolId = action.payload;
    },

    codeTextChange: (state, action) => {
      let list = state.dataList.filter(
        (tool) => tool.workId === action.payload.workId
      );
      const index = list.findIndex(
        (item) => item.toolId === action.payload.toolId
      );
      const newValue = action.payload.newValue;
      const oldValue = list[index].identifyCode;
      if (newValue !== oldValue) {
        list[index].identifyCode = newValue;
      }
    },

    nameTextChange: (state, action) => {
      let list = state.dataList.filter(
        (tool) => tool.workId === action.payload.workId
      );
      const index = list.findIndex(
        (item) => item.toolId === action.payload.toolId
      );
      const newValue = action.payload.newValue;
      const oldValue = list[index].toolName;
      if (newValue !== oldValue) {
        list[index].toolName = newValue;
      }
    },

    countTextChange: (state, action) => {
      let list = state.dataList.filter(
        (tool) => tool.workId === action.payload.workId
      );
      const index = list.findIndex(
        (item) => item.toolId === action.payload.toolId
      );
      const newValue = action.payload.newValue;
      const oldValue = list[index].memberCnt;
      if (newValue !== oldValue) {
        list[index].memberCnt = newValue === "" ? -1 : newValue;
      }
    },

    toolTypeChange: (state, action) => {
      let list = state.dataList.filter(
        (tool) => tool.workId === action.payload.workId
      );
      const index = list.findIndex(
        (item) => item.toolId === action.payload.toolId
      );
      const newValue = action.payload.newValue;
      const oldValue = list[index].toolType;
      if (newValue !== oldValue) {
        list[index].toolType = newValue;
      }
    },

    toolUpDownChange: (state, action) => {
      const newList = state.dataList.filter((tool) => tool.workId === action.payload.workId);
      newList.sort((a, b) => a.dispOrder - b.dispOrder);
      const delta = action.payload.upDownType;
      const currentIndex = newList.findIndex((item) => item.toolId === state.selectedToolId);
      const currTool = newList[currentIndex];
      const swapIndex = currentIndex + delta;
      const swapTool = newList[swapIndex];
      // 表示順を切り替えする
      const temp = currTool.dispOrder;
      newList[currentIndex].dispOrder = swapTool.dispOrder;
      newList[swapIndex].dispOrder = temp;
      state.selectedToolId = currTool.toolId;
    },

    setToolImgInfo: (state, action) => {
      const index = state.dataList.findIndex(
        (item) => item.toolId === state.selectedToolId
      );
      state.dataList[index].fileName = action.payload.fileName;
      state.dataList[index].objUrl = action.payload.objUrl;
    },
    /**
     * 作業IDリストで工具を削除する。
     * param: array of workID
     * @param {*} detailDoc 
     * @param {*} action 
     */
    deleteToolByWorkIDList: (state, action) => {
      const lstWorkID = action.payload;
      if (lstWorkID && lstWorkID.length > 0) {
        const filteredList = state.dataList.filter(d => lstWorkID.includes(d.workId) === false);
        if (filteredList) {
          state.dataList = filteredList;
        } else {
          state.dataList = [];
        }
      }
    },
    resetTool: (state, action) => {
      return { ...initState };
    }
  },
  extraReducers: (builder) => {
    // fulfilled(完了)/pending(処理中)/rejected(エラー)時の処理を書く
    // 完了
    builder.addCase(fetchToolByWorkID.fulfilled, (toolList, action) => {
      toolList.dataList = action.payload;
      toolList.backupDataList = toolList.dataList.slice();
    });
    // エラー
    // エラー時はエラー内容が返されることを期待している
    builder.addCase(fetchToolByWorkID.rejected, (state, action) => {
      appInsights.trackException({ ...action.error, errorFunction: "toolListSlice.builder.addCase(fetchToolByWorkID.rejected)" });
    });
  },
});

export const selectAllTools = (state) => {
  return state.toolList.dataList;
}

export const selectBackupTools = (state) => {
  return state.toolList.backupDataList;
}

export const selectToolListByWorkId = (state, workId) => {
  return state.toolList.dataList.filter((w) => w.workId === workId);
};

export const {
  addTool,
  deleteTool,
  setSelectedToolId,
  codeTextChange,
  nameTextChange,
  countTextChange,
  toolTypeChange,
  toolUpDownChange,
  setToolImgInfo,
  deleteToolByWorkIDList,
  resetTool,
} = toolListSlice.actions;
export default toolListSlice.reducer;
