import { React, useState, useRef, useEffect } from "react";
import { FaUser } from "@react-icons/all-files/fa/FaUser";
import { MdLock } from "@react-icons/all-files/md/MdLock";
import bcrypt from "bcryptjs";
import { variables, StatusCode, Salt, CompanyLoginPath } from "../../../Variables";
import { VscError } from "react-icons/vsc";
import { IconContext } from "react-icons";
import { Navigate } from 'react-router-dom';
import { generatePath } from 'react-router';
import { GetUrlCompany } from "../../util/commonFun";
import { appInsights } from "../../util/ApplicationInsight";

const axios = require('axios');
const urlCompanyName = GetUrlCompany();

function EditorUserLogin(props) {
  const userID = useRef();
  const password = useRef();
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [isRedirectToMasterLayout, setIsRedirectToMasterLayout] = useState(false);
  const [isRedirectToCompanyLogin, setIsRedirectToCompanyLogin] = useState(false);

  useEffect(() => {
    let statusCd;
    axios({
      method: 'GET',
      url: variables.LOGIN_URL + "/validate",
      headers: { 'company': urlCompanyName },
      withCredentials: true
    }).then(function (response) {
      statusCd = response.data.statusCode;
      if (statusCd === StatusCode.AuthorizedWithAdministrator) {
        setIsRedirectToMasterLayout(true);
      }
      if (statusCd === StatusCode.UnAuthenticated || statusCd === StatusCode.ValidationFailed) {
        setIsRedirectToCompanyLogin(true);
      }
    }).catch(function (error) {
      appInsights.trackException({ ...error, errorFunction: "EditorUserLogin.useEffect()" });
    });
  }, [])

  function handleSigninClick() {
    let errResponseStatus;
    let inputteduserID = userID.current.value;
    let hashPassword = bcrypt.hashSync(password.current.value, Salt);
    let body = { companyName: urlCompanyName, companyId: "", dispUserId: inputteduserID, password: hashPassword }
    axios({
      method: 'POST',
      url: variables.LOGIN_URL,
      headers: { 'company': urlCompanyName },
      data: body,
      withCredentials: true
    }).then(function (response) {
      setIsRedirectToMasterLayout(true);
    }).catch(function (error) {
      errResponseStatus = error.response.status;
      if (errResponseStatus === 401) {
        setShowErrorMessage(true);
      }
      else {
        appInsights.trackTrace({ data: JSON.stringify(body) });
        appInsights.trackException({ ...error, errorFunction: "EditorUserLogin.handleSigninClick()" });
      }
    });
  };

  return (
    <div className=" flex flex-col w-[1920px] h-[1080px] items-center justify-center bg-[#0073CD]">
      <span className="absolute top-[112px] text-[#FFFFFF] text-[85px] font-black">Roland DG Assemble</span>
      <span className="absolute top-[220px] text-[#FFFFFF] text-[68px] font-black">for Editor</span>
      <div className="flex flex-col w-[774px] h-[477px] bg-white shadow-md px-4 sm:px-6 md:px-8 lg:px-10 py-8">
        <span className="self-center text-black text-[26px] text-left font-bold">サインイン</span>
        <span className="self-center mt-[37px] text-black text-base text-left">ユーザーID、パスワードを入力してください。</span>
        <div className="relative">
          <div className="inline-flex items-center justify-center absolute w-[23px] h-[23px] ml-[125px] mt-[68px]">
            <FaUser />
          </div>
          <input id="userid" type="text" name="userid" ref={userID} placeholder="ユーザーID" className={"ml-[108px] mt-[51px] text-sm sm:text-base placeholder-[#D8D7D5] pl-10 pr-4  border-2 border-[#00000099] w-[479px] h-[51px] py-2 focus:outline-none focus:border-[#0073CD] " + (showErrorMessage ? " border-red-600" : "")} />
        </div>
        <div className="relative">
          <div className="inline-flex items-center justify-center absolute w-[23px] h-[25px] ml-[125px] mt-[43px] ">
            <MdLock />
          </div>
          <input id="password" type="password" ref={password} name="password" placeholder="パスワード" className={"ml-[108px] mt-[30px] text-sm sm:text-base placeholder-[#D8D7D5] pl-10 pr-4 border-2 border-[#00000099] w-[479px] h-[51px] py-2 focus:outline-none focus:border-[#0073CD] " + (showErrorMessage ? " border-red-600" : "")} />
        </div>

        <div className="flex ml-[249px]">
          <button type="button" onClick={handleSigninClick} className="flex items-center justify-center focus:outline-none text-white text-sm sm:text-base bg-blue-600 hover:bg-blue-700 mt-[76px] py-2 w-[196px] h-[50px] transition duration-150 ease-in">
            サインイン
          </button>
        </div>
      </div>
      <div>
        {isRedirectToMasterLayout && <><Navigate to={generatePath("/:company", {
          company: urlCompanyName
        })} /></>}
        {isRedirectToCompanyLogin && <><Navigate to={generatePath(CompanyLoginPath, {
          company: urlCompanyName
        })} /></>}
        {showErrorMessage &&
          <div className="mt-[-156px]">
            <label className="text-red-600">
              <IconContext.Provider value={{ color: "red" }}>
                <VscError className="w-[18px] h-[21px] inline-block pb-1" />
                ユーザーIDまたはパスワードが正しくありません。
              </IconContext.Provider>
            </label>
          </div>
        }
      </div>
    </div>
  );
}
export default EditorUserLogin;
