import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { variables } from "../../Variables";
import { GetUrlCompany, ToLocalDateTime } from "../util/commonFun";
import { appInsights } from "../util/ApplicationInsight";

const axios = require('axios');
const urlCompanyName = GetUrlCompany();

const emptycompletedRecords = {
    categoryCode: -1,
    categoryName: "",
    dispItemCode: "",
    itemName: "",
    processName: "",
    lotSerialNo: "",
    completedCount: -1,
    dispOrder: -1,
    dispProcessID: "",
}

export const fetchCategoryList = createAsyncThunk("completedRecord/fetchCategoryList",
    async () => {
        const response = await axios({
            method: 'GET',
            url: variables.CATEGORY_URL,
            headers: { 'company': urlCompanyName },
            withCredentials: true,
        }).catch((error) => {
            appInsights.trackException({ ...error, errorFunction: "completedRecordSlice.fetchCategoryList()" });
            throw (error.response.status);
        });
        return response.data;
    }
);

export const fetchCompletedRecords = createAsyncThunk("completedRecord/fetchCompletedRecords",
    async param => {
        const response = await axios({
            method: 'GET',
            url: variables.COMPLETEDRECORD_URL + "/get-completed-records",
            params: { startDate: param.startDate, endDate: param.endDate },
            headers: { 'company': urlCompanyName },
            withCredentials: true,
        }).catch((error) => {
            appInsights.trackTrace({ params: JSON.stringify({ startDate: param.startDate, endDate: param.endDate }) });
            appInsights.trackException({ ...error, errorFunction: "completedRecordSlice.fetchCompletedRecords()" });
            throw (error.response.status);
        });
        return response.data;
    }
);

const initState = {
    categoryList: [],
    completedRecordsList: [],
}

export const completedRecordSlice = createSlice({
    name: "completedRecord",
    initialState: initState,
    extraReducers: (builder) => {
        builder.addCase(fetchCategoryList.fulfilled, (completedRecord, action) => {
            completedRecord.categoryList = action.payload;
        });
        builder.addCase(fetchCategoryList.rejected, (completedRecord, action) => {
            appInsights.trackException({ ...action.error, errorFunction: "completedRecordSlice.fetchCategoryList.rejected()" });
        });
        builder.addCase(fetchCompletedRecords.fulfilled, (completedRecord, action) => {
            const records = action.payload;
            completedRecord.completedRecordsList = [];
            if (records && records.length > 0) {
                records.forEach(record => {
                    completedRecord.completedRecordsList.push({
                        ...emptycompletedRecords,
                        categoryCode: record.categoryCode,
                        categoryName: record.categoryName,
                        dispItemCode: record.dispItemCode,
                        itemName: record.itemName,
                        processName: record.processName,
                        lotSerialNo: record.number,
                        completedCount: record.completedCount,
                        dispOrder: record.dispOrder,
                        dispProcessID: record.dispProcessID,
                        completedDate: ToLocalDateTime(record.completedDate),
                        isLot: record.isLot,
                    })
                })
            }
        });
        builder.addCase(fetchCompletedRecords.rejected, (completedRecord, action) => {
            appInsights.trackException({ ...action.error, errorFunction: "completedRecordSlice.builder.addCase(fetchCompletedRecords.rejected)" });
        });
    }
});

export const getCategory = (state) => {
    return state.completedRecord.categoryList;
};

export const getCompletedRecords = (state) => {
    return state.completedRecord.completedRecordsList;
}

export default completedRecordSlice.reducer;
