import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { variables } from "../../Variables";
import { GetUrlCompany } from "../util/commonFun";
import { appInsights } from "../util/ApplicationInsight";

const axios = require('axios');
const urlCompanyName = GetUrlCompany();

const emptyUser = {
    userId: -1,
    userName: "",
    displayUserCode: "",
};

const emptyItem = {
    itemCode: -1,
    itemName: "",
    displayItemCode: "",
    processCnt: 0,
    isEnd: false,
}

const emptyProcess = {
    processId: "",
    displayText: "",
    dispProcessId: "",
    version: -1,
};

export const fetchUserData = createAsyncThunk("operation/fetchUserData",
    async () => {
        const response = await axios({
            method: 'GET',
            url: variables.OPERATION_URL + "/get-userData",
            headers: { 'company': urlCompanyName },
            withCredentials: true,
        }).catch((error) => {
            appInsights.trackException({ ...error, errorFunction: "operationStartWorkSlice.fetchUserData()" });
            throw (error.response.status);
        });
        return response.data;
    });

export const fetchItemData = createAsyncThunk("operation/fetchItemData",
    async () => {
        const response = await axios({
            method: 'GET',
            url: variables.OPERATION_URL + "/get-itemData",
            headers: { 'company': urlCompanyName },
            withCredentials: true,
        }).catch((error) => {
            appInsights.trackException({ ...error, errorFunction: "operationStartWorkSlice.fetchItemData()" });
            throw (error.response.status);
        });
        return response.data;
    });

export const fetchProcessList = createAsyncThunk("operation/fetchProcessList",
    async itemCode => {
        const response = await axios({
            method: 'GET',
            url: variables.OPERATION_URL + "/get-process-list-by-itemcode",
            params: { itemCode: itemCode },
            headers: { 'company': urlCompanyName },
            withCredentials: true,
        }).catch((error) => {
            appInsights.trackTrace({ params: JSON.stringify({ itemCode: itemCode }) });
            appInsights.trackException({ ...error, errorFunction: "operationStartWorkSlice.fetchProcessList()" });
            throw (error.response.status);
        });
        return response.data;
    });

const initState = {
    selectedUserErrorMsg: "",
    selectedItemErrorMsg: "",
    dataList: {
        userList: [],
        itemList: [],
        processList: []
    },
};

const operationStartWorkSlice = createSlice({
    name: "operationStartWork",
    initialState: initState,
    reducers: {
        setSelectedUserErrorMsg: (state, action) => {
            state.selectedUserErrorMsg = action.payload;
        },

        setSelectedItemErrorMsg: (state, action) => {
            state.selectedItemErrorMsg = action.payload;
        },

        setResetProcessList: (state, action) => {
            state.dataList.processList = [];
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchUserData.fulfilled, (state, action) => {
            const userList = action.payload;
            if (userList && userList.length > 0) {
                state.dataList.userList = userList.map(user => {
                    return {
                        ...emptyUser,
                        userId: user.id,
                        userName: user.userName,
                        displayUserCode: user.displayUserId,
                    };
                });
            }
        });
        builder.addCase(fetchUserData.rejected, (state, action) => {
            appInsights.trackException({ ...action.error, errorFunction: "operationStartWorkSlice.builder.addCase(fetchUserData.rejected)" });
        });
        builder.addCase(fetchItemData.fulfilled, (state, action) => {
            const itemList = action.payload;
            if (itemList && itemList.length > 0) {
                itemList.sort((a, b) => a.itemName.localeCompare(b.itemName))
                state.dataList.itemList = itemList.map(item => {
                    return {
                        ...emptyItem,
                        itemCode: item.itemCode,
                        itemName: item.itemName,
                        displayItemCode: item.displayItemCode,
                        processCnt: item.processCnt,
                        isEnd: item.isEnd,
                    };
                });
            }
        });
        builder.addCase(fetchItemData.rejected, (state, action) => {
            appInsights.trackException({ ...action.error, errorFunction: "operationStartWorkSlice.builder.addCase(fetchUserData.rejected)" });
        });
        builder.addCase(fetchProcessList.fulfilled, (state, action) => {
            const processList = action.payload;
            processList.sort((a, b) => a.dispOrder - b.dispOrder);
            if (processList && processList.length > 0) {
                state.dataList.processList = processList.map(item => {
                    return {
                        ...emptyProcess,
                        processId: item.processID,
                        displayText: item.dispOrder + " " + item.processName,
                        dispProcessId: item.dispProcessID,
                        version: item.version
                    };
                });
            }
        });
        builder.addCase(fetchProcessList.rejected, (state, action) => {
            appInsights.trackException({ ...action.error, errorFunction: "operationStartWorkSlice.builder.addCase(fetchProcessList.rejected)" });
        });
    },
});

export const {
    setSelectedUserErrorMsg,
    setSelectedItemErrorMsg,
    setResetProcessList,
} = operationStartWorkSlice.actions;
export default operationStartWorkSlice.reducer;
