import React from "react";
import SectionTitle from "../../components/SectionTitle";
import Add from "../../components/Add";
import UserInput from "../../components/UserInput";
import EditButton from "../../components/EditButton";
import { variables, Sort, userType, CompanyLoginPath } from "../../Variables";
import { BsThreeDots } from "react-icons/bs";
import SearchBox from "../../components/SearchBox";
import { IconContext } from "@react-icons/all-files";
import TableTitle from "../../components/TableTitle";
import { Navigate } from 'react-router-dom';
import { generatePath } from 'react-router';
import ConfirmBox from "../../components/ConfirmBox";
import { IsEditing, GetUrlCompany } from "../util/commonFun";
import { nanoid } from "nanoid";
import { appInsights } from "../util/ApplicationInsight";

const axios = require('axios');
const initialState = {
  users: [],
  editBtn: { id: -1, btn: null },
  userInput: null,
  userIdSortedOrder: Sort.NONE,
  userNameSortedOrder: Sort.NONE,
  userTypeSortedOrder: Sort.NONE,
  userWithoutFilter: [],
  userWithFilter: [],
  userFilterText: [],
  dialogBox: null,
  isRetiredDisplayChk: false,
};
const urlCompanyName = GetUrlCompany();
const cssNormal =
  "border-y-[1px] border-[#C8C6C4]-[.56] text-[#0068B8] " + variables.HOVER_CSS;
const cssEnd =
  "border-y-[1px] border-[#C8C6C4]-[.56] text-[#0068B8] bg-[#979797]";

class User extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;

    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleUserClick = this.handleUserClick.bind(this);
    this.refreshList = this.refreshList.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleThreeDotsClick = this.handleThreeDotsClick.bind(this);
    this.clearEditButton = this.clearEditButton.bind(this);
    this.sortByDisplayUserId = this.sortByDisplayUserId.bind(this);
    this.sortByUserName = this.sortByUserName.bind(this);
    this.sortByUserType = this.sortByUserType.bind(this);
    this.handleUserAddComplete = this.handleUserAddComplete.bind(this);
    this.handleSearchInputChange = this.handleSearchInputChange.bind(this);
    this.deleteDialog = this.deleteDialog.bind(this);
    this.redirect = this.redirect.bind(this);
    this.showUserInput = this.showUserInput.bind(this);
    this.handleRetiredUserDisplayChkChange = this.handleRetiredUserDisplayChkChange.bind(this);
  }

  componentDidMount() {
    this.refreshList();
  }

  // ユーザー追加ボタンのイベントハンドラ
  handleAddClick() {
    if (IsEditing() === true) {
      this.handleDiscardClick(-1);
    }
    else {
      this.setState({
        userInput: (
          <UserInput
            key={nanoid()}
            onComplete={this.handleUserAddComplete}
            User={{
              id: -1,
              displayUserId: "",
              userName: "",
              userTypeId: 1,
              password: "",
            }}
          />
        ),
      });
    }
  }

  handleUserAddComplete() {
    this.refreshList();
  }

  // 編集ボタンのイベントハンドラ
  handleEdit(targetId) {
    if (IsEditing() === true) {
      this.handleDiscardClick(targetId);
    }
    else {
      this.setState((state) => {
        return {
          userInput: (
            <UserInput
              onComplete={this.handleUserAddComplete}
              key={nanoid()}
              User={state.users.find((i) => i.id === targetId)}
            />
          ),
        };
      });
    }
  }

  handleDiscardClick(targetId) {
    const msg = <>作業が破棄されます。よろしいですか？</>
    this.setState({
      dialogBox: (
        <ConfirmBox
          className="absolute right-[162px] top-[265px] w-[471px]"
          title="変更の破棄" message={msg}
          onYesClick={() => this.showUserInput(targetId)} onNoClick={this.deleteDialog} />
      ),
    });
  }

  deleteDialog() {
    this.setState({
      dialogBox: null,
    });
  }

  showUserInput(targetId) {
    this.setState({
      dialogBox: null,
    });
    if (targetId === -1) {
      this.setState({
        userInput: (
          <UserInput
            key={nanoid()}
            onComplete={this.handleUserAddComplete}
            User={{
              id: -1,
              displayUserId: "",
              userName: "",
              userTypeId: 1,
              password: "",
            }}
          />
        ),
      });
    }
    else {
      this.setState((state) => {
        return {
          userInput: (
            <UserInput
              onComplete={this.handleUserAddComplete}
              key={nanoid()}
              User={state.users.find((i) => i.id === targetId)}
            />
          ),
        };
      });
    }
  }

  redirect() {
    this.setState({
      isRedirectToCompanyLogin: true
    });
  }

  // ユーザー一覧を更新する。
  refreshList() {
    axios({
      method: 'GET',
      url: variables.USER_URL,
      headers: { 'company': urlCompanyName },
      withCredentials: true
    }).then(
      response => {
        let data = response.data;
        data.sort((a, b) => a.displayUserId.localeCompare(b.displayUserId));
        this.setState({
          ...initialState,
          users: data.filter(user => user.isRetired === false),
          userWithFilter: data,
          userWithoutFilter: data,
        });
      }).catch((error) => {
        let errResponseStatus = error.response.status;
        if (errResponseStatus === 401) {
          this.redirect();
        }
        else {
          appInsights.trackException({ ...error, errorFunction: "User.refreshList()" });
        }
      });
  }

  // 「…」を押す時のイベントハンドラ
  // 「…」を押すと、編集ボタンを表示する。
  handleThreeDotsClick(userId, e) {
    const positionX = e.pageX + e.currentTarget.clientWidth + 10;
    const positionY = e.pageY - e.currentTarget.clientHeight / 2;
    this.setState({
      editBtn: {
        id: userId,
        btn: (
          <div
            style={{
              position: "absolute",
              left: positionX + "px",
              top: positionY + "px",
            }}
          >
            <EditButton onClick={() => this.handleEdit(userId)} />
          </div>
        ),
      },
    });
    e.stopPropagation();
  }

  // ユーザーを押すと、編集画面を表示する。
  handleUserClick(userId, e) {
    this.handleEdit(userId);
    e.stopPropagation();
  }

  // 編集ボタンをクリアする。
  clearEditButton() {
    this.setState({
      editBtn: { id: -1, btn: null },
    });
  }

  //ユーザーコードでソートする
  sortByDisplayUserId() {
    const userIdSortedOrder = this.state.userIdSortedOrder;
    const sortedusers = this.state.users.sort(function (firstItem, secondItem) {
      if (userIdSortedOrder === Sort.DESC || userIdSortedOrder === Sort.NONE) {
        return firstItem.displayUserId.localeCompare(secondItem.displayUserId)
      } else {
        return secondItem.displayUserId.localeCompare(firstItem.displayUserId)
      }
    });
    this.setState({
      users: sortedusers,
      userIdSortedOrder:
        userIdSortedOrder === Sort.DESC || userIdSortedOrder === Sort.NONE
          ? Sort.ASC
          : Sort.DESC,
      userNameSortedOrder: Sort.NONE,
      userTypeSortedOrder: Sort.NONE,
    });
  }

  //ユーザー名でソートする
  sortByUserName() {
    const userNameSortedOrder = this.state.userNameSortedOrder;
    const sorteditems = this.state.users.sort(function (firstItem, secondItem) {
      if (
        userNameSortedOrder === Sort.DESC ||
        userNameSortedOrder === Sort.NONE
      ) {
        return firstItem.userName.localeCompare(secondItem.userName)
      } else {
        return secondItem.userName.localeCompare(firstItem.userName)
      }
    });
    this.setState({
      users: sorteditems,
      userNameSortedOrder:
        userNameSortedOrder === Sort.DESC || userNameSortedOrder === Sort.NONE
          ? Sort.ASC
          : Sort.DESC,
      userIdSortedOrder: Sort.NONE,
      userTypeSortedOrder: Sort.NONE,
    });
  }

  //ユーザータイプでソートする
  sortByUserType() {
    const userTypeSortedOrder = this.state.userTypeSortedOrder;
    const sorteditems = this.state.users.sort(function (firstItem, secondItem) {
      if (
        userTypeSortedOrder === Sort.DESC ||
        userTypeSortedOrder === Sort.NONE
      ) {
        return firstItem.userTypeId > secondItem.userTypeId ? 1 : -1;
      } else {
        return firstItem.userTypeId < secondItem.userTypeId ? 1 : -1;
      }
    });
    this.setState({
      users: sorteditems,
      userTypeSortedOrder:
        userTypeSortedOrder === Sort.DESC || userTypeSortedOrder === Sort.NONE
          ? Sort.ASC
          : Sort.DESC,
      userIdSortedOrder: Sort.NONE,
      userNameSortedOrder: Sort.NONE,
    });
  }
  // 入力したユーザーIDとユーザー名でフィルターする。
  handleSearchInputChange(e) {
    let userList = [];
    const filteredUsers = this.state.userWithoutFilter.filter(function (user) {
      return (
        user.displayUserId
          .toString()
          .toLowerCase()
          .includes(e.target.value.toString().toLowerCase()) ||
        user.userName
          .toString()
          .toLowerCase()
          .includes(e.target.value.toString().toLowerCase())
      );
    });

    if (this.state.isRetiredDisplayChk) {
      userList = filteredUsers;
    }
    else {
      userList = filteredUsers.filter(user => user.isRetired === false);
    }

    this.setState({
      users: userList,
      userWithFilter: filteredUsers,
      userFilterText: e.target.value,
    });
  }


  //「退社ユーザーの表示」チェックボックスの処理
  handleRetiredUserDisplayChkChange(e) {
    let userList = [];
    if (e.target.checked) {
      userList = this.state.userWithFilter;
    }
    else {
      userList = this.state.userWithFilter.filter(data => data.isRetired === false);
    }
    // ユーザーIDでソートする
    if (this.state.userIdSortedOrder !== Sort.NONE) {
      if (this.state.userIdSortedOrder === Sort.DESC) {
        userList = userList.sort(function (firstItem, secondItem) {
          return secondItem.displayUserId.localeCompare(firstItem.displayUserId);
        });
      } else {
        userList = userList.sort(function (firstItem, secondItem) {
          return firstItem.displayUserId.localeCompare(secondItem.displayUserId);
        });
      }
    }
    // ユーザー名でソートする
    if (this.state.userNameSortedOrder !== Sort.NONE) {
      if (this.state.userNameSortedOrder === Sort.DESC) {
        userList = userList.sort(function (firstItem, secondItem) {
          return secondItem.userName.localeCompare(firstItem.userName);
        });
      } else {
        userList = userList.sort(function (firstItem, secondItem) {
          return firstItem.userName.localeCompare(secondItem.userName);
        });
      }
    }
    // ユーザータイプでソートする
    if (this.state.userTypeSortedOrder !== Sort.NONE) {
      if (this.state.userTypeSortedOrder === Sort.DESC) {
        userList = userList.sort(function (firstItem, secondItem) {
          return secondItem.displayUserId.localeCompare(firstItem.displayUserId);
        });
        userList = userList.sort(function (firstItem, secondItem) {
          return firstItem.userTypeId < secondItem.userTypeId ? 1 : -1;
        });
      } else {
        userList = userList.sort(function (firstItem, secondItem) {
          return firstItem.displayUserId.localeCompare(secondItem.displayUserId);
        });
        userList = userList.sort(function (firstItem, secondItem) {
          return firstItem.userTypeId > secondItem.userTypeId ? 1 : -1;
        });
      }
    }

    this.setState((prevState) => {
      return {
        users: userList,
        isRetiredDisplayChk: !prevState.isRetiredDisplayChk,
      }
    });
  }

  render() {
    return (
      <div onClick={this.clearEditButton}>
        {this.state.isRedirectToCompanyLogin && <><Navigate to={generatePath(CompanyLoginPath, {
          company: urlCompanyName
        })} /></>}
        <div className="flex flex-row">
          {/* 中央パネル */}
          <div>
            <div className="my-[11px] h-[21px]">マスタ設定</div>
            <SectionTitle Text="ユーザー" />
            <Add onClick={this.handleAddClick} Text="ユーザーの追加" />
            <div className="h-[2px] w-[1683px] bg-[#C8C6C4] opacity-[.56] absolute" />
            <div className="flex">
              <div className="h-[32px] px-[15px] my-[15px]">
                {/* 検索ボックス */}
                <SearchBox onChange={this.handleSearchInputChange} value={this.state.userFilterText} />
              </div>
              <div className="flex flex-row items-center">
                <div className="ml-[20px] mt-[5px]">
                  <input className="w-[21px] h-[21px]"
                    id="retiredDisplayChk"
                    checked={this.state.isRetiredDisplayChk}
                    type="checkbox"
                    onChange={this.handleRetiredUserDisplayChkChange} />
                </div>
                <label htmlFor="retiredDisplayChk">
                  <div className="ml-[8px]">
                    退社ユーザーの表示
                  </div>
                </label>

              </div>
            </div>
            <div className="overflow-x-scroll overflow-y-scroll h-[840px] w-[1030px] mt-[3px]">
              <table className="border-collapse">
                <thead>
                  <tr>
                    <th className="header">
                      <div className="w-[320px] border-r-2 h-[30px] min-w-[50px] max-w-[900px] resizable inner-resizer-disabled">
                        <TableTitle
                          onClick={this.sortByDisplayUserId}
                          Sort={this.state.userIdSortedOrder}
                          Text="ユーザーID" />
                      </div>
                    </th>
                    <th className="header">
                      <div className="w-[320px] border-r-2 h-[30px] min-w-[50px] max-w-[900px] resizable inner-resizer-disabled">
                        <TableTitle
                          onClick={this.sortByUserName}
                          Sort={this.state.userNameSortedOrder}
                          Text="ユーザー名"
                        />
                      </div>
                    </th>
                    <th className="header">
                      <div className="w-[320px] border-r-2 h-[30px] min-w-[50px] max-w-[900px] resizable inner-resizer-disabled">
                        <TableTitle
                          onClick={this.sortByUserType}
                          Sort={this.state.userTypeSortedOrder}
                          Text="ユーザータイプ" />
                      </div>
                    </th>
                    <th className="header">
                      <div className="h-[30px] w-[30px]"></div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.users.map((user) => (
                    <tr key={user.id} className={user.isRetired === true ? cssEnd : cssNormal}>
                      <td className="w-[50px] min-w-[50px] h-[53px] text-left pl-[20px] font-bold"
                        onClick={(e) => this.handleUserClick(user.id, e)}>
                        <span className="w-full one-line cursor-pointer hover:underline">{user.displayUserId}</span>
                      </td>
                      <td className="w-[50px] min-w-[50px] h-[53px] text-left pl-[20px] text-black">
                        <span className="w-full one-line">{user.userName}</span></td>
                      <td className="w-[50px] min-w-[50px] h-[53px] text-left pl-[20px] text-black">
                        <span className="w-full one-line">{user.userTypeId === userType.Sagyousha
                          ? "作業者"
                          : "管理者"}</span>
                      </td>
                      <td className="w-[30px]" onClick={(e) => this.handleThreeDotsClick(user.id, e)}>
                        <div className="flex justify-center items-center text-black cursor-pointer">
                          <IconContext.Provider
                            value={{ color: "Black", size: "20px" }}
                          >
                            <BsThreeDots />
                          </IconContext.Provider>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          {/* 右側パネル  */}
          {this.state.userInput}
        </div>
        {/* 編集ボタン */}
        {this.state.editBtn.btn}
        <div>
          {this.state.dialogBox}
        </div>
      </div>
    );
  }
}

export default User;
