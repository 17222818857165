import { React, useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import SectionTitle from "../../components/SectionTitle";
import { BiInfoCircle } from "react-icons/bi";
import { IconContext } from "react-icons";
import { BsThreeDots } from "react-icons/bs";
import TableTitle from "../../components/TableTitle";
import EditButton from "../../components/EditButton";
import { variables } from "../../Variables";
import { v4 as uuidv4 } from "uuid";
import { OperationManagementType } from "../../Variables";
import ComboBox from "../../components/ComboBox";
import InputBox from "../../components/InputBox";
import { TextType } from "../../Variables";
import { errorCode } from "../../ErrorCode";
import ErrorBox from "../../components/ErrorBox";
import ConfirmBox from "../../components/ConfirmBox";
import CompleteBox from "../../components/CompleteBox";
import {
    fetchItemData,
    fetchProcessList,
    setResetProcessList,
} from "../workRestore/workRestoreSlice";
import { GetUrlCompany, ToLocalDateTime, NavigateToCompanyLogin } from "../util/commonFun";
import SpinnerLoading from "../../components/SpinnerLoading";
import { appInsights } from "../util/ApplicationInsight";

let serialSearchBtnRefs = null;
let lotSearchBtnRefs = null;
let serialNumberInputRefs = null;
let lotNumberInputRefs = null;
let kouteiComboRefs = null;
let serialMatomeSuu = 1;
let fukkyuuResult = -1;

const axios = require('axios');
const urlCompanyName = GetUrlCompany();

const emptyLockedData = {
    logLockId: -1,
    lotSerialNo: "",
    userName: "",
    dispItemCode: "",
    itemName: "",
    processDispOrder: -1,
    processId: "",
    processName: "",
    workDispOrder: -1,
    workName: "",
    matomesuu: -1,
    lastDate: ""
}

function WorkRestore() {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const items = useSelector((state) => state.workRestore);
    const itemList = [...items.itemList];
    const itemListNoEnd = itemList.filter((item) => item.isEnd === false).sort((a, b) => a.itemName.localeCompare(b.itemName));
    const processList = [...items.processList];
    const [editBtn, setEditBtn] = useState({ id: -1, btn: null });
    const [selectedRow, setSelectedRow] = useState(-1);
    const [managementType, setmanagementType] = useState(OperationManagementType.SERIALNUMBER);
    const [lotItem, setLotItem] = useState("");
    const [lotNo, setLotNo] = useState("");
    const [serialItem, setSerialItem] = useState("");
    const [serialNo, setSerialNo] = useState("");
    const [dispProcessId, setDispProcessId] = useState("");
    const [selectedProcessId, setSelectedProcessId] = useState("");
    const [itemCode, setItemCode] = useState(-1);
    const [isStopKeyUp, setIsStopKeyUp] = useState(false);
    const [lockedData, setLockedData] = useState([]);
    const [spinnerLoading, setSpinnerLoading] = useState(null);
    const [dialogBox, setDialogBox] = useState(null);
    const [fukkyuuErrCode, setFukkyuuErrCode] = useState(-1);

    const titleCss = "h-[29px] inline-flex border-r-[1px] border-solid border-[#707070] border-opacity-5";
    const rowCss = "text-[#000000] " + variables.HOVER_CSS;
    const selectedRowCss = "text-[#000000] bg-[#D8D7D5]";
    const buttonEnable = "w-[94px] h-[32px] cursor-pointer mt-[15px] ml-[100px] border-[1px] rounded-[2px] bg-[#0073CD] text-white hover:bg-[#0068B8]";
    const buttonDisable = "w-[94px] h-[32px] cursor-pointer mt-[15px] ml-[100px] border-[1px] rounded-[2px] bg-[#F3F2F1] text-black";

    useEffect(() => {
        dispatch(fetchItemData())
    }, []);

    function handleRadioBtnClick(mngType) {
        setmanagementType(mngType);
        if (managementType !== mngType) {
            clearEditButton();
            setLockedData([]);
            setSerialItem("");
            setSerialNo("");
            setLotItem("");
            setLotNo("");
            setSelectedProcessId("");
            dispatch(setResetProcessList());
        }
    }

    function handleSelectedItemChange(e) {
        setLockedData([]);
        const selectItemObj = itemListNoEnd.find((item) => item.itemCode === e.targetValue);
        setItemCode(e.targetValue);
        if (managementType === OperationManagementType.SERIALNUMBER) {
            serialNumberInputRefs.focus();
            setSerialNo("");
            setSerialItem(selectItemObj.itemName);
        }
        else {
            lotNumberInputRefs.focus();
            dispatch(setResetProcessList());
            setLotNo("");
            setSelectedProcessId("");
            setLotItem(selectItemObj.itemName);
            if (selectItemObj.processCnt > 0) {
                dispatch(fetchProcessList(selectItemObj.itemCode));
            }
        }
    }

    function handleItemEnterKeyDown(e) {
        if (e.key === 'Enter') {
            setIsStopKeyUp(true);
            if (managementType === OperationManagementType.SERIALNUMBER) {
                serialNumberInputRefs.focus();
            }
            else {
                lotNumberInputRefs.focus();
            }
        }
    }

    function handleSerialNumberChange(e) {
        setLockedData([]);
        const str = e.target.value;
        setSerialNo(str);
        setIsStopKeyUp(false);
    }

    function handleSerialNumberKeyUp(e, str) {
        if (e.key === 'Enter' && isStopKeyUp === false) {
            if (str.length > 0) {
                serialSearchBtnRefs.focus();
            }
            else {
                setSerialNo(str);
            }
        }
        setIsStopKeyUp(false);
    }

    function handleSerialNumberFocusOut(str) {
        setSerialNo(str);
    }

    function handleSerialSearchClick(e) {
        setSpinnerLoading(<SpinnerLoading />);
        const comRecPromise = getLockedSerialData(itemCode, serialNo);
        Promise.all([comRecPromise])
            .then((responses) => { setSpinnerLoading(null); });
    }

    function handleSerialSearchKeyUp(e) {
        if (e.key === 'Enter' && isStopKeyUp === false) {
            e.stopPropagation();
            e.preventDefault();
            e.currentTarget.blur();
            handleSerialSearchClick(e);
        }
        setIsStopKeyUp(false);
    }

    async function getLockedSerialData(itemCode, serialNo) {
        let paramObj = { itemCode: itemCode, serialNo: serialNo };
        await axios({
            method: 'GET',
            url: variables.WORKRESTORE_URL + "/get-locked-serial-operation-data",
            params: paramObj,
            headers: { 'company': urlCompanyName },
            withCredentials: true,
        }).then(function (response) {
            let lockedSerialData = response.data;
            lockedSerialData.sort((a, b) => new Date(a.lastDate) - new Date(b.lastDate));
            setLockedData([]);
            if (lockedSerialData && lockedSerialData.length > 0) {
                setLockedData(lockedSerialData.map(lockedSerialData => {
                    return {
                        ...emptyLockedData,
                        logLockId: lockedSerialData.serialOperationLogLockId,
                        lotSerialNo: lockedSerialData.serialNo,
                        userName: lockedSerialData.userName,
                        dispItemCode: lockedSerialData.dispItemCode,
                        itemName: lockedSerialData.itemName,
                        processDispOrder: lockedSerialData.processDispOrder,
                        processId: lockedSerialData.processId,
                        processName: lockedSerialData.processName,
                        workDispOrder: lockedSerialData.workDispOrder,
                        workName: lockedSerialData.workName,
                        matomesuu: serialMatomeSuu,
                        lastDate: lockedSerialData.lastDate
                    }
                }));
            }
        }).catch(function (error) {
            let errStatus = error.response.status;
            if (errStatus === 401) {
                NavigateToCompanyLogin(navigate);
            }
            else{
                appInsights.trackTrace({ params: JSON.stringify(paramObj) });
                appInsights.trackException({...error, errorFunction: "WorkRestore.getLockedSerialData()"});    
            }
        });
    }

    function handleLotNumberChange(e) {
        setLockedData([]);
        const str = e.target.value;
        setLotNo(str);
        setSelectedProcessId("");
        setIsStopKeyUp(false);
    }

    function handleLotNumberKeyUp(e, str) {
        if (e.key === 'Enter' && isStopKeyUp === false) {
            if (str.length > 0) {
                kouteiComboRefs.focus();
            }
            else {
                setLotNo(str);
            }
        }
        setIsStopKeyUp(false);
    }

    function handleLotNumberFocusOut(str) {
        setLotNo(str);
    }

    function handleKouteiSelectedChange(e) {
        setLockedData([]);
        let processId = e.targetValue;
        setDispProcessId(processList.find((x) => x.processId === processId)?.dispProcessId);
        setSelectedProcessId(processId);
        setIsStopKeyUp(true);
        lotSearchBtnRefs.focus();
    }

    function handleKouteiEnterKeyDown(e) {
        if (e.key === 'Enter' && selectedProcessId.length > 0) {
            setIsStopKeyUp(true);
            lotSearchBtnRefs.focus();
        }
    }

    function handlelotSearchClick(e) {
        setSpinnerLoading(<SpinnerLoading />);
        const comRecPromise = getLockedLotData(itemCode, lotNo, dispProcessId);
        Promise.all([comRecPromise])
            .then((responses) => { setSpinnerLoading(null); });
    }

    function handlelotSearchKeyUp(e) {
        if (e.key === 'Enter' && isStopKeyUp === false) {
            e.stopPropagation();
            e.preventDefault();
            e.currentTarget.blur();
            handlelotSearchClick(e);
        }
        setIsStopKeyUp(false);
    }

    async function getLockedLotData(itemCode, lotNo, dispProcessId) {
        let paramObj = { itemCode: itemCode, lotNo: lotNo, dispProcessId: dispProcessId };
        await axios({
            method: 'GET',
            url: variables.WORKRESTORE_URL + "/get-locked-lot-operation-data",
            params: paramObj,
            headers: { 'company': urlCompanyName },
            withCredentials: true
        }).then(function (response) {
            let lockedLotData = response.data;
            lockedLotData.sort((a, b) => new Date(a.lastDate) - new Date(b.lastDate));
            setLockedData([]);
            if (lockedLotData && lockedLotData.length > 0) {
                setLockedData(lockedLotData.map(lockedLotData => {
                    return {
                        ...emptyLockedData,
                        logLockId: lockedLotData.lotOperationLogLockId,
                        lotSerialNo: lockedLotData.lotNo,
                        userName: lockedLotData.userName,
                        dispItemCode: lockedLotData.dispItemCode,
                        itemName: lockedLotData.itemName,
                        processDispOrder: lockedLotData.processDispOrder,
                        processId: lockedLotData.processId,
                        processName: lockedLotData.processName,
                        workDispOrder: lockedLotData.workDispOrder,
                        workName: lockedLotData.workName,
                        matomesuu: lockedLotData.matomesuu,
                        lastDate: lockedLotData.lastDate
                    }
                }))
            }
        }).catch(function (error) {
            let errStatus = error.response.status;
            if (errStatus === 401) {
                NavigateToCompanyLogin(navigate);
            }
            else{
                appInsights.trackTrace({ params: JSON.stringify(paramObj) });
                appInsights.trackException({...error, errorFunction: "WorkRestore.getLockedLotData()"});       
            }
        });
    }

    const isActiveSearchBtn = () => {
        if ((serialItem.length > 0 && serialNo.length > 0)
            || (lotItem.length > 0 && lotNo.length > 0 && selectedProcessId.length > 0)) {
            return true;
        }
        return false;
    }

    function handleThreeDotsClick(logLockId, index, e) {
        setSelectedRow(index);
        const positionX = 1828;
        const positionY = e.pageY - e.currentTarget.clientHeight / 2;
        setEditBtn({
            id: -1, btn: (
                <div
                    style={{
                        position: "absolute",
                        left: positionX + "px",
                        top: positionY + "px",
                    }}
                >
                    <EditButton onClick={() => handleEdit(logLockId)} />
                </div>
            )
        });
        e.stopPropagation();
    }

    function handleEdit(logLockId) {
        clearEditButton();
        const msg = <>選択した作業を復旧します。<br />
            間違えた場合、間違えて復旧処理をした作業が停止してしまいます。
            選択した作業を本当に復旧しますか？</>
        if (managementType === OperationManagementType.SERIALNUMBER) {
            setDialogBox(
                <ConfirmBox
                    className="absolute right-[162px] top-[265px] w-[471px]"
                    title="作業復旧"
                    message={msg}
                    onYesClick={() => confrimSerialEdit(itemCode, serialNo, logLockId)}
                    onNoClick={deleteDialog} />
            )
        }
        else {
            setDialogBox(
                <ConfirmBox
                    className="absolute right-[162px] top-[265px] w-[471px]"
                    title="作業復旧"
                    message={msg}
                    onYesClick={() => confirmLotEdit(itemCode, lotNo, dispProcessId, logLockId)}
                    onNoClick={deleteDialog}
                />
            )
        }
    }

    function clearEditButton() {
        // 編集ボタンをクリアする。
        setSelectedRow(-1);
        setEditBtn({ id: -1, btn: null });
    }

    function deleteDialog() {
        setDialogBox(null);
    }

    async function confrimSerialEdit(itemCode, serialNo, logLockId) {
        deleteDialog();
        let isError = await getSerialFukkyuuResult(itemCode, serialNo, logLockId);
        if (isError === false) {
            setDialogBox(<CompleteBox
                className="absolute right-[162px] top-[100px] w-[471px] text[22px]"
                title="作業復旧"
                message={"復旧処理が完了しました。"}
                onYesClick={clearDisplay} />)
        }
    }

    async function getSerialFukkyuuResult(itemCode, serialNo, logLockId) {
        let isError = false;
        let paramObj = { serialOperationLogLockId: logLockId, itemCode: itemCode, serialNo: serialNo };
        await axios({
            method: 'GET',
            url: variables.WORKRESTORE_URL + "/get-serial-fukkyuu-result",
            params: paramObj,
            headers: { 'company': urlCompanyName },
            withCredentials: true,
        }).then(function (response) {
            fukkyuuResult = response.data;
            if (fukkyuuResult === errorCode.E1012) {
                isError = true;
                setFukkyuuErrCode(errorCode.E1012);
            }
        }).catch(function (error) {
            let errStatus = error.response.status;
            if (errStatus === 401) {
                NavigateToCompanyLogin(navigate);
            }
            else{
                appInsights.trackTrace({ params: JSON.stringify(paramObj) });
                appInsights.trackException({...error, errorFunction: "WorkRestore.getSerialFukkyuuResult()"});    
            }
        });
        return isError;
    }

    async function confirmLotEdit(itemCode, lotNo, dispProcessId, logLockId) {
        deleteDialog();
        let isError = await getLotFukkyuuResult(itemCode, lotNo, dispProcessId, logLockId);
        if (isError === false) {
            setDialogBox(<CompleteBox
                className="absolute right-[162px] top-[100px] w-[471px]"
                title="作業復旧"
                message={"復旧処理が完了しました。"}
                onYesClick={clearDisplay} />)
        }
    }

    async function getLotFukkyuuResult(itemCode, lotNo, dispProcessId, logLockId) {
        let isError = false;
        let paramObj = { lotOperationLogLockId: logLockId, itemCode: itemCode, lotNo: lotNo, dispProcessId: dispProcessId };
        await axios({
            method: 'GET',
            url: variables.WORKRESTORE_URL + "/get-lot-fukkyuu-result",
            params: paramObj,
            headers: { 'company': urlCompanyName },
            withCredentials: true,
        }).then(function (response) {
            fukkyuuResult = response.data;
            if (fukkyuuResult === errorCode.E1012) {
                isError = true;
                setFukkyuuErrCode(errorCode.E1012);
            }
        }).catch(function (error) {
            let errStatus = error.response.status;
            if (errStatus === 401) {
                NavigateToCompanyLogin(navigate);
            }
            else{
                appInsights.trackTrace({ params: JSON.stringify(paramObj) });
                appInsights.trackException({...error, errorFunction: "WorkRestore.getLotFukkyuuResult()"});
            }
        });
        return isError;
    }

    function clearDisplay() {
        setLockedData([]);
        deleteDialog();
        setFukkyuuErrCode(-1);
        if (managementType === OperationManagementType.SERIALNUMBER) {
            setSerialItem("");
            setSerialNo("");
        }
        else {
            setLotItem("");
            setLotNo("");
            setSelectedProcessId("");
        }
    }

    return (
        <div onClick={clearEditButton}>
            <div>
                <div className="my-[16px] h-[21px]">システム</div>
                <SectionTitle className="mt-[15px]" Text="作業復旧"></SectionTitle>
                <div className="bg-[#DFF6DD] w-[1673px] h-[64px] mt-[6px]">
                    <div className="inline-flex ml-[19px] pt-[8px]">
                        <IconContext.Provider value={{ color: "#000000", size: "20px", className: "self-center" }}>
                            <BiInfoCircle />
                        </IconContext.Provider>
                        <div className="w-[355px] h-[21px] text-[16px] ml-[8px] text-[#000000]">
                            作業再開できない、作業中の状況を復旧処理します。
                        </div>
                    </div>
                    <div className="ml-[45px] w-[513px] h-[21px] text-[16px] text-[#000000]">
                        復旧処理を実行した場合は、Operationアプリのページを再読み込みしてください。
                    </div>
                </div>
                <div className="h-[2px] w-[1673px] mt-[8px] bg-[#C8C6C4] opacity-[.56] absolute" />
            </div>
            <div className="flex mt-[26px]">
                <div>
                    <div className="w-[208px] h-[40px]">
                        <TableTitle
                            className="underline underline-offset-4"
                            iconShow={false}
                            Text="検索条件" />
                    </div>
                    <div className="w-[298px] max-w-[298px] h-[106px] border-[1px] border-solid border-[#707070] border-opacity-100 rounded-[3px]">
                        <div className="flex flex-row items-start h-[20px] ml-[10px] mt-[3px]">
                            <div className="text-[18px] font-bold">管理方法</div>
                            <div className="text-[#CD0000]">
                                <strong>*</strong>
                            </div>
                        </div>
                        <div>
                            <div className="w-[208px] mt-[9px] ml-[10px]" onClick={() => handleRadioBtnClick(OperationManagementType.SERIALNUMBER)}
                                key={uuidv4()}>
                                <input
                                    key={uuidv4()}
                                    type="radio"
                                    id={"type" + OperationManagementType.SERIALNUMBER}
                                    name={OperationManagementType.SERIALNUMBER}
                                    checked={managementType === OperationManagementType.SERIALNUMBER}
                                    readOnly={true}
                                />
                                <label htmlFor={"type" + OperationManagementType.SERIALNUMBER} className="pl-1 text-[16px]">
                                    製造番号 / シリアル番号
                                </label>
                            </div>
                            <div className="w-[208px] mt-[12px] ml-[10px]" onClick={() => handleRadioBtnClick(OperationManagementType.LOTNUMBER)}
                                key={uuidv4()}>
                                <input
                                    key={uuidv4()}
                                    type="radio"
                                    id={"type" + OperationManagementType.LOTNUMBER}
                                    name={OperationManagementType.LOTNUMBER}
                                    checked={managementType === OperationManagementType.LOTNUMBER}
                                    readOnly={true}
                                />
                                <label htmlFor={"type" + OperationManagementType.LOTNUMBER} className="pl-1 text-[16px]">
                                    ロット番号
                                </label>
                            </div>
                        </div>
                    </div>
                    {managementType === OperationManagementType.SERIALNUMBER &&
                        <div className="w-[298px] h-[248px] mt-[6px] border border-[#707070] rounded-[3px] border-sol">
                            <div className="flex flex-row ml-[10px] mt-[8px] mr-[8px] mb-[3px] border-b border-[#E8E7E6]">
                                <div className="text-[18px] font-bold">製品</div>
                                <div className="text-[#CD0000]">
                                    <strong>*</strong>
                                </div>
                            </div>
                            <div className="w-[280px] mt-[8px] ml-[10px]">
                                <ComboBox
                                    width={280}
                                    height={38}
                                    fontSize={16}
                                    fontBold={false}
                                    verPadding={0}
                                    isSearchable={false}
                                    selectedOption={serialItem}
                                    itemsSource={itemListNoEnd}
                                    displayMemberPath={"itemName"}
                                    selectedValuePath={"itemCode"}
                                    onKeyDown={handleItemEnterKeyDown}
                                    onSelectedChange={handleSelectedItemChange}
                                    placeholder={"製品を選択して下さい。"}>
                                </ComboBox>
                            </div>
                            <div className="flex flex-row ml-[10px] mt-[24px] mr-[8px] mb-[3px] border-b border-[#E8E7E6]">
                                <div className="text-[18px] font-bold">製造番号 / シリアル番号</div>
                                <div className="text-[#CD0000]">
                                    <strong>*</strong>
                                </div>
                            </div>
                            <div className="w-[280px] mt-[8px]">
                                <InputBox
                                    className="ml-[10px] focus:outline-none border-2 w-[280px] h-[38px] px-2 text-ellipsis rounded-[2px]"
                                    maxLength={50}
                                    refInputBox={(e) => { serialNumberInputRefs = e; }}
                                    value={serialNo}
                                    textType={TextType.HALFWIDTH_SYMBOL_AUTOCHANGE}
                                    onChange={(e) => handleSerialNumberChange(e)}
                                    onKeyUp={(e, str) => handleSerialNumberKeyUp(e, str)}
                                    onFocusOut={(txt) => handleSerialNumberFocusOut(txt)}
                                    placeholder="シリアル番号を入力して下さい。">
                                </InputBox>
                            </div>
                            <div>
                                <div ref={(e) => { serialSearchBtnRefs = e; }} tabIndex={0} className={isActiveSearchBtn() === true ? buttonEnable : buttonDisable}
                                    onClick={isActiveSearchBtn() === true ? (e) => handleSerialSearchClick(e) : void (0)}
                                    onKeyUp={isActiveSearchBtn() === true ? (e) => handleSerialSearchKeyUp(e) : void (0)}>
                                    <div className="pl-[31px] pt-[4px]">検索</div>
                                </div>
                            </div>
                        </div>
                    }
                    {managementType === OperationManagementType.LOTNUMBER &&
                        <div className="w-[298px] h-[338px] mt-[6px] border border-[#707070] rounded-[3px] border-sol">
                            <div className="flex flex-row ml-[10px] mt-[8px] mr-[8px] mb-[3px] border-b border-[#E8E7E6]">
                                <div className="text-[18px] font-bold">製品</div>
                                <div className="text-[#CD0000]">
                                    <strong>*</strong>
                                </div>
                            </div>
                            <div className="w-[280px] mt-[8px] ml-[10px]">
                                <ComboBox
                                    width={280}
                                    height={38}
                                    fontSize={16}
                                    fontBold={false}
                                    verPadding={0}
                                    isSearchable={false}
                                    selectedOption={lotItem}
                                    itemsSource={itemListNoEnd}
                                    displayMemberPath={"itemName"}
                                    selectedValuePath={"itemCode"}
                                    onKeyDown={handleItemEnterKeyDown}
                                    onSelectedChange={handleSelectedItemChange}
                                    placeholder={"製品を選択して下さい。"}>
                                </ComboBox>
                            </div>
                            <div className="flex flex-row ml-[10px] mt-[24px] mr-[8px] mb-[3px] border-b border-[#E8E7E6]">
                                <div className="text-[18px] font-bold">ロット番号</div>
                                <div className="text-[#CD0000]">
                                    <strong>*</strong>
                                </div>
                            </div>
                            <div className="w-[280px] mt-[8px]">
                                <InputBox
                                    className="ml-[10px] focus:outline-none border-2 w-[280px] h-[38px] px-2 text-ellipsis rounded-[2px]"
                                    refInputBox={(e) => { lotNumberInputRefs = e; }}
                                    maxLength={50}
                                    value={lotNo}
                                    textType={TextType.HALFWIDTH_SYMBOL_AUTOCHANGE}
                                    onChange={(e) => handleLotNumberChange(e)}
                                    onKeyUp={(e, str) => handleLotNumberKeyUp(e, str)}
                                    onFocusOut={(txt) => handleLotNumberFocusOut(txt)}
                                    placeholder="ロット番号を入力して下さい。">
                                </InputBox>
                            </div>
                            <div className="flex flex-row ml-[10px] mt-[24px] mr-[8px] mb-[3px] border-b border-[#E8E7E6]">
                                <div className="text-[18px] font-bold">工程</div>
                                <div className="text-[#CD0000]">
                                    <strong>*</strong>
                                </div>
                            </div>
                            <div className="w-[280px] mt-[8px] ml-[10px]">
                                <ComboBox
                                    width={280}
                                    height={38}
                                    fontSize={16}
                                    fontBold={false}
                                    refComboBox={(e) => { kouteiComboRefs = e; }}
                                    verPadding={0}
                                    isSearchable={false}
                                    selectedOption={selectedProcessId}
                                    itemsSource={processList}
                                    displayMemberPath={"displayText"}
                                    selectedValuePath={"processId"}
                                    onKeyDown={handleKouteiEnterKeyDown}
                                    onSelectedChange={handleKouteiSelectedChange}
                                    placeholder={"工程を選択して下さい。"}>
                                </ComboBox>
                            </div>
                            <div>
                                <div ref={(e) => { lotSearchBtnRefs = e; }} tabIndex={0} className={isActiveSearchBtn() === true ? buttonEnable : buttonDisable}
                                    onClick={isActiveSearchBtn() === true ? (e) => handlelotSearchClick(e) : void (0)}
                                    onKeyUp={isActiveSearchBtn() === true ? (e) => handlelotSearchKeyUp(e) : void (0)}>
                                    <div className="pl-[31px] pt-[4px]">検索</div>
                                </div>
                            </div>
                        </div>}
                </div>
                <div>
                    <div className="w-[1300px] h-[29px] ml-[10px]">
                        <div className={"w-[140px] " + titleCss}>
                            <TableTitle
                                iconShow={false}
                                Text="最終日時" />
                        </div>
                        <div className={"w-[136px] pl-[24px] " + titleCss}>
                            <TableTitle
                                horiPadding={0}
                                iconShow={false}
                                Text="ユーザー名" />
                        </div>
                        <div className={"w-[200px] pl-[24px] " + titleCss}>
                            <TableTitle
                                horiPadding={0}
                                iconShow={false}
                                Text="製品コード" />

                        </div>
                        <div className={"w-[215px] pl-[16px] " + titleCss}>
                            <TableTitle
                                horiPadding={0}
                                iconShow={false}
                                Text="製品名" />

                        </div>
                        <div className={"w-[224px] pl-[40px] " + titleCss}>
                            <TableTitle
                                horiPadding={0}
                                iconShow={false}
                                Text="工程" />

                        </div>
                        <div className={"w-[264px] pl-[40px] " + titleCss}>
                            <TableTitle
                                horiPadding={0}
                                iconShow={false}
                                Text="作業" />

                        </div>
                        <div className={"w-[80px] pl-[7px] " + titleCss}>
                            <TableTitle
                                horiPadding={0}
                                horiMargin={0}
                                iconShow={false}
                                Text="まとめ数" />
                        </div>
                    </div>
                    <div className="h-[1px] mt-[3px] ml-[10px] bg-[#C8C6C4] opacity-[.56]" />
                    <div className="h-[720px] w-[1340px] overflow-y-scroll overflow-x-hidden">
                        <table className="w-[1325] border-collapse ml-[10px] pl-[72px]">
                            <tbody>
                                {lockedData && lockedData.map((data, index) => (
                                    <tr key={index} className={selectedRow === index ? selectedRowCss : rowCss}>
                                        <td className="w-[140px] h-[48px]">
                                            <div className="pl-[12px] items-center">
                                                {ToLocalDateTime(data.lastDate)}
                                            </div>
                                        </td>
                                        <td className="w-[136px] h-[48px]">
                                            <div className="ml-[24px] items-center overflow-hidden text-ellipsis whitespace-nowrap max-w-[111px]">
                                                {data.userName}
                                            </div>
                                        </td>
                                        <td className="w-[200px] h-[48px]">
                                            <div className="ml-[24px] items-center overflow-hidden text-ellipsis whitespace-nowrap max-w-[200px]" >
                                                {data.dispItemCode}
                                            </div>
                                        </td>
                                        <td className="w-[215px] h-[48px]">
                                            <div className="ml-[16px] items-center max-w-[200px] overflow-hidden whitespace-nowrap text-ellipsis">
                                                {data.itemName}
                                            </div>
                                        </td>
                                        <td className="w-[224px] h-[48px]">
                                            <div className="ml-[23px] pt-[16px] pb-[11px] inline-flex items-center">
                                                <div className="w-[9px]">{data.processDispOrder}</div>
                                                <div className="ml-[8px] max-w-[176px] overflow-hidden text-ellipsis whitespace-nowrap">
                                                    {data.processName}
                                                </div>
                                            </div>
                                        </td>
                                        <td className="w-[264px] h-[48px]">
                                            <div className="ml-[23px] pt-[16px] pb-[11px] inline-flex items-center">
                                                <div className="w-[9px]">{data.workDispOrder}</div>
                                                <div className="ml-[8px] max-w-[214px] text-ellipsis overflow-hidden whitespace-nowrap">
                                                    {data.workName}
                                                </div>
                                            </div>
                                        </td>
                                        <td className="w-[72px] h-[48px]">
                                            <div className="ml-[40px] text-right mr-[15px]">{data.matomesuu}</div>
                                        </td>
                                        <td className="w-[49px] h-[48px]"><div
                                            onClick={(e) => handleThreeDotsClick(data.logLockId, index, e)}
                                            className="mx-[15px] flex items-center cursor-pointer">
                                            <IconContext.Provider
                                                value={{ color: "Black", size: "20px", }}>
                                                <BsThreeDots />
                                            </IconContext.Provider>
                                        </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    {fukkyuuErrCode > 0 && (
                        <ErrorBox
                            className={"absolute right-[1px] top-[40px] w-[490px]"}
                            Title="作業復旧"
                            Message={errorCode[fukkyuuErrCode]}
                            onYesClick={() => clearDisplay()}
                        />
                    )}
                    {/* 編集ボタン */}
                    {editBtn.btn}
                    {/* Loading... */}
                    {spinnerLoading}
                    {/* dialog */}
                    {dialogBox}
                </div>
            </div>
        </div>
    )
}
export default WorkRestore;