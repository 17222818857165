export const variables = {
  CATEGORY_URL: `${process.env.REACT_APP_BACKEND_URL}/category`,
  ITEM_URL: `${process.env.REACT_APP_BACKEND_URL}/item`,
  USER_URL: `${process.env.REACT_APP_BACKEND_URL}/user`,
  EDITOR_URL: `${process.env.REACT_APP_BACKEND_URL}/editor`,
  PROCESS_URL: `${process.env.REACT_APP_BACKEND_URL}/process`,
  WORK_URL: `${process.env.REACT_APP_BACKEND_URL}/work`,
  MNGITM_URL: `${process.env.REACT_APP_BACKEND_URL}/manageitem`,
  TOOL_URL: `${process.env.REACT_APP_BACKEND_URL}/tool`,
  PROCESS_CHART_URL: `${process.env.REACT_APP_BACKEND_URL}/processchart`,
  DETAIL_DOC_URL: `${process.env.REACT_APP_BACKEND_URL}/detaildoc`,
  OPERATION_URL: `${process.env.REACT_APP_BACKEND_URL}/operation`,
  LOGIN_URL: `${process.env.REACT_APP_BACKEND_URL}/login`,
  COMPLETEDRECORD_URL: `${process.env.REACT_APP_BACKEND_URL}/completedrecord`,
  WORKRESTORE_URL: `${process.env.REACT_APP_BACKEND_URL}/workrestore`,
  PRODUCTION_PLAN_URL: `${process.env.REACT_APP_BACKEND_URL}/productionplan`,
  FILEUPLOAD_URL: `${process.env.REACT_APP_BACKEND_URL}/fileupload`,
  COLOR_DISABLED: "#A19F9D",
  HOVER_CSS: "hover:bg-[#D8D7D5]",
  VERSION: `v${process.env.REACT_APP_VERSION}`,
  APPINSIGHTS_CONNECTION_STR: process.env.REACT_APP_APPINSIGHTS_CONNECTIONSTRING,
}

export const CompanyLoginPath = "/:company/editor/login";
export const UserLoginPath = "/:company/editor/login/userlogin";
export const OperationPath = "/:company/operation";
export const OperationLoginPath = "/:company/operation/login";
export const OperationStartWorkPath = "/:company/operation/operationstartwork";

export const LockAttributes = {
  ATTRIBUTES: " text-[#A19F9D] opacity-60 bg-[#F3F2F1]",
};

export const Sort = {
  NONE: 0,
  ASC: 1,
  DESC: 2,
};
Object.freeze(Sort);

export const ManageItemType = {
  None: 0,
  Audio: 1,
  MarketWarning: 2,
  Text: 3,
  TextCompare: 4,
  NumericalJudge: 5,
  OkNgJudge: 6,
  SagyouBunki: 7,
  Hardware: 8,
};
Object.freeze(ManageItemType);

export const CompareSource = {
  Shitei: 1,
  Work: 2,
  LotSerialNo: 3,
};
Object.freeze(CompareSource);

export const TextType = {
  ALL: 1,
  HALFWIDTH_INPUT: 2,
  HALFWIDTH_NUMBER: 3,
  HALFWIDTH_SYMBOL_INPUT: 4,
  HALFWIDTH_SYMBOL_AUTOCHANGE: 5,
  HALFWIDTH_NUMBER_AUTOCHANGE: 6,
  HALFWIDTH_HANDLE_NAME_AUTOCHANGE: 7,
};
Object.freeze(TextType);

export const userType = {
  Sagyousha: 1,
  Kanrisha: 2,
}
Object.freeze(userType)

export const CompareMethod = {
  AllMatch: 1,
  PartialMatch: 2,
};
Object.freeze(CompareMethod);

export const SagyouBunkiType = {
  BtnType: 1,
  ListType: 2,
};
Object.freeze(SagyouBunkiType);

export const HardwareType = {
  None: 0,
  FiotCount: 1,
  SioCount: 2,
};
Object.freeze(HardwareType);

export const ToolType = {
  None: 0,
  Buhin: 1,
  Kougu: 2,
};
Object.freeze(ToolType);

export const DetailDocType = {
  NONE: 0,
  MOVIE: 1,
  PDF: 2,
  TEXT: 3,
};
Object.freeze(DetailDocType);

export const StatusCode = {
  // エラー
  ValidationFailed: -1,
  // 会社ログイン失敗
  UnAuthenticated: 0,
  // 会社ログイン成功
  Authenticated: 1,
  // 会社ログイン成功、ユーザー権限
  AuthorizedWithUser: 2,
  // 会社ログイン成功、管理者権限
  AuthorizedWithAdministrator: 3,
};
Object.freeze(StatusCode);

export const WorkStatus = {
  NONE: 0,                        // 作業なし
  WORKOK: 1,                      // 作業OK
  STARTWORK: 2,                   // 作業開始
  SUSPENSION: 3,                  // 中断
  FINISH: 4,                      // 終了
  WORKCOMPLETED: 5,               // 作業完了
  WORKMOVE: 6,                    // 作業位置変更
  NG: 7,                          // 作業NG
  MATOMESUUCHANGE: 8,             // まとめ数変更
  RESUMEWORK: 9,                  // 作業再開
  STARTPROCESS: 10,               // 工程開始
  FINISHPROCESS: 11,              // 工程完了
  UNLOCK: 12,                     // ロック解除
  MATOMESUUCHANGESUSPENSION: 13,  // まとめ数変更中断
  KURIKAESHI: 14,                 // 繰り返し
  HARDWAREOK: 15,                 // ハードウェアOK
  HARDWARENG: 16,                 // ハードウェアNG
}
Object.freeze(WorkStatus);

export const OkNgResult = {
  OK: "OK",
  NG: "NG",
}
Object.freeze(OkNgResult);

export const MasterPage = {
  HOME: 0,
  PROCESS_CHART: 1,
  CATEGORY: 2,
  ITEM: 3,
  USER: 4,
  COMPLETEDRECORD: 5,
  WORKRESTORE: 6,
  PRODUCTION_PLAN: 7,
};
Object.freeze(MasterPage);

export const OperationManagementType = {
  NONE: 0,
  SERIALNUMBER: 1,
  LOTNUMBER: 2,
};
Object.freeze(OperationManagementType);

export const ShikakariStatus = {
  NONE: 0,
  SAGYOUCHUU: 1,
  CHUUDAN: 2,
}
Object.freeze(ShikakariStatus);

export const BunkiStatus = {
  None: 0,
  Kanri: 1,
  BunkiSaki: 2,
  BunkiSakiAndKanri: 3,
  BunkiMoto: 4,
  TextCompareSakiAndKanri: 5,
  TextCompareMoto: 6,
}
Object.freeze(BunkiStatus);

export const ProductionPlanType = {
  REGISTER: 0,
  RESTORE: 1,
};
Object.freeze(ProductionPlanType);

export const ProductionPlanOrderColumn = {
  None: 0,
  DispItemCode: 1,
  ItemName: 2,
  SerialNumber: 3,
  LotNumber: 4,
  PlanDate: 5,
};
Object.freeze(ProductionPlanOrderColumn);

export const SerialPortStatus = {
  Connected: "接続あり",
  Disconnected: "接続なし"
};
Object.freeze(SerialPortStatus);

export const ImageUrlYuukoukikan = {
  ShortTerm: 8,
  LongTerm: 8760,
};
Object.freeze(ImageUrlYuukoukikan);

export const UrlPath = {
  Editor: 0,
  Operation: 1,
};
Object.freeze(UrlPath);

export const Salt = "$2a$10$" + process.env.REACT_APP_SALT;

export const popupcss = "absolute inset-0 z-10 bg-[#605E5C] bg-opacity-60 ";

export const popupWithYellowBG = "absolute inset-0 z-10 bg-[#A5A545] bg-opacity-70 ";

export const maxLengthWorkNote = 250;

export const BunkiTxt = "bunki";