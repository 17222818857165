import { React, useRef, useState } from "react";
import SectionTitle from "../../../components/SectionTitle";
import { IoMdClose } from "@react-icons/all-files/io/IoMdClose";
import { popupcss, variables, TextType, ImageUrlYuukoukikan, OperationManagementType, UrlPath } from "../../../Variables";
import ReadOnlyTextBox from "../../../components/ReadOnlyTextBox";
import InputBox from "../../../components/InputBox";
import ErrorBox from "../../../components/ErrorBox";
import { FileUpload, GetUrlCompany, NavigateToOperationLogin } from "../../util/commonFun";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from 'react-router-dom';
import { appInsights } from "../../util/ApplicationInsight";
import { setIsLoading as setPageLoading } from "../../Loading/pageLoadingSlice";
import { useDispatch } from "react-redux";

const axios = require('axios');
const urlCompanyName = GetUrlCompany();
// 最大ファイルサイズ： 50 MB
const maxFileSize = 50;
const regex = /^\d*$/;

let file = null;
let errMsg = "";

/**
 * テキスト・イメージURL・停止時間登録ボックス。
 * @param {*} props 
 * @returns 
 */
function TeishiInputBox(props) {
    const inputFile = useRef(null);
    let navigate = useNavigate();
    const [inputText, setInputText] = useState("");
    const [teishiTime, setTeishiTime] = useState(0);
    const [showError, setShowError] = useState(false);
    const [fileName, setFileName] = useState("");
    const dispatch = useDispatch();

    function handleImageChange() {
        // <input type="file" /> のクリックイベントを発生させる。
        inputFile.current.click();
    }

    function handleInputFileChange(e) {
        if (e.target.files.length > 0) {
            const fileSize = e.target.files[0].size / 1024 / 1024;
            //ファイルの拡張子を取る
            const fileExtension = "." + e.target.files[0].name.split(".").pop();
            let allowedFileExtensions = [".jpg", ".jpeg", ".jfif", ".png"];
            if (allowedFileExtensions.indexOf(fileExtension.toLowerCase()) === -1) {
                // ファイル形式がjpg,jpeg,jfif,png以外の場合エラーメッセージを出す
                errMsg = (
                    <>
                        選択したファイルは登録できません。
                        <br />
                        登録できるファイルは「jpg」、「jpeg」、「jfif」、「png」です。
                    </>
                );
                setShowError(true);
                e.target.value = "";
                file = null;
            }
            else if (fileSize > maxFileSize) {
                // ファイルサイズ　50 MB を超える場合エラーメッセージを出す。
                errMsg = "ファイル容量制限 50MB を超えています。";
                setShowError(true);
                e.target.value = "";
                file = null;
            } else {
                file = e.target.files[0];
                setFileName(file.name);
            }
        }
    }

    /**
     * 登録ボタンの有効無効判断をする。
     * return true: テキスト入力 or 画像ファイル or 停止時間入力のいずれかが入力された場合.
     * @returns 
     */
    function isEnableRegiserButton() {
        if ((inputText && inputText.length > 0) || (file?.name.length > 0) || teishiTime) {
            return true;
        } else {
            return false;
        }
    }

    function handleClose() {
        props.onClose();
    }

    function handleTeishiInputChange(e) {
        setInputText(e.target.value);
    }

    function handleInputTeishiTimeChange(e) {
        let inputTxt = e.target.value.trim();
        if (isExistFullWidthCharacter(inputTxt) || regex.test(inputTxt)) {
            if (inputTxt.length > 0) {
                inputTxt = isNaN(inputTxt) ? inputTxt : Number(inputTxt) > 0 ? Number(inputTxt) : 0;
            }
            setTeishiTime(inputTxt);
        }
    }

    function isExistFullWidthCharacter(str) {
        const fullWidthPattern = /[\uFF00-\uFFEF\u4E00-\u9FFF\u3000-\u303F]/g;
        for (let i = 0; i < str.length; i++) {
            if (fullWidthPattern.test(str[i])) {
                return true;
            }
        }
        return false;
    }

    function handleInoutTextKeyUp(e, str) {
        if (e.key === 'Enter') {
            teishiInputFilter(str);
        }
    }

    function teishiInputFilter(str) {
        let value = "";
        for (let i = 0; i < str.length; i++) {
            if (str[i].match(regex)) {
                value += str[i];
            }
        }
        if (value.length > 0) {
            value = isNaN(value) ? value : Number(value) > 0 ? Number(value) : 0;
        }
        return setTeishiTime(value);
    }

    async function handleTeishiInputRegister() {
        let imageUrl = "";
        let isSuccessFileUpload = true;
        dispatch(setPageLoading(true));
        if (file) {
            const ext = '.' + file.name.split('.').pop();
            let name = uuidv4() + ext;
            try {
                // ファイルのアップロード
                imageUrl = await FileUpload(file, name, ImageUrlYuukoukikan.LongTerm, UrlPath.Operation);
            } catch (error) {
                isSuccessFileUpload = false;
                if (error === 401) {
                    NavigateToOperationLogin(navigate);
                }
                else {
                    errMsg = "登録に失敗しました。";
                    setShowError(true);
                    setFileName("");
                    file = null;
                    inputFile.current.value = "";
                    appInsights.trackException({ ...error, errorFunction: "TeishiInputBox.handleTeishiInputRegister()" });
                }
            }
        }

        if (isSuccessFileUpload) {
            let teishiInputData = {
                operationLogId: props.logId,
                inputText: inputText,
                teishiTime: teishiTime,
                imageUrl: imageUrl,
            };

            const urlName = props.managementType === OperationManagementType.LOTNUMBER ? "/register-lot-teishi-input" : "/register-serial-teishi-input";
            await axios({
                method: 'POST',
                url: variables.OPERATION_URL + urlName,
                withCredentials: true,
                headers: { 'company': urlCompanyName },
                data: teishiInputData
            }).catch(function (error) {
                isSuccessFileUpload = false;
                let errResponseStatus = error.response.status;
                if (errResponseStatus === 401) {
                    NavigateToOperationLogin(navigate);
                }
                else {
                    errMsg = "登録に失敗しました。";
                    setShowError(true);
                    appInsights.trackTrace({ data: JSON.stringify(teishiInputData) });
                    appInsights.trackException({ ...error, errorFunction: "TeishiInputBox.handleTeishiInputRegister()" });
                    console.error(error);
                }
            });
            if (isSuccessFileUpload) {
                props.onClose();
                file = null;
            }
        }
        dispatch(setPageLoading(false));
    }

    return (
        <div className={popupcss} >
            <div className="w-0 h-0 opacity-0">
                <input
                    className="w-0 h-0 opacity-0"
                    type="file"
                    ref={inputFile}
                    onChange={handleInputFileChange}
                    accept=".jpg, .jpeg, .jfif, .png"
                />
            </div>

            <div className={props.className}>
                <div className='w-[512px] h-[1033px] p-[15px] mt-[23px] border-[1px] border-[#C8C6C4] drop-shadow-[0_6px_6px_rgba(96,94,92,1)] bg-white rounded-sm'>
                    <div className="grid grid-cols-2">
                        <SectionTitle Text="テキスト入力" className='text-[24px] font-bold' />
                        <div className="justify-self-end">
                            <button onClick={handleClose}>
                                <IoMdClose className={variables.HOVER_CSS} />
                            </button>
                        </div>
                    </div>
                    <div className="my-[17px]">
                        1000文字以内でテキストを入力（改行可能）
                    </div>
                    <div className="my-[15px]">
                        <textarea
                            value={inputText}
                            onChange={handleTeishiInputChange}
                            maxLength={1000}
                            className="w-[472px] h-[450px] pl-[5px] resize-none rounded-sm border-2 border-[#C8C6C4] outline-offset-0 focus:outline-[#0073CD]"
                        />
                    </div>
                    <SectionTitle Text="画像ファイル登録" className='text-[24px]' />
                    <div className='my-[15px]'>ファイル選択</div>
                    <div className="mt-[10px]">
                        <ReadOnlyTextBox
                            text={fileName}
                            onClick={handleImageChange}
                        />
                    </div>
                    <div className='mb-[15px]'>
                        ファイルが登録済みの場合は上書きされます
                    </div>
                    <SectionTitle Text="停止時間入力" className='text-[24px] mt-[27px]' />
                    <div className="flex my-[15px]">
                        <InputBox
                            value={teishiTime === 0 ? "" : teishiTime}
                            onChange={(e) => handleInputTeishiTimeChange(e)}
                            onKeyUp={(e, str) => handleInoutTextKeyUp(e, str)}
                            onFocusOut={(txt) => teishiInputFilter(txt)}
                            maxLength={5}
                            textType={TextType.HALFWIDTH_NUMBER_AUTOCHANGE}
                            className="w-[72px] h-[29px] pl-[5px] px-1 text-right rounded-sm resize-none border-2 border-[#C8C6C4] outline-offset-0 focus:outline-[#0073CD]"
                        />
                        <span className="text-[20px] mt-[2px] ml-[8px] font-bold">分</span>
                    </div>
                    <div className="h-[1px] border-1 bg-[#C8C6C4] opacity-[.56]" />
                    <div className="my-[15px]">
                        {(isEnableRegiserButton()) ?
                            <button
                                type='button'
                                onClick={handleTeishiInputRegister}
                                className='w-[73px] h-[25px] mr-[7px] bg-[#0073CD] text-white border-[1px] border-[#0073CD] border-solid hover:bg-[#0068B8] rounded-sm'
                            >
                                登録
                            </button>
                            :
                            <button
                                type='button'
                                disabled="disabled"
                                className={'w-[73px] h-[25px] mr-[7px] text-[#A19F9D] border-solid rounded-sm bg-[#F3F2F1]'}
                            >
                                登録
                            </button>
                        }
                    </div>
                </div>
            </div>
            {/* サイズ制限とファイルタイプエラーボックス */}
            {showError && (
                <ErrorBox
                    className="absolute right-[162px] top-[265px] w-[550px]"
                    Title="ファイル登録"
                    Message={errMsg}
                    onYesClick={() => setShowError(false)}
                />
            )}
        </div>
    );
}

export default TeishiInputBox;