import ToggleButton from "./ToggleButton";
import { FiRepeat } from "@react-icons/all-files/fi/FiRepeat"
import { IconContext } from "@react-icons/all-files";
import { nanoid } from "nanoid";

function KurikaeshiToggle(props){
    return(
        <div>
            <div className={"flex items-center "}>
              <div>
                <IconContext.Provider value={{ size: "24px" }}>
                  <FiRepeat />
                </IconContext.Provider>
              </div>
              <div className={"m-1 font-bold"}>
                まとめ数 繰り返し
              </div>
              <ToggleButton
                isChecked={props.isChecked}
                onChange={props.onChange}
                inputID={props.inputID.length === 0 ? nanoid() : props.inputID}
                isLock={props.isLock}
              />
            </div>
          </div>
    );
}
KurikaeshiToggle.defaultProps={
    isChecked: false,
    onChange: void(0),
    inputID: "",
    isLock: false,
};
export default KurikaeshiToggle;