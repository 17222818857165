import { React, useEffect, useState } from "react";
import SectionTitle from "../../components/SectionTitle";
import { useDispatch } from "react-redux";
import { DatePicker } from "../../components/DatePicker";
import { useNavigate } from 'react-router-dom';
import Save from "../../components/Save";
import ErrorBox from "../../components/ErrorBox";
import Discard from "../../components/Discard";
import ToggleButton from "../../components/ToggleButton";
import ComboxBoxValueSearchable from "../../components/ComboxBoxValueSearchable";
import { GetUrlCompany, NavigateToCompanyLogin, SetIsEditing } from "../util/commonFun";
import { setIsLoading as setPageLoading } from "../Loading/pageLoadingSlice";
import { variables, TextType, OperationManagementType, CompareMethod, popupcss } from "../../Variables";
import InputBox from "../../components/InputBox";
import ConfirmBox from "../../components/ConfirmBox";
import { errorCode } from "../../ErrorCode";
import { appInsights } from "../util/ApplicationInsight";

export const emptyProductionPlanObj = {
    productionPlanId: -1,
    itemCode: -1,
    dispItemCode: "",
    itemName: "",
    serialNo: "",
    lotNo: "",
    dispProcessId: "",
    processName: "",
    quantity: 0,
    planDate: "",
    optionalText1: "",
    optionalText2: "",
    optionalText3: "",
    optionalText4: "",
    optionalText5: "",
    optionalNo: "",
    isChecked: false,
}

const axios = require("axios");
var hash = require('object-hash');
const urlCompanyName = GetUrlCompany();
const regex = /^[-]?\d{0,10}(\.\d{0,4})?$/;
const fullWidthPattern = /[\uFF00-\uFFEF\u4E00-\u9FFF\u3000-\u303F]/g;

function ProductionPlanInput(props) {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const [productionPlanObj, setProductionPlanObj] = useState({ ...props.productionPlanObj });
    let currCheckToggle = productionPlanObj ? productionPlanObj.dispProcessId.length > 0 : true;
    const [operationMgmtType, setOperationMgmtType] = useState(
        productionPlanObj.lotNo?.length > 0 ? OperationManagementType.LOTNUMBER : OperationManagementType.SERIALNUMBER
    );
    const [itemList, setItemList] = useState([]);
    const [processList, setProcessList] = useState([]);
    const [isCheckedToggleBtn, setIsCheckedToggleBtn] = useState(productionPlanObj.dispProcessId?.length > 0);
    const [showSaveConfirmMessage, setShowSaveConfirmMessage] = useState(false);
    const [showSaveErrorMessage, setShowSaveErrorMessage] = useState(false);
    const [isDiscard, setIsDiscard] = useState(false);

    useEffect(() => {
        if (itemList.length === 0) {
            axios({
                method: 'GET',
                url: variables.OPERATION_URL + "/get-itemData",
                headers: { 'company': urlCompanyName },
                withCredentials: true
            }).then(response => {
                let data = response.data;
                let itemListNoEnd = data.filter((item) => item.isEnd === false)
                itemListNoEnd.sort((a, b) => a.itemName.localeCompare(b.itemName));
                setItemList(itemListNoEnd);
            }).catch((error) => {
                if (error.response.status === 401) {
                    // ログイン画面へ移動する。                       
                    NavigateToCompanyLogin(navigate);
                }
                else {
                    appInsights.trackException({ ...error, errorFunction: "ProductionPlanInput.useEffect()" });
                }
            });
        }
        if (productionPlanObj?.itemCode >= 0) {
            getProcessListByItemCode(productionPlanObj.itemCode);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        SetIsEditing(isCheckDataChanged());
        return function cleanup() {
            SetIsEditing(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productionPlanObj]);

    /**
    * 製品名ドロップダウンの選択変更処理
    * @param {*} e 
    */
    async function handleItemNameSelectedChange(e) {
        let itemObj = itemList.find((item) => item.itemCode === e.targetValue);
        if (itemObj) {
            setProductionPlanObj((previousState) => {
                return {
                    ...previousState,
                    itemCode: itemObj.itemCode,
                    itemName: itemObj.itemName,
                    dispItemCode: itemObj.displayItemCode,
                    dispProcessId: ""
                }
            });
        }
        await getProcessListByItemCode(e.targetValue);
    }

    /**
     * 製品名ドロップダウンのフォーカスアウト処理
     * @param {*} e 
     */
    async function handleItemNameChangeFocusOut(e) {
        let inputTxt = e.target.value;
        if (inputTxt.length > 0) {
            let itemObj = itemList.find((item) => item.itemName.toLowerCase().includes(inputTxt.toLowerCase()));
            if (itemObj) {
                setProductionPlanObj((previousState) => {
                    return {
                        ...previousState,
                        itemCode: itemObj.itemCode,
                        itemName: itemObj.itemName,
                        dispItemCode: itemObj.displayItemCode,
                        dispProcessId: ""
                    }
                });
                await getProcessListByItemCode(itemObj.itemCode);
            }
        }
    }

    /**
    * 製品コードドロップダウンの選択変更処理
    * @param {*} e 
    */
    async function handleDispItemCodeSelectedChange(e) {
        let itemObj = itemList.find((item) => item.itemCode === e.targetValue);
        if (itemObj) {
            setProductionPlanObj((previousState) => {
                return {
                    ...previousState,
                    itemCode: itemObj.itemCode,
                    itemName: itemObj.itemName,
                    dispItemCode: itemObj.displayItemCode,
                    dispProcessId: ""
                }
            });
        }
        await getProcessListByItemCode(e.targetValue);
    }

    /**
    * 工程リストデータ取得
    * @param {*} selectedItemCode 
    */
    async function getProcessListByItemCode(selectedItemCode) {
        await axios({
            method: 'GET',
            url: variables.OPERATION_URL + "/get-process-list-by-itemcode",
            params: { itemCode: selectedItemCode },
            headers: { 'company': urlCompanyName },
            withCredentials: true,
        }).then(response => {
            let data = response.data;
            data.sort((a, b) => a.dispOrder - b.dispOrder);
            setProcessList(data);
        }).catch((error) => {
            if (error.response.status === 401) {
                NavigateToCompanyLogin(navigate);
            }
            else {
                appInsights.trackTrace({ params: JSON.stringify({ itemCode: selectedItemCode }) });
                appInsights.trackException({ ...error, errorFunction: "ProductionPlanInput.getProcessListByItemCode()" });
            }
        });
    }

    /**
    * 工程ドロップダウンの選択変更処理
    * @param {*} e 
    */
    function handleProcessSelectedChange(e) {
        let processObj = processList.find((process) => process.dispProcessID === e.targetValue);
        if (processObj) {
            setProductionPlanObj((previousState) => {
                return {
                    ...previousState,
                    dispProcessId: e.targetValue,
                    processName: processObj.processName
                }
            });
        }
    }

    /**
     * 完成予定日のPlanDate変更処理
     * @param {*} value 
     */
    function handlePlanDateChange(value) {
        setProductionPlanObj((previousState) => {
            return { ...previousState, planDate: value }
        });
    }

    /**
     * Serial/Lotラジオボタン処理
     * @param {*} str
     */
    function onChangeRadio(optMgmtType) {
        setOperationMgmtType(optMgmtType);
        setIsCheckedToggleBtn(false);
        let qty = optMgmtType === OperationManagementType.SERIALNUMBER ? 1 : 0;
        setProductionPlanObj((previousState) => {
            return {
                ...previousState,
                serialNo: "",
                lotNo: "",
                quantity: qty,
                dispProcessId: ""
            }
        });
    }

    /**
     * Serial/Lotテキストボックのフォーカスアウトイベント
     * @param {*} str
     */
    function handleSerialLotNumberFocusOut(str) {
        if (operationMgmtType === OperationManagementType.LOTNUMBER) {
            setProductionPlanObj((previousState) => {
                return {
                    ...previousState,
                    serialNo: "",
                    lotNo: str
                }
            });
        } else {
            setProductionPlanObj((previousState) => {
                return {
                    ...previousState,
                    serialNo: str,
                    lotNo: ""
                }
            });
        }
    }

    /**
     * 計画数テキストボックのフォーカスアウトイベント
     * @param {*} str
     */
    function handleQuantityFocusOut(str) {
        let value = "";
        for (let i = 0; i < str.length; i++) {
            if (str[i].match(/^[0-9]*$/)) {
                value += str[i];
            }
        }
        setProductionPlanObj((previousState) => {
            return { ...previousState, quantity: isNaN(value) ? value : Number(value) }
        });
    }

    /**
     * 任意入力_数値テキストボックのフォーカスアウトイベント
     * @param {*} str
     */
    function handleOptionalNoFocusOut(str) {
        inputTextFilter(str);
    }

    /**
     * Serial/LotテキストボックのEnterキー
     * @param {*} str
     */
    function handleSerialLotNumberKeyUp(e, str) {
        if (e.key === 'Enter') {
            if (operationMgmtType === OperationManagementType.LOTNUMBER) {
                setProductionPlanObj((previousState) => {
                    return { ...previousState, lotNo: str }
                });
            } else {
                setProductionPlanObj((previousState) => {
                    return { ...previousState, serialNo: str }
                });
            }
        }
    }

    /**
     * 計画数テキストボックのEnterキー
     * @param {*} str
     */
    function handleQuantityKeyUp(e, str) {
        if (e.key === 'Enter') {
            let value = "";
            for (let i = 0; i < str.length; i++) {
                if (str[i].match(/^[0-9]*$/)) {
                    value += str[i];
                }
            }
            setProductionPlanObj((previousState) => {
                return { ...previousState, quantity: isNaN(value) ? value : Number(value) }
            });
        }
    }

    /**
     * 任意入力_数値テキストボックのEnterキー
     * @param {*} str
     */
    function handleOptionalNoKeyUp(e, str) {
        if (e.key === 'Enter') {
            inputTextFilter(str);
        }
    }

    /**
     * 任意入力_数値テキストをフィルタする処理
     * @param {*} str 
     */
    function inputTextFilter(str) {
        let value = "";
        for (let i = 0; i < str.length; i++) {
            if (str[i].match(regex)) {
                value += str[i];
            }
        }
        if (regex.test(value) === false) {
            let newStr = "";
            let inputTxt = productionPlanObj.optionalNo;
            const argStr = inputTxt.split(/[.]/);
            if (argStr.length === 1 || argStr.length === 2) {
                let fullWidthStartIdx = argStr[0].search(fullWidthPattern);
                if (fullWidthStartIdx > -1) {
                    let temp = argStr[0].replace(fullWidthPattern, '');
                    let maxChar = Number(temp) < 0 ? 11 : 10;
                    newStr = temp.slice(0, fullWidthStartIdx);
                    newStr += value.slice(fullWidthStartIdx, fullWidthStartIdx + (maxChar - temp.length));
                    newStr += temp.slice(fullWidthStartIdx, temp.length);
                    newStr += argStr.length === 2 ? '.' + argStr[1] : "";
                }
                fullWidthStartIdx = argStr.length === 2 ? argStr[1].search(fullWidthPattern) : -1;
                if (fullWidthStartIdx > -1) {
                    newStr = argStr[0] + '.';
                    let temp = argStr[1].replace(fullWidthPattern, '');
                    newStr += temp.slice(0, fullWidthStartIdx);
                    newStr += value.slice(newStr.length, newStr.length + (4 - temp.length));
                    newStr += temp.slice(fullWidthStartIdx, temp.length);
                }
            }
            value = newStr;
        }
        setProductionPlanObj((previousState) => {
            return { ...previousState, optionalNo: value }
        });
    }

    /**
     * 計画数入力
     */
    function handlePlanNumberChange(e) {
        const str = e.target.value;
        setProductionPlanObj((previousState) => {
            return { ...previousState, quantity: isNaN(str) ? str : Number(str) }
        });
    }

    /**
     * Serial/Lot入力
     */
    function handleSerialLotNumberChange(e) {
        const str = e.target.value;
        if (operationMgmtType === OperationManagementType.LOTNUMBER) {
            setProductionPlanObj((previousState) => {
                return { ...previousState, lotNo: str }
            });
        } else {
            setProductionPlanObj((previousState) => {
                return { ...previousState, serialNo: str }
            });
        }
    }

    /**
     * 任意入力1
     */
    function handleOptionalText1Change(e) {
        const str = e.target.value;
        productionPlanObj.optionalText1 = str;
        setProductionPlanObj((previousState) => {
            return { ...previousState, optionalText1: str }
        });
    }

    /**
     * 任意入力1
     */
    function handleOptionalText2Change(e) {
        const str = e.target.value;
        productionPlanObj.optionalText2 = str;
        setProductionPlanObj((previousState) => {
            return { ...previousState, optionalText2: str }
        });
    }

    /**
     * 任意入力3
     */
    function handleOptionalText3Change(e) {
        const str = e.target.value;
        productionPlanObj.optionalText3 = str;
        setProductionPlanObj((previousState) => {
            return { ...previousState, optionalText3: str }
        });
    }

    /**
     * 任意入力4
     */
    function handleOptionalText4Change(e) {
        const str = e.target.value;
        productionPlanObj.optionalText4 = str;
        setProductionPlanObj((previousState) => {
            return { ...previousState, optionalText4: str }
        });
    }

    /**
     * 任意入力5
     */
    function handleOptionalText5Change(e) {
        const str = e.target.value;
        productionPlanObj.optionalText5 = str;
        setProductionPlanObj((previousState) => {
            return { ...previousState, optionalText5: str }
        });
    }

    /**
     * 任意入力_数値
     */
    function handleOptionalNoChange(e) {
        const str = e.target.value.trim();
        if (isExistFullWidthCharacter(str) || regex.test(str)) {
            setProductionPlanObj((previousState) => {
                return { ...previousState, optionalNo: str }
            });
        }
    }

    /**
     * 記入するテキストが全角かをチェックする
     * @param {*} str 
     * @returns 
     */
    function isExistFullWidthCharacter(str) {
        for (let i = 0; i < str.length; i++) {
            if (fullWidthPattern.test(str[i])) {
                return true;
            }
        }
        return false;
    }

    /**
     * ConfirmBox表示
     */
    function handleSaveButtonClick() {
        setShowSaveConfirmMessage(true);
    }

    /**
     * ToggleButton 処理
     */
    function handleToggleBtnClick() {
        setIsCheckedToggleBtn(!isCheckedToggleBtn);
        setProductionPlanObj((previousState) => {
            return { ...previousState, dispProcessId: "" }
        });
    }

    /**
    * ProductionPlanリスト
    */
    async function writeRegisterPlan() {
        let productionPlanList = {
            productionPlanID: productionPlanObj ? productionPlanObj.productionPlanId : -1,
            itemCode: Number(productionPlanObj.itemCode),
            dispItemCode: productionPlanObj.dispItemCode,
            itemName: productionPlanObj.itemName,
            serialNo: productionPlanObj.serialNo,
            lotNo: productionPlanObj.lotNo,
            dispProcessID: productionPlanObj.dispProcessId,
            processName: productionPlanObj.processName,
            quantity: productionPlanObj.quantity,
            planDate: productionPlanObj.planDate.replaceAll("/", "-"),
            optionalText1: productionPlanObj.optionalText1,
            optionalText2: productionPlanObj.optionalText2,
            optionalText3: productionPlanObj.optionalText3,
            optionalText4: productionPlanObj.optionalText4,
            optionalText5: productionPlanObj.optionalText5,
            optionalNo: productionPlanObj.optionalNo,
        }
        await registerProductionPlan(productionPlanList);
    }

    /**
    * ProductionPlan保存処理
    * @param {*} data
    */
    async function registerProductionPlan(data) {
        dispatch(setPageLoading(true));
        await axios({
            method: 'POST',
            url: variables.PRODUCTION_PLAN_URL + "/register-productionplan",
            withCredentials: true,
            headers: { 'company': urlCompanyName },
            data: data
        }).then(function (response) {
            let errCode = response.data;
            if (errCode === errorCode.E2006) {
                setShowSaveErrorMessage(true);
            }
            else {
                props.onComplete();
            }
        }).catch(function (error) {
            let errResponseStatus = error.response.status;
            if (errResponseStatus === 401) {
                NavigateToCompanyLogin(navigate);
            }
            else {
                appInsights.trackTrace({ data: JSON.stringify(data) });
                appInsights.trackException({ ...error, errorFunction: "productionPlanInput.registerProductionPlan()" });
                console.error(error);
            }
        });
        dispatch(setPageLoading(false));
    }

    function handleDiscardBtnClick() {
        if (isCheckDataChanged() || (isCheckedToggleBtn !== currCheckToggle)) {
            setIsDiscard(true);
        }
        else {
            props.onClose();
        }
    }

    function isCheckDataChanged() {
        if (hash(props.productionPlanObj) !== hash(productionPlanObj)) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * 各項目がエラー無しか確認する処理
     * @returns 
     */
    const isActiveWork = () => {
        if (isCheckDataChanged()
            && (productionPlanObj.serialNo.length > 0 || productionPlanObj.lotNo.length > 0)
            && productionPlanObj.quantity > 0 &&
            productionPlanObj.planDate.length > 0
        ) {
            return isCheckedToggleBtn ? productionPlanObj.dispProcessId.length > 0 : true;
        }
        else {
            return false;
        }
    }

    return (
        <>
            <div className={popupcss}>
                <div className="h-[1033px] ml-[1270px] w-[650px] p-[15px] mt-[47px] border-[1px] border-[#C8C6C4] shadow-md bg-white">
                    <div className="grid grid-cols-2">
                        {productionPlanObj && productionPlanObj.productionPlanId > -1 ? (
                            <SectionTitle Text="生産計画の編集" />
                        ) : (
                            <SectionTitle Text="生産計画の新規登録" />
                        )}
                    </div>
                    <div className="flex flex-row">
                        <Save onClick={isActiveWork() === false ? void (0) : (e) => handleSaveButtonClick(e)}
                            isEnable={isActiveWork() === false ? false : true} />
                        <Discard onClick={handleDiscardBtnClick} />
                    </div>
                    <div className="h-[2px] bg-[#C8C6C4] opacity-[.10] mx-[-15px]" />
                    <div className="h-[200px] mt-3">
                        <SectionTitle Text={"製品情報"} />
                        <div className="items-center mb-1 mt-3">
                            <div className="w-[135px] text-[16px]">
                                <label>製品コード<span className="text-[#CD0000] ml-1 text-[22px] font-bold">*</span></label>
                                <ComboxBoxValueSearchable
                                    verPadding={1}
                                    fontSize={16}
                                    width={390}
                                    height={30}
                                    isSearchable={true}
                                    itemsSource={itemList}
                                    selectedOption={productionPlanObj.dispItemCode}
                                    targetValue={"itemCode"}
                                    selectedValuePath={"displayItemCode"}
                                    displayMemberPath={"displayItemCode"}
                                    onSelectedChange={handleDispItemCodeSelectedChange}
                                    placeholder={"製品コードを入力、またはリストから製品コードを選択"}
                                >
                                </ComboxBoxValueSearchable>
                            </div>
                        </div>
                        <div className="items-center mb-8 mt-1">
                            <div className="w-[135px] text-[16px]">
                                <label>製品名<span className="text-[#CD0000] ml-1 text-[22px] font-bold">*</span></label>
                                <ComboxBoxValueSearchable
                                    verPadding={1}
                                    fontSize={16}
                                    width={390}
                                    height={30}
                                    isSearchable={true}
                                    itemsSource={itemList}
                                    selectedOption={productionPlanObj.itemName}
                                    targetValue={"itemCode"}
                                    selectedValuePath={"itemName"}
                                    displayMemberPath={"itemName"}
                                    searchableMethod={CompareMethod.PartialMatch}
                                    onSelectedChange={handleItemNameSelectedChange}
                                    onFocusOut={handleItemNameChangeFocusOut}
                                    placeholder={"製品名を入力、またはリストから製品名を選択"}
                                >
                                </ComboxBoxValueSearchable>
                            </div>
                        </div>
                        <SectionTitle Text={"計画"} />
                        <div className="items-center mb-1 mt-3">
                            <div className="flex flex-row">
                                <div className="w-[195px]">
                                    <input
                                        type="radio"
                                        id="radioSerialNo"
                                        name="managementType"
                                        onChange={(e) => onChangeRadio(OperationManagementType.SERIALNUMBER)}
                                        checked={operationMgmtType === OperationManagementType.SERIALNUMBER}
                                    />
                                    <label htmlFor="radioSerialNo" className="pl-1">
                                        製造番号/シリアル番号
                                    </label>
                                </div>
                                <div className="w-[95px]">
                                    <input
                                        type="radio"
                                        id="radioLotNo"
                                        name="managementType"
                                        onChange={(e) => onChangeRadio(OperationManagementType.LOTNUMBER)}
                                        checked={operationMgmtType === OperationManagementType.LOTNUMBER}
                                    />
                                    <label htmlFor="radioLotNo" className="pl-1">
                                        ロット番号
                                    </label>
                                </div>
                            </div>
                            <InputBox
                                maxLength={50}
                                value={
                                    operationMgmtType === OperationManagementType.LOTNUMBER ? productionPlanObj.lotNo : productionPlanObj.serialNo
                                }
                                className="focus:outline-none border-2 w-[390px] h-[29px] px-2 mb-1 rounded-[2px]"
                                onChange={(e) => handleSerialLotNumberChange(e)}
                                textType={TextType.HALFWIDTH_SYMBOL_AUTOCHANGE}
                                onKeyUp={(e, str) => handleSerialLotNumberKeyUp(e, str)}
                                onFocusOut={(txt) => handleSerialLotNumberFocusOut(txt)}
                            >
                            </InputBox><span className="text-[#CD0000] ml-1 text-[22px] font-bold">*</span>
                            <div className="flex flex-row">
                                <label className="ml-2 mr-1 font-bold">工程毎の計画入力</label>
                                <ToggleButton isChecked={isCheckedToggleBtn}
                                    onChange={handleToggleBtnClick}
                                    inputID={"processInputFlag"} />
                            </div>
                        </div>
                        <div className="flex">
                            <div className="w-[245px]">
                                <div>工程名<span className="text-[#CD0000] ml-1 text-[22px] font-bold">*</span></div>
                                <ComboxBoxValueSearchable
                                    verPadding={1}
                                    fontSize={16}
                                    className={"focus:outline-none border-2 w-[69px] h-[38px] px-2 rounded-[2px]" + (isCheckedToggleBtn ? "" : "bg-[#D8D7D5]")}
                                    width={264}
                                    height={30}
                                    selectedOption={productionPlanObj.dispProcessId}
                                    itemsSource={processList}
                                    displayMemberPath={"processName"}
                                    selectedValuePath={"dispProcessID"}
                                    onSelectedChange={handleProcessSelectedChange}
                                    placeholder={"工程名を選択"}
                                    isDisabled={isCheckedToggleBtn ? false : true}
                                ></ComboxBoxValueSearchable>
                            </div>
                            <div className="w-[132px] ml-10">
                                <div className="mt-[2px]">工程ID</div>
                                <input className={"focus:outline-none border-[1px] w-[119px] h-[37px] mt-[1px] px-2 rounded-[2px]" + (isCheckedToggleBtn ? "" : " bg-[#D8D7D5]")}
                                    value={productionPlanObj.dispProcessId}
                                    readOnly={true}>
                                </input>
                            </div>
                        </div>
                        <div className="items-center w-[105px] mb-1">
                            <div className="text-[16px] mt-1">
                                <label>計画数<span className="text-[#CD0000] ml-1 text-[22px] font-bold">*</span></label>
                            </div>
                            <InputBox
                                disabled={operationMgmtType === OperationManagementType.SERIALNUMBER ? true : false}
                                maxLength={5}
                                value={productionPlanObj.quantity === 0 ? "" : productionPlanObj.quantity}
                                className={"focus:outline-none border-2 w-[69px] h-[29px] px-2 rounded-[2px]" + (operationMgmtType === OperationManagementType.SERIALNUMBER ? " bg-[#D8D7D5]" : "")}
                                onChange={(e) => handlePlanNumberChange(e)}
                                textType={TextType.HALFWIDTH_SYMBOL_AUTOCHANGE}
                                onKeyUp={(e, str) => handleQuantityKeyUp(e, str)}
                                onFocusOut={(txt) => handleQuantityFocusOut(txt)}
                            >
                            </InputBox>
                        </div>
                        <div className="items-center w-[105px] mb-7">
                            <div className="text-[16px]">
                                <label>完成予定日<span className="text-[#CD0000] ml-1 text-[22px] font-bold">*</span></label>
                            </div>
                            <DatePicker value={productionPlanObj.planDate} onDateChange={(value) => handlePlanDateChange(value)} />
                        </div>
                        <SectionTitle Text={"任意入力"} />
                        <div className="items-center mt-3">
                            <div className="w-[135px] text-[16px]">
                                <label>任意入力1</label>
                            </div>
                            <InputBox value={productionPlanObj.optionalText1} onChange={(e) => handleOptionalText1Change(e)} className="focus:outline-none border-2 w-[390px] h-[29px] px-2 rounded-[2px]"></InputBox>
                        </div>
                        <div className="items-center">
                            <div className="w-[105px] text-[16px]">
                                <label>任意入力2</label>
                            </div>
                            <InputBox value={productionPlanObj.optionalText2} onChange={(e) => handleOptionalText2Change(e)} className="focus:outline-none border-2 w-[390px] h-[29px] px-2 rounded-[2px]"></InputBox>
                        </div>
                        <div className="items-center">
                            <div className="w-[105px] text-[16px]">
                                <label>任意入力3</label>
                            </div>
                            <InputBox value={productionPlanObj.optionalText3} onChange={(e) => handleOptionalText3Change(e)} className="focus:outline-none border-2 w-[390px] h-[29px] px-2 rounded-[2px]"></InputBox>
                        </div>
                        <div className="items-center">
                            <div className="w-[105px] text-[16px]">
                                <label>任意入力4</label>
                            </div>
                            <InputBox value={productionPlanObj.optionalText4} onChange={(e) => handleOptionalText4Change(e)} className="focus:outline-none border-2 w-[390px] h-[29px] px-2 rounded-[2px]"></InputBox>
                        </div>
                        <div className="items-center">
                            <div className="w-[105px] text-[16px]">
                                <label>任意入力5</label>
                            </div>
                            <InputBox value={productionPlanObj.optionalText5} onChange={(e) => handleOptionalText5Change(e)} className="focus:outline-none border-2 w-[390px] h-[29px] px-2 rounded-[2px]"></InputBox>
                        </div>
                        <div className="items-center">
                            <div className="w-[125px] text-[16px]">
                                <label>任意入力_数値</label>
                            </div>
                            <InputBox
                                maxLength={16}
                                value={productionPlanObj.optionalNo}
                                textType={TextType.HALFWIDTH_NUMBER_AUTOCHANGE}
                                onChange={(e) => handleOptionalNoChange(e)}
                                onKeyUp={(e, str) => handleOptionalNoKeyUp(e, str)}
                                onFocusOut={(txt) => handleOptionalNoFocusOut(txt)}
                                className="focus:outline-none border-2 w-[390px] h-[29px] px-2 rounded-[2px]"></InputBox>
                        </div>
                    </div>
                </div>
            </div>
            {showSaveConfirmMessage && <ConfirmBox
                className="absolute left-[1410px] top-[115px] w-[310px]"
                title="入力内容の登録"
                message={"登録しますか？"}
                onYesClick={() => { writeRegisterPlan(); setShowSaveConfirmMessage(false); }}
                onNoClick={() => { setShowSaveConfirmMessage(false); }}
            />}
            {showSaveErrorMessage && <ErrorBox
                className={"absolute left-[1288px] top-[265px] w-[480px]"}
                Title="入力内容の登録"
                Message={errorCode[errorCode.E2006]}
                onYesClick={() => { setShowSaveErrorMessage(false); }}
            />
            }
            {isDiscard && <ConfirmBox
                className="absolute right-[162px] top-[265px] w-[471px]"
                title="変更の破棄"
                message={"作業が破棄されます。よろしいですか？"}
                onYesClick={props.onClose}
                onNoClick={() => { setIsDiscard(false); }}
            />}
        </>
    );
}

export default ProductionPlanInput;