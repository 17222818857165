import { React, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EditButton from "../../../components/EditButton";
import ToggleButton from "../../../components/ToggleButton";
import KurikaeshiToggle from "../../../components/KurikaeshiToggle";
import HardwareInput from "./HardwareInput";
import { setHardwareKurikaeshi, setHardwareAutoTransition, selectMngItmByWorkID } from "./manageItemSlice";

function ManageItemHardware(props) {
    const dispatch = useDispatch();
    const selectedWorkID = props.workID;
    const mngItm = useSelector((state) => selectMngItmByWorkID(state, selectedWorkID));
    let hardwareList = mngItm.data.hardwareData;
    const [displayHardwareInput, setDisplayHardwareInput] = useState(false);

    const displayHardwareList = () => {
        let displayHardwareData = [];
        if (hardwareList.length >= 1) {
            for (let i = 0; i < hardwareList.length; i++) {
                displayHardwareData.push({ hardwareType: hardwareList[i].hardwareType === 1 ? "締結" : hardwareList[i].hardwareType === 2 ? "信号" : "", handleName: hardwareList[i].handleName, count: hardwareList[i].count });
            }
        }
        return displayHardwareData;
    }

    function onHardwareKurikaeshiToggleChage() {
        dispatch(setHardwareKurikaeshi({ workID: selectedWorkID, hardwareKurikaeshi: !mngItm.data.hardwareKurikaeshi }));
    }

    function onAutoTransitionToggleChange() {
        dispatch(setHardwareAutoTransition({ workID: selectedWorkID, hardwareAutoTransition: !mngItm.data.hardwareAutoTransition }));
    }

    function handleEditButtonClick() {
        setDisplayHardwareInput(true);
    }

    function onHardwareInputBoxClose() {
        setDisplayHardwareInput(false);
    }

    return (
        <div className="flex">
            <div className="flex-row w-[214px]">
                <div className="ml-[6px] flex flex-row">
                    <EditButton
                        isLock={props.isLock}
                        onClick={handleEditButtonClick}
                        editBtnCss={"flex mt-[-6px] items-center justify-center w-[80px] h-[48px] cursor-pointer bg-white "} />
                    <span className="text-2xl font-bold text-red-500 mt-[5px]">*</span>
                </div>
                <div className="mt-[-15px]">
                    <KurikaeshiToggle
                        isChecked={mngItm.data.hardwareKurikaeshi}
                        onChange={onHardwareKurikaeshiToggleChage}
                        isLock={props.isLock} />
                </div>
                <div className="flex mt-[20px] ml-[75px]">
                    <div className="font-bold">
                        自動遷移
                    </div>
                    <div className="ml-[5px]">
                        <ToggleButton
                            isChecked={mngItm.data.hardwareAutoTransition}
                            onChange={onAutoTransitionToggleChange}
                            isLock={props.isLock}
                            inputID={"hardwareSetting"} />
                    </div>
                </div>
            </div>
            <div className="flex flex-col ml-[5px] w-[261px] h-[120px] border-2 border-[#C8C6C4] bg-white overflow-y-scroll overflow-x-hidden">
                <div className="sticky top-0 w-[240px] h-[22px] bg-[#c8c6c4]">
                    <span className="ml-[4px]">種類</span>
                    <span className="ml-[17px]">ネーム/データ</span>
                    <span className="ml-[50px]">カウント</span>
                </div>
                <table className="border-collase w-full">
                    <tbody>
                        {displayHardwareList() && displayHardwareList().map((data, idx) => (
                            <tr className="h-[21px] w-[238px] border-b" key={idx}>
                                <td className="w-[52px] pl-[4px]">{data.hardwareType}</td>
                                <td className="ellipse one-line whitespace-nowrap w-[130px]">{data.handleName}</td>
                                <td className="text-right pr-[10px]">{data.count === 0 ? "" : data.count}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {displayHardwareInput && <HardwareInput
                workID={selectedWorkID}
                onClose={onHardwareInputBoxClose}
                className={"absolute right-0 top-0"} />}
        </div>
    );
}
export default ManageItemHardware;