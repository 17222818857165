import { React, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ToggleButton from "../../../components/ToggleButton";
import { ManageItemType, TextType } from "../../../Variables";
import ManageItemDropDown from "./ManageItemDropDown";
import AddDocPicture from "./AddDocPicture";
import { VscChecklist } from "@react-icons/all-files/vsc/VscChecklist";
import { IconContext } from "@react-icons/all-files";
import ManageItemInputForTextCompare from "./ManageItemInputForTextCompare";
import MangeItemInputForSagyouBunki from "./MangeItemInputForSagyouBunki";
import ManageItemInputForOKNG from "./ManageItemInputForOKNG";
import ManageItemHardware from "./ManageItemHardware";
import ConfirmBox from "../../../components/ConfirmBox";
import { nanoid } from "nanoid";
import {
  selectMngItmByWorkID,
  addEmptyMngItm,
  deleteMngItm,
  setType,
  setTxtInputKurikaeshi,
  setAudioText,
  setMketWarnText,
  setNumJudgeUpper,
  setNumJudgeLower,
  setNumJudgeKurikaeshi,
  getCmpSrcWorkMngItm,
} from "./manageItemSlice";
import { getSelectedWorkInfo } from "./workListSlice";
import { LockAttributes } from "../../../Variables";
import { selectProcessList } from "../process/processListSlice";
import { getAllWorkList } from "./workListSlice";
import ErrorBox from "../../../components/ErrorBox";
import KurikaeshiToggle from "../../../components/KurikaeshiToggle";
import InputBox from "../../../components/InputBox";
import HardwareInput from "./HardwareInput";

let selManageItem = "";
const regex = /^[-]?\d{0,10}(\.\d{0,4})?$/;
const fullWidthPattern = /[\uFF00-\uFFEF\u4E00-\u9FFF\u3000-\u303F]/g;

/**
 * 管理項目コンポネント
 * @param {*} props
 * @returns
 */
function ManageItem(props) {
  const dispatch = useDispatch();
  // エラーダイアログ
  const [dialog, setDialog] = useState(null);
  // 確認ダイアログ
  const [dialogBox, setDialogBox] = useState(null);
  const [isDisabled, setDisabled] = useState(false);
  const processList = useSelector(selectProcessList);
  const allWorkList = useSelector(getAllWorkList);
  const selectedWork = useSelector((state) => getSelectedWorkInfo(state));
  const selectedWorkID = selectedWork.workID;
  // 作業の管理項目データ
  const mngItm = useSelector((state) => selectMngItmByWorkID(state, selectedWorkID));
  const isManageItemOn = mngItm && mngItm.workID.length > 0;
  const [selectedManageItemType, setSelectedManageItemType] = useState(mngItm ? mngItm.mngItmType : ManageItemType.None);
  const cmpSrcWrkMngItmList = useSelector(getCmpSrcWorkMngItm);
  const textColor = selectedWork.isLock ? " text-[#A19F9D]" : "";
  const [displayHardwareInput, setDisplayHardwareInput] = useState(false);

  /**
  * 当作業が文字列比較先に設定されているか確認する。
  */
  function findLinkedProcessAndWork() {
    if (cmpSrcWrkMngItmList && cmpSrcWrkMngItmList.length > 0 && selectedWork) {
      const linkedWID = cmpSrcWrkMngItmList.find(m => m.data.txtCompWorkID === selectedWork.workID)?.workID;
      if (linkedWID && linkedWID.length > 0) {
        const linkedWork = allWorkList.find(w => w.workID === linkedWID);
        const linkedProcess = processList.find(p => p.processID === linkedWork.processID);
        return { process: linkedProcess, work: linkedWork };
      }
    }
    return null;
  }

  function handleManageItemToggleButton() {
    if (isManageItemOn && Number(selectedManageItemType) !== 0) {
      let msg = "";
      msg = (
        <>
          <p className="break-all">
            設定した内容が削除されます。
            <br />変更しますか？
          </p>
        </>
      );
      setDialogBox(
        <ConfirmBox
          className="absolute left-[1400px] top-[630px] w-[471px]"
          title="管理項目の変更"
          message={msg}
          onYesClick={handleManageItemChangeYes}
          onNoClick={handleManageItemChangeNo}
        />
      );
    }
    else {
      handleManageItemChangeYes();
    }

  }

  function handleManageItemChangeNo() {
    setDialogBox(null);
  }

  function handleManageItemChangeYes() {
    setDialogBox(null);
    if (selectedWorkID && selectedWorkID.length > 0) {
      if (isManageItemOn) {
        // on -> off
        const linkedProcAndWrk = findLinkedProcessAndWork();
        if (linkedProcAndWrk) {
          const errorMsg = <>「工程No{linkedProcAndWrk.process.dispOrder} : {linkedProcAndWrk.process.processName}」 <br />
            「作業No{linkedProcAndWrk.work.dispOrder} : {linkedProcAndWrk.work.workName}」 <br />
            で参照設定されている為、変更できません。
          </>
          setDialog(
            <ErrorBox
              className="absolute left-[1400px] top-[630px] w-[471px]"
              Title="管理項目の変更"
              Message={errorMsg}
              onYesClick={() => setDialog(null)}
            />
          );
          return;
        }

        // delete mngItmdata
        dispatch(deleteMngItm({ workID: selectedWorkID }))
        setSelectedManageItemType(ManageItemType.None);
      } else {
        // off -> on:  add new empty mngItmData
        dispatch(addEmptyMngItm({ workID: selectedWorkID }));
      }
    }
  }

  function handleManageItemDropDownChange(value) {
    selManageItem = value;
    // 文字列登録から他のタイプへ変更しようとする時、当作業は他の作業にリンクされているか確認する。
    // リンクされている場合は、エラーメッセージを出す。
    if (Number(selectedManageItemType) === Number(ManageItemType.Text)) {
      const linkedProcAndWrk = findLinkedProcessAndWork();
      if (linkedProcAndWrk) {
        const errorMsg = <>「工程No{linkedProcAndWrk.process.dispOrder} : {linkedProcAndWrk.process.processName}」 <br />
          「作業No{linkedProcAndWrk.work.dispOrder} : {linkedProcAndWrk.work.workName}」 <br />
          で参照設定されている為、変更できません。
        </>
        setDialog(
          <ErrorBox
            className="absolute left-[1400px] top-[630px] w-[471px]"
            Title="管理項目の変更"
            Message={errorMsg}
            onYesClick={() => setDialog(null)}
          />
        );
        return;
      }
    }
    if (Number(selectedManageItemType) !== 0) {
      let msg = "";
      msg = (
        <>
          <p className="break-all">
            設定した内容が削除されます。<br />変更しますか？
          </p>
        </>
      );
      setDialogBox(
        <ConfirmBox
          className="absolute left-[1400px] top-[630px] w-[471px]"
          title="管理項目の変更"
          message={msg}
          onYesClick={() => handleManageItemDropDownChangeYes(selManageItem)}
          onNoClick={handleManageItemDropDownChangeNo}
        />
      );
    }
    else {
      if (Number(selManageItem) === ManageItemType.Hardware) {
        setDisplayHardwareInput(true);
      }
      setSelectedManageItemType(selManageItem);
      dispatch(setType({ workID: selectedWorkID, mngItmType: Number(selManageItem) }));
    }
  }

  function handleManageItemDropDownChangeNo() {
    setDialogBox(null);
  }

  function handleManageItemDropDownChangeYes(selManageItem) {
    if (Number(selManageItem) === ManageItemType.Hardware) {
      setDisplayHardwareInput(true);
    }
    setDialogBox(null);
    setSelectedManageItemType(selManageItem);
    dispatch(setType({ workID: selectedWorkID, mngItmType: Number(selManageItem) }));
  }

  /**
   * 音声出力テキストのonChangeイベントハンドラ
   * @param {*} e 
   */
  function onAutioTextChange(e) {
    dispatch(setAudioText({ workID: selectedWorkID, text: e.target.value }));
  }

  /**
   * 警告メッセージテキストのonChangeイベントハンドラ
   * @param {*} e 
   */
  function onMarketWarningChange(e) {
    let inputText = e.target.value.replace(/[\r\n]/gm, '');
    dispatch(setMketWarnText({ workID: selectedWorkID, text: inputText }));
  }

  /**
   * 「文字列登録」の繰り返しボタンのonChangeイベント
   */
  function onTextInputKurikaeshiChg(e) {
    // OFF -> ON　にする場合、当作業は文字列比較の比較先に設定されていたら、
    // エラーメッセージを出す。
    if (mngItm.data.txtInputKurikaeshi === false) {
      const linkedProcAndWrk = findLinkedProcessAndWork();
      if (linkedProcAndWrk) {
        const errorMsg = <>「工程No{linkedProcAndWrk.process.dispOrder} : {linkedProcAndWrk.process.processName}」 <br />
          「作業No{linkedProcAndWrk.work.dispOrder} : {linkedProcAndWrk.work.workName}」 <br />
          で 文字列比較 設定されている為、繰り返し設定をオンにできません。
        </>
        setDialog(
          <ErrorBox
            className="absolute left-[1400px] top-[630px] w-[471px]"
            Title="管理項目の変更"
            Message={errorMsg}
            onYesClick={() => setDialog(null)}
          />
        );
        return;
      }
    }
    dispatch(setTxtInputKurikaeshi({ workID: selectedWorkID, txtInputKurikaeshi: !mngItm.data.txtInputKurikaeshi }));
  }

  function onUpperLimitChange(e) {
    const number = e.target.value.trim();
    if (isExistFullWidthCharacter(number) || regex.test(number)) {
      dispatch(setNumJudgeUpper({ workID: selectedWorkID, text: number }));
    }
  }

  function handleUpperLimitKeyUp(e, str) {
    if (e.key === 'Enter') {
      let value = inputTextFilter(str, mngItm.data.numJudgeUpper);
      dispatch(setNumJudgeUpper({ workID: selectedWorkID, text: value }));
    }
  }

  function handleUpperLimitFocusOut(str) {
    let value = inputTextFilter(str, mngItm.data.numJudgeUpper);
    dispatch(setNumJudgeUpper({ workID: selectedWorkID, text: value }));
  }

  function onLowerLimitChange(e) {
    const number = e.target.value.trim();
    if (isExistFullWidthCharacter(number) || regex.test(number)) {
      dispatch(setNumJudgeLower({ workID: selectedWorkID, text: number }));
    }
  }

  function handleLowerLimitKeyUp(e, str) {
    if (e.key === 'Enter') {
      let value = inputTextFilter(str, mngItm.data.numJudgeLower);
      dispatch(setNumJudgeLower({ workID: selectedWorkID, text: value }));
    }
  }

  function handleLowerLimitFocusOut(str) {
    let value = inputTextFilter(str, mngItm.data.numJudgeLower);
    dispatch(setNumJudgeLower({ workID: selectedWorkID, text: value }));
  }

  /**
   * 全角入力テキストをフィルタする処理
   * @param {*} str 
   * @param {*} inputTxt 
   * @returns 
   */
  function inputTextFilter(str, inputTxt) {
    let value = "";
    for (let i = 0; i < str.length; i++) {
      if (str[i].match(regex)) {
        value += str[i];
      }
    }
    if (regex.test(value) === false) {
      let newStr = "";
      const argStr = inputTxt.split(/[.]/);
      if (argStr.length === 1 || argStr.length === 2) {
        let fullWidthStartIdx = argStr[0].search(fullWidthPattern);
        if (fullWidthStartIdx > -1) {
          let temp = argStr[0].replace(fullWidthPattern, '');
          let maxChar = Number(temp) < 0 ? 11 : 10;
          newStr = temp.slice(0, fullWidthStartIdx);
          newStr += value.slice(fullWidthStartIdx, fullWidthStartIdx + (maxChar - temp.length));
          newStr += temp.slice(fullWidthStartIdx, temp.length);
          newStr += argStr.length === 2 ? '.' + argStr[1] : "";
        }
        fullWidthStartIdx = argStr.length === 2 ? argStr[1].search(fullWidthPattern) : -1;
        if (fullWidthStartIdx > -1) {
          newStr = argStr[0] + '.';
          let temp = argStr[1].replace(fullWidthPattern, '');
          newStr += temp.slice(0, fullWidthStartIdx);
          newStr += value.slice(newStr.length, newStr.length + (4 - temp.length));
          newStr += temp.slice(fullWidthStartIdx, temp.length);
        }
      }
      value = newStr;
    }
    return value;
  }

  /**
     * 記入するテキストが全角かをチェックする
     * @param {*} str 
     * @returns 
     */
  function isExistFullWidthCharacter(str) {
    for (let i = 0; i < str.length; i++) {
      if (fullWidthPattern.test(str[i])) {
        return true;
      }
    }
    return false;
  }

  function onNumJudgeKurikaeshiChg(e) {
    dispatch(setNumJudgeKurikaeshi({ workID: selectedWorkID, numJudgeKurikaeshi: !mngItm.data.numJudgeKurikaeshi }));
  }

  function isEnableButton() {
    if (mngItm.data.audioText.trim().length > 0) {
      return true;
    } else {
      return false;
    }
  }

  function playAudio(text) {
    setDisabled(true);
    let audio = new SpeechSynthesisUtterance(text);
    audio.addEventListener('end', function (event) {
      setDisabled(false);
    });
    audio.lang = 'ja-JP';
    window.speechSynthesis.speak(audio);
  }

  function onHardwareInputBoxClose() {
    setDisplayHardwareInput(false);
  }

  function getManageItemDataInput(itemType) {
    switch (Number(itemType)) {
      case ManageItemType.Hardware:
        return (
          displayHardwareInput ?
            <HardwareInput
              workID={selectedWork.workID}
              onClose={onHardwareInputBoxClose}
              className={"absolute right-0 top-0"} />
            : <ManageItemHardware
              workID={selectedWork.workID}
              mngItm={mngItm}
              isLock={selectedWork.isLock}
            />
        );
      case ManageItemType.SagyouBunki:
        return (
          <MangeItemInputForSagyouBunki
            workID={selectedWork.workID}
            mngItm={mngItm}
            isLock={selectedWork.isLock}
          />
        );
      case ManageItemType.Audio:
        return (
          <div>
            <div className="flex">
              <textarea
                className={"w-[444px] h-[89px] pl-[5px] after:text-red-500 resize-none border-2 border-[#C8C6C4] outline-offset-0 " +
                  (selectedWork.isLock ? LockAttributes.ATTRIBUTES + " outline-none" : " focus:outline-[#0073CD] ")}
                placeholder="読み上げるテキストを入力してください（50文字以内）"
                maxLength={50}
                required={true}
                value={mngItm.data.audioText}
                onChange={onAutioTextChange}
                readOnly={selectedWork.isLock}
              />
              <div className="text-[26px] font-bold pl-[2px] text-red-500">*</div>
            </div>
            <div >   {
              (isEnableButton()) ?
                <button
                  type='button'
                  onClick={isDisabled ? void (0) : () => playAudio(mngItm.data.audioText)}
                  className="mt-2 w-[80px] h-[30px] mr-[7px] bg-[#0073CD] text-white border-[1px] border-[#0073CD] border-solid hover:bg-[#0068B8] sm-rounded"
                >
                  音声確認
                </button>
                :
                <button
                  type='button'
                  disabled="disabled"
                  className={'mt-2 w-[80px] h-[30px] mr-[7px] text-[#b8b6b4] border-[1px] border-[#FAF9F8] border-solid sm-rounded bg-[#f3f2f1]'}
                >
                  音声確認
                </button>
            }</div>
          </div>
        );
      case ManageItemType.MarketWarning:
        return (
          <div>
            <div className="flex">
              <textarea
                placeholder="表示させるメッセージを入力してください（50文字以内）"
                maxLength={50}
                className={"w-[444px] h-[50px] pl-1 after:content-['*'] after:text-red-500 resize-none border-2 border-[#C8C6C4] overflow-hidden outline-offset-0 " +
                  (selectedWork.isLock ? LockAttributes.ATTRIBUTES + " outline-none" : " focus:outline-[#0073CD]")}
                required={true}
                value={mngItm.data.mketWarnText}
                onChange={onMarketWarningChange}
                readOnly={selectedWork.isLock}
              />
              <div className="text-[26px] font-bold pl-[2px] text-red-500">*</div>
            </div>
            <div className="mt-[8px]">
              {/* 写真登録 */}
              <AddDocPicture key={selectedWorkID} workID={selectedWorkID} mngItm={mngItm} isLock={selectedWork.isLock} />
            </div>
          </div>
        );
      case ManageItemType.Text:
        return (
          <KurikaeshiToggle
            isChecked={mngItm.data.txtInputKurikaeshi}
            onChange={onTextInputKurikaeshiChg}
            isLock={selectedWork.isLock}
          />
        );
      case ManageItemType.TextCompare:
        return (
          <ManageItemInputForTextCompare
            workID={selectedWork.workID}
            mngItm={mngItm}
            isLock={selectedWork.isLock}
          />
        );
      case ManageItemType.NumericalJudge:
        return (
          <div>
            <div className="flex items-center">
              <label htmlFor="lower_limit" className={"font-bold"}>
                下限値
              </label>
              <div className="ml-3 inline-block">
                <InputBox
                  className={"w-[338px] h-[29px] border-black px-2 rounded-[2px] border-[1px] " +
                    (selectedWork.isLock ? " outline-none w-[338px]" : "focus:outline-[#0073CD]")}
                  placeholder="小数点以下4桁まで"
                  textType={TextType.HALFWIDTH_NUMBER_AUTOCHANGE}
                  id="lower_limit"
                  maxLength={16}
                  value={mngItm.data.numJudgeLower}
                  onChange={onLowerLimitChange}
                  onKeyUp={(e, str) => handleLowerLimitKeyUp(e, str)}
                  onFocusOut={(txt) => handleLowerLimitFocusOut(txt)}
                  disabled={selectedWork.isLock}
                />
              </div>
              <div className="text-[26px] font-bold pt-2 pl-[2px] text-red-500">*</div>
            </div>
            <div className="mt-[16px] flex items-center">
              <label htmlFor="upper_limit" className={"font-bold"}>
                上限値
              </label>
              <div className="ml-3 inline-block">
                <InputBox
                  className={"w-[338px] h-[29px] border-black px-2 rounded-[2px] border-[1px] " +
                    (selectedWork.isLock ? " outline-none w-[338px]" : "focus:outline-[#0073CD]")}
                  textType={TextType.HALFWIDTH_NUMBER_AUTOCHANGE}
                  id="upper_limit"
                  placeholder="小数点以下4桁まで"
                  maxLength={16}
                  value={mngItm.data.numJudgeUpper}
                  onChange={onUpperLimitChange}
                  onKeyUp={(e, str) => handleUpperLimitKeyUp(e, str)}
                  onFocusOut={(txt) => handleUpperLimitFocusOut(txt)}
                  disabled={selectedWork.isLock}
                />
              </div>
              <div className="text-[26px] font-bold pt-2 pl-[2px] text-red-500">*</div>
            </div>
            <KurikaeshiToggle
              isChecked={mngItm.data.numJudgeKurikaeshi}
              onChange={onNumJudgeKurikaeshiChg}
              isLock={selectedWork.isLock}
            />
          </div>
        );
      case ManageItemType.OkNgJudge:
        return (
          <ManageItemInputForOKNG
            key={nanoid()}
            workID={selectedWork.workID}
            mngItm={mngItm}
            isLock={selectedWork.isLock}
          />
        );
      default:
        return "";
    }
  }

  return (
    <>
      <div className="flex flex-col items-start w-[443px] h-[211px] ">
        <div className="flex items-center">
          <div>
            <IconContext.Provider value={{ size: "27px" }}>
              <VscChecklist />
            </IconContext.Provider>
          </div>
          <div className="font-bold text-[24px] mx-[5px]">管理項目</div>
          <ToggleButton
            isChecked={isManageItemOn}
            onChange={handleManageItemToggleButton}
            inputID={"kanri01"}
            isLock={selectedWork.isLock}
          />
        </div>
        {isManageItemOn && (
          <div className={textColor}>
            <div className="mt-1">
              <ManageItemDropDown
                onSelectedIndexChange={handleManageItemDropDownChange}
                selectedOption={selectedManageItemType}
              />
            </div>
            {/* 管理項目データ入力エリア */}
            <div className="mt-[8px]">
              {getManageItemDataInput(selectedManageItemType)}
            </div>
            <div className="absolute top-[898px] right-[83px] items-center pt-0.5">
              <div className="text-[26px] font-bold leading-4 pl-[2px] text-red-500">*</div>
            </div>
            <div>
              {/* {ConfirmDialogBox} */}
              {dialogBox}
            </div>
          </div>
        )}
      </div>
      {
        dialog && dialog
      }
    </>
  );
}

export default ManageItem;
