import React, { useState, useEffect } from "react";
import SectionTitle from "../../components/SectionTitle";
import Add from "../../components/Add";
import ItemInput from "../../components/ItemInput";
import EditButton from "../../components/EditButton";
import { variables, Sort, CompanyLoginPath, StatusCode } from "../../Variables";
import { BsThreeDots } from "react-icons/bs";
import SearchBox from "../../components/SearchBox";
import { useDispatch } from "react-redux";
import { IconContext } from "@react-icons/all-files";
import TableTitle from "../../components/TableTitle";
import { Navigate } from 'react-router-dom';
import { useNavigate, generatePath } from "react-router";
import { IsEditing, GetUrlCompany, NavigateToCompanyLogin, DisplayDateFormatChange } from "../util/commonFun";
import { setIsLoading as setPageLoading } from "../Loading/pageLoadingSlice";
import ConfirmBox from "../../components/ConfirmBox";
import ErrorBox from "../../components/ErrorBox";
import { errorCode } from "../../ErrorCode";
import { nanoid } from "nanoid";
import { appInsights } from "../util/ApplicationInsight";
import { TemplateFormDownload } from "../../components/TemplateFormDownload";
import { ExcelExport } from "../../components/ExcelExport";
import { ExcelImport } from "../../components/ExcelImport";
import ImportForm from "../../files/itemImportExportForm.xlsx"

import ItemImport from "./ItemImport";
const axios = require('axios');
const cssNormal =
    "border-y-[1px] border-[#C8C6C4]-[.56] text-[#0068B8] " + variables.HOVER_CSS;
const cssEnd =
    "border-y-[1px] border-[#C8C6C4]-[.56] text-[#0068B8] bg-[#979797]";

const urlCompanyName = GetUrlCompany();

function Item(props) {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const [items, setItems] = useState([]);
    const [editBtn, setEditBtn] = useState({ id: -1, btn: null });
    const [itemInput, setItemInput] = useState(null);
    const [itemCodeSortedOrder, setItemCodeSortedOrder] = useState(Sort.NONE);
    const [itemNameSortedOrder, setItemNameSortedOrder] = useState(Sort.NONE);
    const [categorySortedOrder, setCategorySortedOrder] = useState(Sort.NONE);
    const [itemsWithoutFilter, setItemsWithoutFilter] = useState([]);
    const [itemFilterText, setItemFilterText] = useState("");
    const [isRedirectToCompanyLogin, setIsRedirectToCompanyLogin] = useState(false);
    const [dialogBox, setDialogBox] = useState(null);
    const [isEndDisplayCheck, setIsEndDisplayCheck] = useState(false);
    const [showExcelImport, setShowExcelImport] = useState(false);
    const [exportErrorMsg, setExportErrorMsg] = useState(null);

    useEffect(() => {
        refreshList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (itemsWithoutFilter && itemsWithoutFilter.length > 0) {
            // [生産終了製品を表示]のチェックtrueの場合、生産完了フラグがtrueもfalseも両方表示する。。
            let filteredItems = itemsWithoutFilter.filter((itm) => isEndDisplayCheck ? true : itm.isEnd === false);
            if (filteredItems && filteredItems.length > 0) {
                // 検索テキストでフィルターする。
                if (itemFilterText && itemFilterText.length > 0) {
                    filteredItems = filteredItems.filter(function (item) {
                        return (
                            item.displayItemCode
                                .toString()
                                .toLowerCase()
                                .includes(itemFilterText.toString().toLowerCase()) ||
                            item.itemName
                                .toString()
                                .toLowerCase()
                                .includes(itemFilterText.toString().toLowerCase()) ||
                            item.categoryName
                                .toString()
                                .toLowerCase()
                                .includes(itemFilterText.toString().toLowerCase())
                        );
                    });
                }

                if (itemCodeSortedOrder !== Sort.NONE) {
                    if (itemCodeSortedOrder === Sort.ASC) {
                        filteredItems = filteredItems.sort(function (firstItem, secondItem) {
                            return firstItem.displayItemCode.localeCompare(secondItem.displayItemCode);
                        });
                    } else {
                        filteredItems = filteredItems.sort(function (firstItem, secondItem) {
                            return secondItem.displayItemCode.localeCompare(firstItem.displayItemCode);
                        });
                    }
                }

                if (itemNameSortedOrder !== Sort.NONE) {
                    if (itemNameSortedOrder === Sort.ASC) {
                        filteredItems = filteredItems.sort(function (firstItem, secondItem) {
                            return firstItem.itemName.localeCompare(secondItem.itemName);
                        });
                    } else {
                        filteredItems = filteredItems.sort(function (firstItem, secondItem) {
                            return secondItem.itemName.localeCompare(firstItem.itemName);
                        });
                    }
                }

                if (categorySortedOrder !== Sort.NONE) {
                    if (categorySortedOrder === Sort.ASC) {
                        filteredItems = filteredItems.sort(function (firstItem, secondItem) {
                            return firstItem.categoryName.localeCompare(secondItem.categoryName);
                        });
                    } else {
                        filteredItems = filteredItems.sort(function (firstItem, secondItem) {
                            return secondItem.categoryName.localeCompare(firstItem.categoryName);
                        });
                    }
                }
            }

            setItems(filteredItems);
        }
    }, [itemsWithoutFilter, itemFilterText, isEndDisplayCheck, itemCodeSortedOrder, itemNameSortedOrder, categorySortedOrder]);

    //製品追加ボタンのイベントハンドラ
    function handleAddClick() {
        if (IsEditing() === true) {
            handleDiscardClick(-1);
        } else {
            setItemInput(
                <ItemInput
                    key={nanoid()}
                    onComplete={handleItemAddComplete}
                    Item={{
                        id: -1,
                        displayItemCode: "",
                        cateoryName: "",
                        categoryId: -1,
                        itemName: "",
                    }}
                />
            );

        }
    }

    // 編集ボタンのイベントハンドラ
    function handleEdit(targetId) {
        if (IsEditing() === true) {
            handleDiscardClick(targetId);
        }
        else {
            setItemInput(
                <ItemInput
                    key={nanoid()}
                    onComplete={handleItemAddComplete}
                    Item={items.find((i) => i.id === targetId)}
                />
            );
        }
    }

    function handleDiscardClick(targetId) {
        const msg = <>作業が破棄されます。よろしいですか？</>
        setDialogBox(
            <ConfirmBox
                className="absolute right-[162px] top-[265px] w-[471px]"
                title="変更の破棄" message={msg}
                onYesClick={() => showItemInput(targetId)} onNoClick={deleteDialog} />
        );
    }

    function showItemInput(targetId) {
        setDialogBox(null);

        if (targetId === -1) {
            setItemInput(
                <ItemInput
                    key={nanoid()}
                    onComplete={handleItemAddComplete}
                    Item={{
                        id: -1,
                        displayItemCode: "",
                        cateoryName: "",
                        categoryId: -1,
                        itemName: "",
                    }}
                />
            );
        }
        else {
            setItemInput(
                <ItemInput
                    key={nanoid()}
                    onComplete={handleItemAddComplete}
                    Item={items.find((i) => i.id === targetId)}
                />
            );

        }
    }

    function deleteDialog() {
        setDialogBox(null);
    }

    /**
    * 新規フォームダウンロード
    * @param {*} e 
    */
    function handleFormDownloadBtnClick(e) {
        let statusCd;
        axios({
            method: 'GET',
            url: variables.LOGIN_URL + "/validate",
            headers: { 'company': urlCompanyName },
            withCredentials: true
        }).then(function (response) {
            statusCd = response.data.statusCode;
            if (statusCd === StatusCode.AuthorizedWithAdministrator) {
                let link = document.getElementById('itemImportFormatDownFile');
                link.setAttribute('href', ImportForm);
                link.setAttribute('download', "製品マスタ登録フォーム.xlsx");
                link.click();
            } else {
                NavigateToCompanyLogin(navigate);
            }

        }).catch(function (error) {
            appInsights.trackException({ ...error, errorFunction: "Item.handleFormDownloadBtnClick()" });
        });
    }

    // 製品の登録もしくは編集が終了した時、<ItemInput>　をクリアして
    //　製品一覧を更新する。
    function handleItemAddComplete() {
        resetState();
        refreshList();
    }

    function redirect() {
        setIsRedirectToCompanyLogin(true);
    }

    function resetState() {
        setItems([]);
        setEditBtn({ id: -1, btn: null });
        setItemInput(null);
        setItemCodeSortedOrder(Sort.NONE);
        setItemNameSortedOrder(Sort.NONE);
        setCategorySortedOrder(Sort.NONE);
        setItemsWithoutFilter([]);
        setItemFilterText("");
        setIsRedirectToCompanyLogin(false);
        setDialogBox(null);
        setIsEndDisplayCheck(false);
    }

    /**
         * Excel書き出し
         * @param {*} e 
         * @returns 
         */
    function handleExcelExportBtnClick(e) {
        if (items.length === 0) {
            return;
        }
        dispatch(setPageLoading(true));
        let orderColumn = "";
        let orderType = "";

        if (itemCodeSortedOrder !== Sort.NONE) {
            orderColumn = "[disp_item_code]";
            orderType = itemCodeSortedOrder === Sort.ASC ? "ASC" : "DESC";
        } else if (itemNameSortedOrder !== Sort.NONE) {
            orderColumn = "[item_name]";
            orderType = itemNameSortedOrder === Sort.ASC ? "ASC" : "DESC";
        } else if (categorySortedOrder !== Sort.NONE) {
            orderColumn = "Category.[category_name]";
            orderType = categorySortedOrder === Sort.ASC ? "ASC" : "DESC";
        };
        let paramobj = {
            itemFilterText: itemFilterText.toString(),
            isEnded: isEndDisplayCheck,
            orderColumn: orderColumn,
            orderType: orderType
        };
        axios({
            method: 'GET',
            url: variables.ITEM_URL + "/export-item",
            params: paramobj,
            headers: { 'company': urlCompanyName },
            withCredentials: true
        }).then(function (response) {
            const fileUploadResponse = response.data;
            if (fileUploadResponse && !fileUploadResponse.isSuccess) {
                let msg = "";
                // エラーメッセージを表示する。
                if (errorCode.hasOwnProperty(fileUploadResponse.errorCode)) {
                    msg = errorCode[fileUploadResponse.errorCode];
                } else {
                    msg = fileUploadResponse.errorCode;
                }
                // エラーを表示する。
                setExportErrorMsg(msg);
            } else if (fileUploadResponse.objUrl?.length > 0) {
                // ブロックを取得して、一旦ブラウザに保存してからダウンロードする。
                // 理由：　保存ファイル名を設定する為。cross origin の場合、ファイル名が設定出来ない為。
                fetch(fileUploadResponse.objUrl)
                    .then((response) => response.blob())
                    .then((myBlob) => {
                        const objectURL = URL.createObjectURL(myBlob);
                        let link = document.getElementById('itemExportDownFile');
                        link.setAttribute('href', objectURL);
                        let today = DisplayDateFormatChange(new Date());
                        const fileName = `製品マスタ_${today.replaceAll("/", "-").replaceAll(":", "-")}.xlsx`;
                        link.setAttribute('download', fileName);
                        link.click();
                    });
            } else {
                // no data found to export
            }
        }).catch(function (error) {
            let errResponseStatus = error?.response?.status;
            if (errResponseStatus === 401) {
                NavigateToCompanyLogin(navigate);
            }
            else {
                appInsights.trackException({ ...error, errorFunction: "Item.handleExcelExportBtnClick()" });
            }
        }).finally(() => {
            dispatch(setPageLoading(false));
        });
    }

    // 製品一覧を更新する。
    function refreshList() {
        axios({
            method: 'GET',
            url: variables.ITEM_URL,
            headers: { 'company': urlCompanyName },
            withCredentials: true
        }).then(
            response => {
                setItemCodeSortedOrder(Sort.ASC);
                setItemsWithoutFilter(response.data);
            }).catch((error) => {
                let errResponseStatus = error.response.status;
                if (errResponseStatus === 401) {
                    redirect();
                }
                else {
                    appInsights.trackException({ ...error, errorFunction: "Item.refreshList()" });
                }
            });
    }

    // 「…」を押す時のイベントハンドラ
    // 「…」を押すと、編集ボタンを表示する。
    function handleThreeDotsClick(itemId, e) {
        const positionX = e.pageX + e.currentTarget.clientWidth + 10;
        const positionY = e.pageY - e.currentTarget.clientHeight / 2;
        setEditBtn({
            id: itemId,
            btn: (
                <div
                    style={{
                        position: "absolute",
                        left: positionX + "px",
                        top: positionY + "px",
                    }}
                >
                    <EditButton onClick={() => handleEdit(itemId)} />
                </div>
            ),
        });
        e.stopPropagation();
    }

    // 製品を押すと、編集画面を表示する。
    function handleItemClick(itemId, e) {
        handleEdit(itemId);
        e.stopPropagation();
    }

    // 編集ボタンをクリアする。
    function clearEditButton() {
        setEditBtn({ id: -1, btn: null });
    }

    //製品コードでソートする
    function sortByDisplayItemCode() {

        setItemCodeSortedOrder(itemCodeSortedOrder === Sort.ASC ? Sort.DESC : Sort.ASC);
        setItemNameSortedOrder(Sort.NONE);
        setCategorySortedOrder(Sort.NONE);
    }

    //製品名でソートする
    function sortByItemName() {
        setItemCodeSortedOrder(Sort.NONE);
        setItemNameSortedOrder(itemNameSortedOrder === Sort.ASC ? Sort.DESC : Sort.ASC);
        setCategorySortedOrder(Sort.NONE);
    }

    //カテゴリ名でソートする
    function sortByCategoryName() {
        setItemCodeSortedOrder(Sort.NONE);
        setItemNameSortedOrder(Sort.NONE);
        setCategorySortedOrder(categorySortedOrder === Sort.ASC ? Sort.DESC : Sort.ASC);
    }

    // 入力した製品コード、製品名とカテゴリ名でフィルターする。
    function handleSearchInputChange(e) {
        setItemFilterText(e.target.value);
    }

    //「生産終了製品を表示」チェックボックスの処理
    function handleEndItmDisplayChkChange(e) {
        setIsEndDisplayCheck((prevState) => { return !prevState });
    }

    function onItemImportClose() {
        setShowExcelImport(false);
    }

    function onItemImportSuccess() {
        setShowExcelImport(false);
        handleItemAddComplete();
    }

    function handleExcelImportBtnClick(e) {
        setShowExcelImport(true);
    }

    return (
        <div onClick={clearEditButton}>
            {isRedirectToCompanyLogin && <><Navigate to={generatePath(CompanyLoginPath, {
                company: urlCompanyName
            })} /></>}
            <div className="flex flex-row">
                {/* 中央パネル */}
                <a id="itemImportFormatDownFile" href="undefined" className="hidden">-</a>
                <a id="itemExportDownFile" href="undefined" className="hidden">-</a>
                <div>
                    <div className="my-[11px] h-[21px]">マスタ設定</div>
                    <SectionTitle Text="製品" />
                    <div className="flex flex-row">
                        <Add onClick={handleAddClick} Text="製品の追加" />
                        <TemplateFormDownload
                            className="w-[210px] h-[50px]"
                            Text="新規フォームダウンロード"
                            onClick={handleFormDownloadBtnClick}
                        />
                        <ExcelExport
                            className="w-[205px] h-[50px]"
                            isEnable={items.length > 0}
                            Text="製品マスタ エクスポート"
                            onClick={handleExcelExportBtnClick}
                        />
                        <ExcelImport
                            className="w-[200px] h-[50px]"
                            Text="製品マスタ インポート"
                            onClick={handleExcelImportBtnClick} />
                    </div>

                    <div className="h-[2px] w-[1683px] bg-[#C8C6C4] opacity-[.56] absolute" />
                    <div className="flex">
                        <div className="h-[32px] px-[15px] my-[15px]">
                            {/* 検索ボックス */}
                            <SearchBox
                                onChange={handleSearchInputChange}
                                value={itemFilterText}
                            />
                        </div>
                        <div className="flex flex-row items-center">
                            <div className="ml-[20px] mt-[5px]">
                                <input className="w-[21px] h-[21px]"
                                    id="endDisplayChk"
                                    checked={isEndDisplayCheck}
                                    type="checkbox"
                                    onChange={handleEndItmDisplayChkChange} />
                            </div>
                            <label htmlFor="endDisplayChk">
                                <div className="pl-[8px]">
                                    生産終了製品の表示
                                </div>
                            </label>
                        </div>
                    </div>
                    <div className="overflow-y-scroll overflow-x-scroll h-[840px] w-[1030px] mt-[3px]">
                        <table className="border-collapse">
                            <thead>
                                <tr>
                                    <th className="header">
                                        <div className="w-[320px] border-r-2 h-[30px] min-w-[50px] max-w-[900px] resizable inner-resizer-disabled">
                                            <TableTitle
                                                onClick={sortByDisplayItemCode}
                                                Sort={itemCodeSortedOrder}
                                                Text="製品コード" />
                                        </div>
                                    </th>
                                    <th className="header">
                                        <div className="w-[320px] border-r-2 h-[30px] min-w-[50px] max-w-[900px] resizable inner-resizer-disabled">
                                            <TableTitle
                                                onClick={sortByItemName}
                                                Sort={itemNameSortedOrder}
                                                Text="製品名" />
                                        </div>
                                    </th>
                                    <th className="header">
                                        <div className="w-[320px] border-r-2 h-[30px] min-w-[50px] max-w-[900px] resizable inner-resizer-disabled">
                                            <TableTitle
                                                onClick={sortByCategoryName}
                                                Sort={categorySortedOrder}
                                                Text="カテゴリ名" />
                                        </div>
                                    </th>
                                    <th className="header">
                                        <div className="h-[30px] w-[30px]"></div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {items.map((item) => (
                                    <tr key={item.id}
                                        className={item.isEnd === true ? cssEnd : cssNormal}>
                                        <td className="w-[50px] min-w[50px] h-[53px] text-left pl-[20px] font-bold"
                                            onClick={(e) => handleItemClick(item.id, e)}>
                                            <span className="w-full one-line cursor-pointer hover:underline">{item.displayItemCode}</span>
                                        </td>
                                        <td className="w-[50px] min-w[50px] h-[53px] text-left pl-[20px] text-black">
                                            <span className="w-full one-line">{item.itemName}</span>
                                        </td>
                                        <td className="w-[50px] min-w[50px] h-[53px] text-left pl-[20px] text-black">
                                            <span className="w-full one-line">{item.categoryName}</span>
                                        </td>
                                        <td className="w-[30px]" onClick={(e) => handleThreeDotsClick(item.id, e)}>
                                            <div className="flex justify-center items-center text-black cursor-pointer">
                                                <IconContext.Provider
                                                    value={{ color: "Black", size: "20px" }}
                                                >
                                                    <BsThreeDots />
                                                </IconContext.Provider>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                {/* 右側パネル  */}
                {itemInput}
                {showExcelImport && (
                    <ItemImport
                        className="absolute right-0 top-6"
                        onClose={onItemImportClose}
                        onSuccess={onItemImportSuccess}
                    />
                )}
                {
                    exportErrorMsg && (
                        <ErrorBox
                            className={"absolute left-[710px] top-[410px] w-[480px]"}
                            Title="製品マスタ エクスポート"
                            Message={exportErrorMsg}
                            onYesClick={() => { setExportErrorMsg(null); }}
                        />
                    )
                }
            </div>
            {/* 編集ボタン */}
            {editBtn.btn}
            <div>
                {dialogBox}
            </div>
        </div>
    );
}

export default Item;