export const errorCode = {
    E1001: 1001,
    E1002: 1002,
    E1003: 1003,
    E1004: 1004,
    E1005: 1005,
    E1006: 1006,
    E1007: 1007,
    E1008: 1008,
    E1009: 1009,
    E1010: 1010,
    E1011: 1011,
    E1012: 1012,
    E1013: 1013,
    E1014: 1014,
    E1015: 1015,
    E1016: 1016,
    1001: "全作業が完了しています。",
    1002: "{0}が作業中です。作業再開できません。",
    1003: "ユーザーマスタに登録されていないユーザーです。",
    1004: "製品マスタで生産終了設定をしている製品です。",
    1005: "工程情報が登録されていない製品です。",
    1006: "製品マスタに登録されていない製品です。",
    1007: "前の工程の完了数を超えています。",
    1008: "今から作業する仕掛かりは作業中、または作業が完了している為、作業再開できません。\n生産状況を確認して下さい。",
    1009: "工程設計のバージョンが変更になりました。\n作業開始画面に戻ります。\n生産状況を確認して、作業する工程を再度選択して下さい。",
    1010: "今から作業する工程は作業可能なまとめ数を超えている為、作業することができません。\n生産状況を確認して下さい。",
    1011: "管理者によって作業を継続できない状況になりました。\n管理者へ報告をして下さい。",
    1012: "他のユーザーがすでに復旧処理を完了しています。",
    1013: "今から作業する製造番号 / シリアル番号は作業中、または作業が完了している為、作業再開できません。\n生産状況を確認して下さい。",
    1014: "生産計画に登録されていない製造番号 / シリアル番号です。",
    1015: "生産計画に登録されていないロット番号です。",
    1016: "登録された計画数を超えています。",
    E2001: 2001,
    E2003: 2003,
    E2004: 2004,
    E2005: 2005,
    E2006: 2006,
    E2007: 2007,
    E2008: 2008,
    E2009: 2009,
    E2010: 2010,
    E2011: 2011,
    E2012: 2012,
    E2013: 2013,
    E2014: 2014,
    E2015: 2015,
    E2016: 2016,
    E2017: 2017,
    E2018: 2018,
    E2019: 2019,
    E2020: 2020,
    E2021: 2021,
    E2022: 2022,
    E2023: 2023,
    E2024: 2024,   
    2001: (<>必須入力項目が入力されていない箇所があります。<br />
        Excelファイルの内容を確認して下さい。</>),
    2003: (<>マスタ登録されていない製品が入力されている箇所があります。<br />
        Excelファイルの内容を確認して下さい。</>),
    2004: (<>製品と工程IDが一致しない箇所があります。<br />
        Excelファイルの内容を確認して下さい。</>),
    2005: (<>製品コードと製品名が一致しない、または<br />
        工程IDと工程名が一致しない箇所があります。<br />
        Excelファイルの内容を確認して下さい。</>),
    2006: (<>重複した生産計画がすでに登録されています。<br />
        生産計画を確認して下さい。</>),
    2007: (<>インポートファイルが正しい形式ではありません。<br />
        ファイルの選択をやり直してください。</>),
    2008: (<>インポート制限1000行を超えています。<br />
        Excelファイルの内容を確認して下さい。</>),
    2009: (<>作業を開始している生産計画は削除できません。</>),
    2010: (<>製造/シリアル番号、またはロット番号の入力に誤りがあります。<br />
        ・半角英数字記号「ｰ#_」以外が入力されている。<br />
        ・最大文字数50文字を超えている。<br />
        Excelファイルの内容を確認して下さい。</>),
    2011: (<>完成予定日の日付フォーマットに誤りがあります。<br />
        Excelファイルの内容を確認して下さい。</>),
    2012: (<>製造/シリアル番号の場合は計画数は1である必要があります。<br />
        Excelファイルの内容を確認して下さい。</>),
    2013: (<>任意入力の最大文字数50文字を超えています。<br />
        Excelファイルの内容を確認して下さい。</>),
    2014: (<>製造/シリアル番号とロット番号両方に入力されている箇所があります。<br />
        Excelファイルの内容を確認して下さい。</>),
    2015: (<>エクスポート制限20,000行を超えています。<br />
        Excelファイルの内容を確認して下さい。</>),
    2016: (<>任意入力_数値は<br />
        -9999999999.9999～9999999999.9999である必要があります。<br />
        Excelファイルの内容を確認して下さい。</>),
    2017: (<>重複した生産計画が入力されている箇所があります。<br />
        Excelファイルの内容を確認して下さい。</>),
    2018: (<>ロット番号の場合は計画数は1~99,999である必要があります。<br />
        Excelファイルの内容を確認して下さい。</>),
    2019: (<>製品コードの入力に誤りがあります。<br />
        ・半角英数字記号「ｰ#_」以外が入力されている。<br />
        ・最大文字数50文字を超えている。<br />
        Excelファイルの内容を確認して下さい。</>),
    2020: (<>重複した製品コード、または製品名が入力されている箇所があります。<br />
        Excelファイルの内容を確認して下さい。</>),
    2021: (<>必須入力項目が入力されていない箇所があります。<br />
        Excelファイルの内容を確認して下さい。</>),
    2022: (<>登録されていないカテゴリが入力されている箇所があります。<br />
            Excelファイルの内容を確認して下さい。</>),
    2023: (<>製品名の最大文字数50文字を超えています。<br />
        Excelファイルの内容を確認して下さい。</>),
    2024: (<>生産終了の入力に誤りがあります。<br />
        空白、「生産終了」以外のテキストが入力されています。<br />
        Excelファイルの内容を確認して下さい。</>)
};
Object.freeze(errorCode);