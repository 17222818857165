import { React, useState, useEffect } from "react";
import { IconContext } from "@react-icons/all-files";
import { BsFillPersonFill } from "@react-icons/all-files/bs/BsFillPersonFill";
import { variables } from "../Variables";
import { GetUrlCompany } from "../features/util/commonFun";
import { appInsights } from "../features/util/ApplicationInsight";

const axios = require('axios');
const urlCompanyName = GetUrlCompany();

function LoginUserDisplay(props) {
    const [userName, setUserName] = useState("");
    useEffect(function () {
        fetchUserName();
    }, []);

    function fetchUserName() {
        axios({
            method: 'GET',
            url: variables.USER_URL + "/get-login-user-name",
            withCredentials: true,
            headers: { 'company': urlCompanyName },
        }).then(function (response) {
            setUserName(response.data);
        }).catch(function (error) {
            appInsights.trackException({...error, errorFunction: "LoginUserDisplay.fetchUserName()"});
            setUserName("");
        });
    }

    return (
        <div onClick={props.onClick}
            className="flex items-center justify-end mr-[10px] px-[5px] cursor-pointer hover:bg-[#1980D1]">
            <div className="w-[170px] h-full flex items-center text-white">
                <div
                    className="w-full font-bold whitespace-nowrap text-right overflow-hidden text-ellipsis">
                    {userName}
                </div>
            </div>
            <div className="ml-[10px]">
                <IconContext.Provider
                    value={{ color: 'white', size: "30px" }}>
                    <BsFillPersonFill />
                </IconContext.Provider>
            </div>
        </div>
    );
}

export default LoginUserDisplay;